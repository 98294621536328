import { db, storage } from "../../firebase"
import { deleteCrewAppoitment } from "../Api"
import { isBetweenDates, isNullOrEmpty } from "../Utils"
import { CrewMemberEntity } from "../data/Crew"
import { ResponseEntity } from "../data/Response"
import { FileEntity, toFileEntity, toFileEntityDto } from "../data/folder/FolderStructure"
import { JobEntity, JobStatus, JobStatusEntity } from "../data/job/Job"
import { buildJobWalkThroughFileEntityPath, buildJobWalkThroughFileUploadPath, JobWalkThroughEntity } from "../data/job/JobWalkThrough"
import { FirebaseInventoriesCollections, FirebaseJobCollections, FirebaseRootCollections } from "../firebase/FirebaseSchema"
import { deleteCrewMemberReservations, deleteCrewReservations, getCrewMembersSchedules, getCrewsSchedules, saveCrewMembersSchedules, saveCrewSchedules } from "./CrewApi"
import { deleteEquipmentReservations, getEquipmentSchedules, saveEquipmentSchedules } from "./EquipmentApi"
import { FileUploadEntity, saveFileData } from "./FilesApi"
import { deleteAllJobLocateTickets } from "./LocateTicketsApi"

export const getJobReportCrewMembers = async (inventoryId: string, jobId: string) => {
  try {
    const result = await db
      .collection(FirebaseRootCollections.INVENTORIES)
      .doc(inventoryId)
      .collection(FirebaseInventoriesCollections.JOBS)
      .doc(jobId)
      .collection(FirebaseJobCollections.CREW_MEMBERS)
      .get()

    const crewMembers = result.docs.map((doc: any) => {
      return { ...doc.data(), id: doc.id }
    })

    return new ResponseEntity<CrewMemberEntity[]>(crewMembers, "")
  } catch (error: any) {
    return new ResponseEntity<CrewMemberEntity[]>(null, error)
  }
}

export const saveJobReportCrewMembers = async (inventoryId: string, jobId: string, crewMember: CrewMemberEntity) => {
  try {
    await db.collection(FirebaseRootCollections.INVENTORIES)
      .doc(inventoryId)
      .collection(FirebaseInventoriesCollections.JOBS)
      .doc(jobId)
      .collection(FirebaseJobCollections.CREW_MEMBERS)
      .add({ ...crewMember })

    return new ResponseEntity<CrewMemberEntity>(crewMember, "")
  } catch (error: any) {
    return new ResponseEntity<CrewMemberEntity>(crewMember, error)
  }
}

export const updateJobReservations = async (inventoryId: string, job: JobEntity) => {
  if (!isNullOrEmpty(job.id)) {
    const crews = (await getCrewsSchedules(inventoryId, job.id ?? "")).data?.filter((crew) => {
      return !isBetweenDates(job.startDate?.toDate(), job.endDate?.toDate(), crew.startDate?.toDate()) ||
        !isBetweenDates(job.startDate?.toDate(), job.endDate?.toDate(), crew.endDate?.toDate())
    })
      ?.map((crew) => { return { ...crew, startDate: job.startDate, endDate: job.endDate } }) ?? []

    const crewMembers = (await getCrewMembersSchedules(inventoryId, job.id ?? "")).data?.filter((crewMember) => {
      return !isBetweenDates(job.startDate?.toDate(), job.endDate?.toDate(), crewMember.startDate?.toDate()) ||
        !isBetweenDates(job.startDate?.toDate(), job.endDate?.toDate(), crewMember.endDate?.toDate())
    })
      ?.map((crewMember) => { return { ...crewMember, startDate: job.startDate, endDate: job.endDate } }) ?? []


    const equipment = (await getEquipmentSchedules(inventoryId, job.id ?? "")).data?.filter((equipment) => {
      return !isBetweenDates(job.startDate?.toDate(), job.endDate?.toDate(), equipment.startDate?.toDate()) ||
        !isBetweenDates(job.startDate?.toDate(), job.endDate?.toDate(), equipment.endDate?.toDate())
    })
      ?.map((equipment) => { return { ...equipment, startDate: job.startDate, endDate: job.endDate } }) ?? []


    const allRequests = [
      ...[crews?.map((crew) => { return saveCrewSchedules(inventoryId, crew) }) ?? []],
      ...[crewMembers?.map((crewMember) => { return saveCrewMembersSchedules(inventoryId, crewMember) }) ?? []],
      ...[equipment?.map((equipment) => { return saveEquipmentSchedules(inventoryId, equipment) }) ?? []]
    ]

    Promise.all(allRequests)
  }
}

export const getJobDocuments = async (inventoryId: string, jobId: string, companyId: string) => {
  try {

    const foldersResult = await db
      .collection(FirebaseRootCollections.INVENTORIES)
      .doc(inventoryId)
      .collection(FirebaseInventoriesCollections.JOBS)
      .doc(jobId)
      .collection(FirebaseJobCollections.FOLDERS)
      .get()

    const filesResult = await db
      .collection(FirebaseRootCollections.INVENTORIES)
      .doc(inventoryId)
      .collection(FirebaseInventoriesCollections.JOBS)
      .doc(jobId)
      .collection(FirebaseJobCollections.FILES)
      .get()

    var folders: any = foldersResult.docs.map(folder => { return { ...folder.data(), id: folder.id }; });
    var files: any = filesResult.docs.map(file => { return { ...file.data(), id: file.id }; });

    try {
      await Promise.all(
        files.map(async (element: any) => {
          try {
            let storageRef = storage.ref(
              `/${companyId}/Files/${jobId}/${element.uploadedAt?.seconds ??
              element.createdAt?.seconds ??
              ""
              }/${element.filename ?? element.fileName ?? ""}`
            );
            let url = await storageRef.getDownloadURL();
            element.fileLink = url;
          } catch (error) {
            try {
              let jobStorageRef = storage.ref(
                `/${companyId}/job/${jobId}/${element.uploadedAt?.seconds ??
                element.createdAt?.seconds ??
                ""
                }/${element.filename ?? element.fileName ?? ""}`
              );
              let url = await jobStorageRef.getDownloadURL();
              element.fileLink = url;
            } catch (error) {
              element.fileLink = null;
            }
          }
        })
      );
    } catch (error) {
      console.error(error);
    }

    const folderMap = new Map();

    // Initialize folders with their basic structure
    folders.forEach((folder: any) => {
      folderMap.set(folder.id, { ...folder, subfolders: [], files: [], type: 'folder' });
    });

    const rootItems: any = [];

    // Organize folders into hierarchy
    folders.forEach((folder: any) => {
      if (folder.categoryId == null) {
        rootItems.push(folderMap.get(folder.id));
      } else {
        const parent = folderMap.get(folder.categoryId);
        if (parent) {
          parent.subfolders.push(folderMap.get(folder.id));
        }
      }
    });

    // Assign files to the correct folders
    files.forEach((file: any) => {
      if (file.categoryID == null) {
        rootItems.push(file);
      } else {
        const parent = folderMap.get(file.categoryID);
        if (parent) {
          parent.files.push(file);
        }
      }
    });

    return {
      status: 'success',
      data: rootItems
    }

  } catch (exception: any) {
    console.error(exception)

    return {
      status: 'error',
      data: exception
    }
  }
}

export const getJobWalkThroughList = async (inventoryId: string, jobId: string) => {
  try {
    const result = await db
      .collection(FirebaseRootCollections.INVENTORIES)
      .doc(inventoryId)
      .collection(FirebaseInventoriesCollections.JOBS)
      .doc(jobId)
      .collection(FirebaseJobCollections.WALK_THROUGH)
      .get()

    const jobWalkThroughList = result.docs.map((doc: any) => {
      return { ...doc.data(), id: doc.id }
    })

    return new ResponseEntity<JobWalkThroughEntity[]>(jobWalkThroughList, "")
  } catch (error: any) {
    return new ResponseEntity<JobWalkThroughEntity[]>([], error)
  }
}

export const getJobWalkThrough = async (inventoryId: string, jobId: string, jobWalkThroughId: string) => {
  try {
    const result = await db
      .collection(FirebaseRootCollections.INVENTORIES)
      .doc(inventoryId)
      .collection(FirebaseInventoriesCollections.JOBS)
      .doc(jobId)
      .collection(FirebaseJobCollections.WALK_THROUGH)
      .doc(jobWalkThroughId)
      .get()

    const jobWalkThrough: JobWalkThroughEntity = { ...result.data(), id: result.id ?? "" }

    return new ResponseEntity<JobWalkThroughEntity>(jobWalkThrough, "")
  } catch (error: any) {
    return new ResponseEntity<JobWalkThroughEntity>(null, error)
  }
}

export const saveJobWalkThrough = async (companyId: string, inventoryId: string, jobId: string, jobWalkThrough: JobWalkThroughEntity, files?: FileEntity[]) => {

  try {
    const { id, ...jobWalkThroughWithoutId } = jobWalkThrough

    const result = await db
      .collection(FirebaseRootCollections.INVENTORIES)
      .doc(inventoryId)
      .collection(FirebaseInventoriesCollections.JOBS)
      .doc(jobId)
      .collection(FirebaseJobCollections.WALK_THROUGH)
      .add(jobWalkThroughWithoutId)

    const jobWalkThroughId = result.id

    if (files)
      uploadJobWalkThroughFiles(files, inventoryId, jobId, jobWalkThroughId, companyId)

    return new ResponseEntity<JobWalkThroughEntity>({ ...jobWalkThrough, id: jobWalkThroughId }, "")

  } catch (error: any) {
    console.error(error)
    return new ResponseEntity<JobWalkThroughEntity>(null, error)
  }

}

const uploadJobWalkThroughFiles = async (files: FileEntity[], inventoryId: string, jobId: string, jobWalkThroughId: string, companyId: string) => {
  Promise.all(
    files?.map(async (file) => {

      const fileUpload: FileUploadEntity = {
        fileEntityPath: buildJobWalkThroughFileEntityPath(inventoryId, jobId, jobWalkThroughId),
        file: file.file,
        fileUploadState: "upload",
        fileUploadPath: buildJobWalkThroughFileUploadPath(companyId, jobId, jobWalkThroughId, file.createdAt?.seconds?.toString() ?? ""),

        fileEntityDto: toFileEntityDto(file),
        fileDocumentId: null,
      };

      const result = await saveFileData(fileUpload);

      if (result?.data?.fileEntityDto) {
        const file = toFileEntity(
          result?.data?.fileEntityDto,
          result?.data?.fileDocumentId
        );
      }


    }) ?? []
  );
}

/**
 * Method to get job statuses
 * 
 * @param inventoryId inventoryId of the job
 * @param jobId jobId of the job
 * @returns list of job statuses sorted by createdAt in descending order
 */
export const getJobStatuses = async (inventoryId: string, jobId: string) => {
  try {
    const result = await db
      .collection(FirebaseRootCollections.INVENTORIES)
      .doc(inventoryId)
      .collection(FirebaseInventoriesCollections.JOBS)
      .doc(jobId)
      .collection(FirebaseJobCollections.JOB_STATUS)
      .get()

    const jobStatuses:JobStatusEntity[] = result.docs.map((doc: any) => {
      return { ...doc.data(), documentID: doc.id }
    }).sort((a:JobStatusEntity, b:JobStatusEntity) => {
      return (a.createdAt ?? 0) < (b.createdAt ?? 0) ? 1 : -1
    })

    return new ResponseEntity<JobStatusEntity[]>(jobStatuses, "")
  } catch (error: any) {
    return new ResponseEntity([], error)
  }
}

export const saveJobStatus = async (inventoryId: string, jobId: string, jobStatus: JobStatusEntity) => {
  try {

    const query = db.collection(FirebaseRootCollections.INVENTORIES)
      .doc(inventoryId)
      .collection(FirebaseInventoriesCollections.JOBS)
      .doc(jobId)
      .collection(FirebaseJobCollections.JOB_STATUS)

      if(jobStatus.documentID){
        const { documentID, ...jobStatusWithoutId } = jobStatus
        await query.doc(jobStatus.documentID).set(jobStatusWithoutId)}
      else{
       await query.add(jobStatus)
      }

    return new ResponseEntity<JobStatusEntity>(jobStatus, "")
  }
  catch (error: any) {
    return new ResponseEntity<JobStatusEntity>(jobStatus, error)
  }
}

export const deleteJob = async (inventoryId: string, jobId: string) =>{
  await deleteEquipmentReservations(inventoryId, jobId)
  await deleteCrewReservations(inventoryId, jobId)
  await deleteCrewMemberReservations(inventoryId, jobId)
  await deleteAllJobLocateTickets(inventoryId, jobId)
}