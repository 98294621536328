import { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../shared/Auth";
import Loader from "../../../components/Layout/Loader/Loader";
import {
  getCrewAppointmentsByJob,
  getCrews,
  getJob,
  getSegments,
  getTasks,
} from "../../../shared/Api";
import { TaskManagementList } from "./TaskManagementList";
import Select from "react-select";
import {
  TaskEntity,
  TaskPriority,
  TaskStatus,
} from "../../../shared/data/taskmanagement/Task";
import TaskManagementMap from "./TaskManagementMap";
import {
  deleteSegment,
  deleteTask,
  getSegmentsAndTasks,
} from "../../../shared/api/TaskManagerApi";
import TabsComponent, { TabData } from "../../../components/Tab/TabsComponent";
import EQExportReportComponent from "../../../components/Form/EQExportReportComponent";
import { parseFromFirebaseDate } from "../../../shared/Util";

const TaskManagement = (props) => {
  const contextType = useContext(AuthContext);
  const currentUser = contextType.currentUser;
  const canEdit = currentUser.userProfile.permissions !== "crewmember";
  const sortByOptions = [
    { value: "startDate", label: "Start Date" },
    { value: "taskPriority", label: "Task Priority" },
    { value: "taskStatus", label: "Task Status" },
  ];

  const [reportState, setReportState] = useState({reportTypes:["Excel"]})

  const [formState, setFormState] = useState({
    tabSelection: "1",
    segmentsWithTasks: [],
    tasksWithoutSegment: [],
    crews: [],
    job: undefined,
    isLoading: false
  });

  const [filteredData, setFilteredData] = useState({
    segments: formState.segmentsWithTasks,
    tasks: formState.tasksWithoutSegment,
  });

  const [filter, setFilter] = useState({
    showFilter: false,
    status: "",
    searchText: "",
    taskPriority: null,
    taskStatus: null,
    crewAssigned: null,
    sortBy: null,
  });

  useEffect(() => {
    const result = filterData();
    setFilteredData((previousState) => {
      return {
        ...previousState,
        segments: result.segments,
        tasks: result.tasks,
      };
    });
  }, [formState, filter]);

  const filterByTabSelection = (tabId) => {
    //numbers 1..6 are tab ids defined in buildTabs() method
    var taskStatus = null;
   switch (TaskStatus[tabId]) {
      case TaskStatus.inProgress: {
        taskStatus = { value: "inProgress", label: "In Progress" };
        break;
      }
      case TaskStatus.open: {
        taskStatus = { value: "open", label: "Open" };
        break;
      }
      case TaskStatus.onHold: {
        taskStatus = { value: "onHold", label: "On Hold" };
        break;
      }
      case TaskStatus.closed: {
        taskStatus = { value: "closed", label: "Closed" };
        break;
      }
    }
    setFilter((previousState) => {
      return { ...previousState, taskStatus: taskStatus };
    });
  };

  const filterData = () => {
    let filteredSegments = formState.segmentsWithTasks?.filter(
      (segmentsWithTasks) => {
        return segmentsWithTasks?.segment?.title
          ?.toLowerCase()
          ?.includes(filter.searchText?.toLowerCase()??"");
      }
    );
    let filteredTasks = formState.tasksWithoutSegment.filter((task) => {
      return task?.title
        ?.toLowerCase()
        ?.includes(filter?.searchText?.toLowerCase()??"");
    });

    if (filter?.taskStatus?.value) {
      filteredSegments = filteredSegments.filter((segmentWithTask) => {
        return segmentWithTask.tasks.some(
          (task) => task.taskStatus == filter.taskStatus.value
        );
        // }
      });
      filteredTasks = filteredTasks.filter(
        (tasksWithoutSegment) =>
          tasksWithoutSegment.taskStatus == filter.taskStatus.value
      );
    }

    if (filter?.taskPriority?.value) {
      filteredSegments = filteredSegments.filter((segmentWithTask) => {
        return segmentWithTask.tasks.some(
          (task) => task.priority == filter.taskPriority.value
        );
      });
      filteredTasks = filteredTasks.filter(
        (tasksWithoutSegment) =>
          tasksWithoutSegment.priority == filter.taskPriority.value
      );
    }

    if (filter?.crewAssigned?.value) {
      filteredSegments = filteredSegments.filter((segmentWithTask) => {
        return segmentWithTask.tasks.some((task) =>
          task.crews.includes(filter.crewAssigned.value)
        );
      });
      filteredTasks = filteredTasks.filter((tasksWithoutSegment) =>
        tasksWithoutSegment.crews.includes(filter.crewAssigned.value)
      );
    }

    if(filter.sortBy){
      const sortedItems = sort(filteredSegments, filteredTasks)
      filteredSegments = sortedItems.segments
      filteredTasks = sortedItems.tasks
    }

    return { segments: filteredSegments, tasks: filteredTasks };
  };

  const sort = (segments, tasks) => {
    let sortedSegments = segments;
    let sortedTasks = tasks;

    switch (filter?.sortBy?.value) {
      case "startDate":
        sortedSegments = segments.sort((a, b) =>
          a.segment.startDate > b.segment.startDate ? -1 : 1
        );
        sortedTasks = tasks.sort((a, b) =>
          a.startDate > b.startDate ? -1 : 1
        );
        break;
      case "taskPriority":
        sortedTasks = sortByPriority(segments, tasks);
        break;
      case "taskStatus":
        sortedTasks = sortByTaskStatus(segments, tasks);
        break;
    }
    return { segments: sortedSegments, tasks: sortedTasks };
  };

  const sortByPriority = (segments, tasks) => {
    const sortOrder = Object.keys(TaskPriority);
    return tasks.sort((a, b) => {
      return sortOrder.indexOf(a.priority) - sortOrder.indexOf(b.priority);
    });
  };

  const sortByTaskStatus = (segments, tasks) => {
    const sortOrder = Object.keys(TaskStatus);
    return tasks.sort((a, b) => {
      return sortOrder.indexOf(a.priority) - sortOrder.indexOf(b.priority);
    });
  };

  const fetchData = async () => {
    setFormState((previousState)=>{return{...previousState, isLoading:true}})
    const inventoryId = currentUser.company.inventoryID;
    const jobId = props.match.params.id;
    const job = await getJob(inventoryId, jobId);
    const segments = await getSegments(inventoryId, jobId);
    const tasks = await getTasks(inventoryId, jobId);
    const crews = await getCrews(inventoryId);
    const crewAppointments = await getCrewAppointmentsByJob(inventoryId, jobId);
    const segmentsAndTasks = await getSegmentsAndTasks(inventoryId, jobId)

    buildReport(segmentsAndTasks?.data)

    const jobCrews = crews.data.filter((crew) => {
      return crewAppointments.data.some(
        (appointment) => appointment.crewID == crew.id
      );
    });

    setFormState((previousState) => {

      const sortedSegments = segments.data.map((segment) => {
        return {
          segment: segment,
          tasks: tasks.data.filter((task) => task.segmentID == segment.id),
        };
      })

      const sortedTasks = tasks.data.filter((task) => !task.segmentID)

      return {
        ...previousState,
        segmentsWithTasks: sortedSegments,
        tasksWithoutSegment: sortedTasks,
        job: job.data,
        crews: jobCrews,
        isLoading: false
      };
    });
  };

  const buildTabs = () =>{    
    return [
      new TabData("1", "Map"),
      new TabData("2", "All"),
      new TabData("inProgress", "In Progress"),
      new TabData("open", "Open"),
      new TabData("onHold", "On Hold"),
      new TabData("closed", "Complete")
    ] 
  }

  const buildReport = (
    segmentsAndTasks
  ) => {
    try{
    const reportHeaders = [
      { label: "Segment ID", key: "segmentId" },
      { label: "Segment Name", key: "segmentName" },
      { label: "Task ID", key: "taskId" },
      { label: "Task Title", key: "taskTitle" },
      { label: "Task Start Date", key: "startDate" },
      { label: "Task End Date", key: "endDate" },
      { label: "Task Type", key: "taskType" },
      { label: "Estimated Duration(h)", key: "estimatedDuration" },
      { label: "Current Duration(h)", key: "currentDuration" },
      { label: "Estimated Quantity", key: "estimatedQuantity" },
      { label: "Current Quantity", key: "currentQuantity" },
      { label: "Estimated Filled Yards", key: "expectedFilledYards" },
      { label: "Current Filled Yards", key: "currentFilledYards" },
      { label: "Estimated Footage", key: "expectedFootage" },
      { label: "Current Footage", key: "currentFootage" },
    ];

    const reportData = segmentsAndTasks.map((data) => {
      return {
        segmentId: data?.segment?.segmentID,
        segmentName: data?.segment?.title,
        taskId: data?.task?.taskID,
        taskTitle: data?.task?.title,
        startDate: parseFromFirebaseDate(data.task?.startDate, "DD/MM/YY hh:mm A"),
        endDate: parseFromFirebaseDate(data.task?.endDate, "DD/MM/YY hh:mm A"),
        taskType: data.task.taskType,
        estimatedDuration: data.task.estimatedDuration,
        currentDuration: data.task.currentDuration,
        estimatedQuantity: data.task?.expectedQuantity,
        currentQuantity: data.task?.currentQuantity,
        expectedFilledYards: data.task?.expectedFilledYards,
        currentFilledYards: data.task?.currentFilledYards,
        expectedFootage: data.task?.expectedFootage,
        currentFootage: data.task?.currentFootage,
      };
    })

    setReportState((previousState) => {
      return {
        ...previousState,
        data: reportData,
        header: reportHeaders,
        title: `report-segments-and-tasks`,
      };
    });
  }catch(error){
  }
};


  const deleteSegmentData = async (segment) => {
    await deleteSegment(currentUser.company.inventoryID, segment.id);
    fetchData();
  };

  const deleteTaskData = async (task) => {
    await deleteTask(currentUser.company.inventoryID, task.id);
    fetchData();
  };

  useEffect(() => {
    fetchData();
    props.setTabActive("taskmanagement");

    return () => {};
  }, []);


  return (
    <>
      {canEdit && (
        <div className="row">
          <div className="col-xl-12">
            <div className="form-group float-right ml-2">
              <Link
                className="btn btn-primary font-weight-bold mr-4 w-100"
                to={{
                  pathname: `/jobs/${props.job.id}/segments/new`,
                  state: {
                    startDate: formState.job?.startDate,
                    endDate: formState.job?.endDate,
                    job: formState.job,
                  },
                }}
              >
                Add new segment
              </Link>
            </div>
            <div className="form-group float-right ml-2">
              <Link
                className="btn btn-primary font-weight-bold mr-4 w-100"
                to={{
                  pathname: `/jobs/${props.job.id}/taskmanagement/task`,
                  state: {
                    taskDateMin: props.job.startDate,
                    taskDateMax: props.job.endDate,
                    task: new TaskEntity(
                      props?.job?.id,
                      null,
                      props?.job?.locationLat,
                      props?.job?.locationLong
                    ),
                  },
                }}
              >
                Add new task
              </Link>
            </div>
            <div className="form-group float-right ml-2">
              <EQExportReportComponent {...reportState} />
            </div>
          </div>
          <div className="card-body p-1">
            <div className="row align-items-center ml-0 mb-2">
              <div className="col-lg-4 my-2 my-md-0">
                <div className="input-icon">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    id="kt_datatable_search_query"
                    onChange={(event) => {
                      setFilter((previousState) => {
                        return {
                          ...previousState,
                          searchText: event.target.value,
                        };
                      });
                    }}
                  />
                  <span>
                    <i className="flaticon2-search-1 text-muted" />
                  </span>
                </div>
              </div>
              <label
                className="btn btn-link"
                onClick={(event) => {
                  setFilter((previousState) => {
                    return {
                      ...previousState,
                      showFilter: !previousState.showFilter,
                    };
                  });
                }}
              >
                {filter.showFilter ? "Hide filter" : "Show filter"}
              </label>
            </div>
            {filter.showFilter && (
              <div
                key="task-management-filter"
                className="d-flex flex-column-fluid mt-5 mb-5"
              >
                <div className="col">
                  <div className="col-form-label ml-4">
                    <label>Crew assigned</label>
                  </div>
                  <div className="col">
                    <Select
                      options={formState.crews.map((crew) => {
                        return { value: crew.id, label: crew.crewName };
                      })}
                      isSearchable={true}
                      onChange={(event) => {
                        setFilter((previousState) => {
                          return { ...previousState, crewAssigned: event };
                        });
                      }}
                      value={filter.crewAssigned}
                      defaultValue={filter.crewAssigned}
                      isClearable={true}
                      backspaceRemovesValue={true}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="col-form-label ml-4">
                    <label>Task priority</label>
                  </div>
                  <div className="col">
                    <Select
                      options={Object.entries(TaskPriority).map(
                        ([key, value]) => {
                          return { value: key, label: value };
                        }
                      )}
                      isSearchable={true}
                      onChange={(event) => {
                        setFilter((previousState) => {
                          return { ...previousState, taskPriority: event };
                        });
                      }}
                      value={filter.taskPriority}
                      defaultValue={filter.taskPriority}
                      isClearable={true}
                      backspaceRemovesValue={true}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="col-form-label ml-4">
                    <label>Task status</label>
                  </div>
                  <div className="col">
                    <Select
                      options={Object.entries(TaskStatus).map(
                        ([key, value]) => {
                          return { value: key, label: value };
                        }
                      )}
                      isSearchable={true}
                      onChange={(event) => {
                        setFilter((previousState) => {
                          return { ...previousState, taskStatus: event };
                        });
                      }}
                      value={filter.taskStatus}
                      defaultValue={filter.taskStatus}
                      isClearable={true}
                      backspaceRemovesValue={true}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="col-form-label ml-4">
                    <label>Sort by</label>
                  </div>
                  <div className="col">
                    <Select
                      options={sortByOptions}
                      isSearchable={true}
                      onChange={(event) => {
                        setFilter((previousState) => {
                          return { ...previousState, sortBy: event };
                        });
                      }}
                      value={filter.sortBy}
                      defaultValue={filter.sortBy}
                      isClearable={true}
                      backspaceRemovesValue={true}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-xl-12">
          <div className="card card-custom card-transparent">
            <div className="card-body p-0">
              {/* <div
                className="wizard wizard-4"
                id="kt_wizard"
                // data-wizard-state="step-first"
                data-wizard-clickable="true"
              > */}
              <TabsComponent
                tabs={buildTabs()}
                onTabSelected={(tab) => {
                  filterByTabSelection(tab.id);
                  setFormState((previousState) => {
                    return { ...previousState, tabSelection: tab.id };
                  });
                }}
              >
               
                <div className="card card-custom card-shadowless rounded-top-0">
                  <div className="card-body p-0">
                    <div className="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                      <div className="col-xl-12 col-xxl-12">
                        <form className="form mt-0 mt-lg-10" id="kt_form">
                          {props.boresLoading ? (
                            <Loader height="100px" />
                          ) : (
                            <div
                              className="pb-5"
                              data-wizard-type="step-content"
                              data-wizard-state="current"
                            >
                              {formState.tabSelection == "1" && (
                                <>
                                  <TaskManagementMap
                                    job={formState.job}
                                    segments={formState.segmentsWithTasks.map(
                                      (segment) => segment.segment
                                    )}
                                    tasks={[...formState.tasksWithoutSegment, ...formState.segmentsWithTasks.flatMap((segment)=>(segment.tasks))]}
                                    isLoading={formState.isLoading}
                                  />
                                </>
                              )}
                              {formState.tabSelection != "1" && (
                                <TaskManagementList
                                  job={formState.job}
                                  segmentsWithTasks={filteredData.segments}
                                  tasksWithoutSegment={filteredData.tasks}
                                  allCrews={formState.crews}
                                  canEdit={canEdit}
                                  isLoading={formState.isLoading}
                                  onSegmentDeleted={deleteSegmentData}
                                  onTaskDeleted={deleteTaskData}
                                />
                              )}
                            </div>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </TabsComponent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskManagement;
