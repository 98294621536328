import React, { useRef } from "react";
import EQInputText from "../../../components/Form/Inputs/EQInputText";
import { Trash } from "../../../components/Icons/Svg";
import { JobWalkThroughAttendeeEntity } from "../../../shared/data/job/JobWalkThrough";
import SignatureCanvas from "react-signature-canvas";

interface JobWalkThroughAttendeeProps {
  clearSignature: boolean;
  componentIdex: number;
  attendee: JobWalkThroughAttendeeEntity;
  isEditable: boolean;
  onRemove: () => void;
  onChange: (
    attendee: JobWalkThroughAttendeeEntity,
  ) => void;
  onSignatureAdded: (signature: string) => void;
}

const JobWalkThroughAttendeeComponent = (
  props: JobWalkThroughAttendeeProps
) => {
  const signatureCanvasRef = useRef<SignatureCanvas>(null);

  const getSignature = () => {
    return (
      signatureCanvasRef.current
        ?.getTrimmedCanvas()
        ?.toDataURL("image/png")
        ?.split(",")
        .slice(1, 2)[0] ?? ""
    );
  };


  if (props.clearSignature) {
    signatureCanvasRef?.current?.clear();
  }

  return (
    <React.Fragment>
      <div className="d-flex flex-column-fluid align-items-center">
        <div
          key={`job-walkthrough-attendee-name-${props.componentIdex}`}
          className="container"
        >
          <EQInputText
            title="Name"
            placeholder="Name"
            inputText={props.attendee.name ?? ""}
            onOnInputChange={(result) => {
              if(props.isEditable) {
              const attendee = { ...props.attendee };
              attendee.name = result.toString();
              props.onChange(attendee);
              }
            }}
          />
          <EQInputText
            title="Company"
            placeholder="Company"
            inputText={props.attendee.company ?? ""}
            onOnInputChange={(result) => {
              if(props.isEditable) {
              const attendee = { ...props.attendee };
              attendee.company = result.toString();
              props.onChange(attendee);
              }
            }}
          />
        </div>
        <div
          key={`job-walkthrough-attendee-company-${props.componentIdex}`}
          className="container"
        >
          <div>
            <label className="mt-5 mr-10">Signature:</label>
            <label
              className="text-dark font-weight-bold"
              onClick={(event) => {
                signatureCanvasRef.current?.clear();
                props.onSignatureAdded("");
              }}
            >
              CLEAR
            </label>
            <div className="card card-body">
              <SignatureCanvas
                penColor="black"
                canvasProps={{ width: 400, height: 80 }}
                onEnd={(result) => {
                  props.onSignatureAdded(getSignature());
                }}
                ref={signatureCanvasRef}
              />
            </div>
          </div>
        </div>
        <div
          key={`job-walkthrough-delete-attendee-${props.componentIdex}`}
          className={`mt-2 mr-3 ${props.isEditable ? '' : 'invisible'}`}
          onClick={() => {
            props.onRemove();
          }}
        >
          <div
            key={`job-walkthrough-delete-attendee-icon${props.componentIdex}`}
            className="form-group"
          ></div>
          <Trash />
        </div>
      </div>
    </React.Fragment>
  );
};

export default JobWalkThroughAttendeeComponent;
