import React, { useContext, useEffect, useState } from "react";
import { SegmentEntity } from "../../../../shared/data/taskmanagement/Segment";
import TaskItemComponent from "../Task/TaskItemComponent";
import GoogleMapReact from "google-map-react";
import EQMarker from "../../../../components/GoogleMap/EQMarker";
import {
  calculateDistance,
  calculateMiddlePoint,
  parseFromFirebaseDate,
} from "../../../../shared/Util";
import { TaskEntity, TaskWithTimeLogsEntity } from "../../../../shared/data/taskmanagement/Task";
import { Link, useHistory } from "react-router-dom";
import { JobEntity } from "../../../../shared/data/job/Job";
import { getTasksWithTimeLogs } from "../../../../shared/api/TaskManagerApi";
import { getCrews } from "../../../../shared/Api";
import { AuthContext } from "../../../../shared/Auth";
import { Location } from "../../../../shared/data/Location";
import EQExportReportComponent, { ExportReportProps } from "../../../../components/Form/EQExportReportComponent";
import { LabelKeyObject } from "react-csv/lib/core";
import { CrewEntity } from "../../../../shared/data/Crew";

const SegmentDetailsItem = ({ title = "", value = "" }) => {
  return (
    <div className="mb-2">
      {value?.trim() && (
        <div>
          <div className="text-dark-75 font-weight-bolder mr-2">{title}</div>
          <div className="text-muted">{value}</div>
        </div>
      )}
    </div>
  );
};

interface SegmentDetailsProp {
  segment: SegmentEntity;
  segmentTasks?: TaskEntity[];
  job?: JobEntity;
}

export const SegmentDetails = ({
  segment,
  segmentTasks,
  job,
}: SegmentDetailsProp) => {
  const contextType = useContext(AuthContext);
  const history = useHistory()

  const numberOfTasks = segmentTasks?.length;

  const completedTasks = segmentTasks?.filter(
    (item) => item.taskStatus == "closed"
  ).length;

  const [tasksState, setTaskState] = useState<TaskWithTimeLogsEntity[]>([]);
  const [crewState, setCrewState] = useState<CrewEntity[]>([]);
  const [selectedTaskState, setSelectedTask] = useState<string | null>(null);

  const [reportState, setReportState] = useState<ExportReportProps>({reportTypes:["Excel"]});

  const getTasksData = async () => {
    const inventoryId = contextType.currentUser.company.inventoryID;
    const tasksWithTimeLogs = await getTasksWithTimeLogs(inventoryId, segment.jobID, segment.id);
    setTaskState(() => tasksWithTimeLogs?.data ?? []);
    buildReport(tasksWithTimeLogs?.data??[], segment)
  };

  const getCrws = async () => {
    const inventoryId = contextType.currentUser.company.inventoryID;
    const allCrws: any = await getCrews(inventoryId);
    setCrewState(() => allCrws?.data ?? []);
  };

  const buildReport = (
    tasks: TaskWithTimeLogsEntity[],
    segment: SegmentEntity
  ) => {
    const reportHeaders: LabelKeyObject[] = [
      { label: "Segment ID", key: "segmentId" },
      { label: "Segment Name", key: "segmentName" },
      { label: "Task ID", key: "taskId" },
      { label: "Task Title", key: "taskTitle" },
      { label: "Task Start Date", key: "startDate" },
      { label: "Task End Date", key: "endDate" },
      { label: "Task Type", key: "taskType" },
      { label: "Estimated Duration(h)", key: "estimatedDuration" },
      { label: "Current Duration(h)", key: "currentDuration" },
      { label: "Estimated Quantity", key: "estimatedQuantity" },
      { label: "Current Quantity", key: "currentQuantity" },
      { label: "Estimated Filled Yards", key: "expectedFilledYards" },
      { label: "Current Filled Yards", key: "currentFilledYards" },
      { label: "Estimated Footage", key: "expectedFootage" },
      { label: "Current Footage", key: "currentFootage" },
    ];
    const reportData = tasks.map((task) => {
      return {
        segmentId: segment.segmentID,
        segmentName: segment.title,
        taskId: task.task.taskID,
        taskTitle: task.task.title,
        startDate: parseFromFirebaseDate(task.task?.startDate, "DD/MM/YY hh:mm A"),
        endDate: parseFromFirebaseDate(task.task?.endDate, "DD/MM/YY hh:mm A"),
        taskType: task.task.taskType,
        estimatedDuration: task.task.estimatedDuration,
        currentDuration: task.task.currentDuration,
        estimatedQuantity: task.task?.expectedQuantity,
        currentQuantity: task.task?.currentQuantity,
        expectedFilledYards: task.task?.expectedFilledYards,
        currentFilledYards: task.task?.currentFilledYards,
        expectedFootage: task.task?.expectedFootage,
        currentFootage: task.task?.currentFootage,
      };
    });
    setReportState((previousState) => {
      return {
        ...previousState,
        data: reportData,
        header: reportHeaders,
        title: `report-segment-${segment.segmentID}`,
      };
    });
  };

  const getSegmentStartLocation = () => {
    if (segment.startLat && segment.startLng)
      return new Location(segment.startLat, segment.startLng);
    else return null;
  };

  const getSegmentEndLocation = () => {
    if (segment.endLat && segment.endLng)
      return new Location(segment.endLat, segment.endLng);
    else return null;
  };

  useEffect(() => {
    getTasksData();
    getCrws();
  }, []);

  return (
    <React.Fragment>
      <div className="card card-custom p-6">
        <div className="card-header flex-wrap p-0">
          <div className="card-title">
            <h3 className="card-label">
              Segment details
              <span className="d-block text-muted font-size-sm"></span>
            </h3>
          </div>
          <div className="card-toolbar">
            <EQExportReportComponent {...reportState} />
            <Link
              key="segment-details-add-new-task-btn"
              className="btn btn-primary float-right font-weight-bold ml-4"
              to={{
                pathname: `/jobs/${segment?.jobID}/taskmanagement/task`,
                state: {
                  taskDateMin: job?.startDate,
                  taskDateMax: job?.endDate,
                  task: new TaskEntity(segment.jobID, segment?.id ?? ""),
                  segmentStartLocation: new Location(
                    segment?.startLat ?? 0.0,
                    segment.startLng ?? 0.0
                  ),
                  segmentEndLocation: new Location(
                    segment?.endLat ?? 0.0,
                    segment.endLng ?? 0.0
                  ),
                  segmentTasksLocations: tasksState?.map((task) => {
                    return new Location(task.task?.lat ?? 0.0, task.task?.lng ?? 0.0);
                  }),
                },
              }}
            >
              Add new task
            </Link>
          </div>
        </div>
        <div style={{ height: "40vh", width: "100%" }}>
          <GoogleMapReact
            options={{
              mapTypeControl: true,
              mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
              },
              mapTypeId: "satellite",
            }}
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAP_KEY ?? "",
            }}
            defaultZoom={14}
            defaultCenter={{
              lat: segment?.startLat ?? 0.0,
              lng: segment?.startLng ?? 0.0,
            }}
            center={{
              lat: segment?.startLat ?? 0.0,
              lng: segment?.startLng ?? 0.0,
            }}
            yesIWantToUseGoogleMapApiInternals={false}
            onGoogleApiLoaded={({ map, maps }) => {
              new maps.Circle({
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF0000",
                fillOpacity: 0.1,
                map,
                center: calculateMiddlePoint(
                  segment.startLat,
                  segment.startLng,
                  segment.endLat,
                  segment.endLng
                ),
                radius:
                  calculateDistance(
                    segment.startLat,
                    segment.startLng,
                    segment.endLat,
                    segment.endLng
                  ) / 2,
              });
            }}
          >
            <EQMarker
              key={1}
              lat={segment?.startLat}
              lng={segment?.startLng}
              pinColor={"blue"}
              icon={"flaticon2-pin"}
            />
            <EQMarker
              key={2}
              lat={segment?.endLat}
              lng={segment?.endLng}
              pinColor={"blue"}
              icon={"flaticon2-pin"}
            />
            {tasksState?.map((task) => {
              return (
                <EQMarker
                  key={Math.random() * 100}
                  lat={task.task.lat}
                  lng={task.task.lng}
                  pinColor={"darkgreen"}
                  infoText={`#${task.task.taskID} - ${task.task.title}`}
                  onClick={() => {
                    setSelectedTask(() => task.task.id ?? null);
                  }}
                  show={selectedTaskState == task.task.id}
                  onInfoClicked={() => {
                    history.push({
                      pathname: `/jobs/${task?.task?.jobID}/tasks/${task?.task?.id}`,
                      state: {
                        task: task.task,
                      },
                    });
                  }}
                />
              );
            })}
          </GoogleMapReact>
        </div>
        <div className="card mb-6">
          <div className="mt-2 p-5">
            <SegmentDetailsItem title="Segment ID" value={`#${segment.segmentID}`} />
            {segment.title && <SegmentDetailsItem title="Segment Name" value={segment.title} />}
            {segment.startDate && <SegmentDetailsItem title="Start Date" value={parseFromFirebaseDate(segment.startDate)} />}
            {segment.soilConditions && <SegmentDetailsItem title="Soil conditions" value={segment.soilConditions} />}
            {segment.summary && <SegmentDetailsItem title="Measurables" value={segment.summary} />}
            {segment.description && <SegmentDetailsItem title="Description" value={segment.description} />}
            {tasksState?.reduce(
              (total, task) =>
                (total = total + (task.task.estimatedDuration ?? 0)),
              0
            ) && (
              <SegmentDetailsItem
                title="Estimated duration"
                value={`${tasksState?.reduce(
                  (total, task) =>
                    (total = total + (task.task.estimatedDuration ?? 0)),
                  0
                )} h`}
              />
            )}
            {completedTasks && (
              <SegmentDetailsItem
                title="Task Progress"
                value={`${completedTasks} of ${numberOfTasks}`}
              />
            )}
          </div>
        </div>

        <div className="card card-custom card-shadowless rounded-top-0">
          {segment &&
            tasksState?.map((task) => {
              return (
                <div>
                  <TaskItemComponent
                    task={task.task}
                    allCrews={crewState}
                    segmentStartLocation={getSegmentStartLocation()}
                    segmentEndLocation={getSegmentEndLocation()}
                    segmentTasksLocations={tasksState.map((task) => {
                      return new Location(task?.task?.lat ?? 0, task?.task?.lng ?? 0);
                    })}
                    canEdit={true}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SegmentDetails;
