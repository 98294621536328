import React from "react";
import EQLabel from "../../../components/Form/Inputs/EQLabel";
import { getJobStatusColor, JobEntity, JobStatus, JobStatusEntity, JobStatusLabels } from "../../../shared/data/job/Job";
import EQInlineLabel from "../../../components/Form/Inputs/EQInlineLabel";
import { parseFromFirebaseDate } from "../../../shared/Util";

interface JobDetailsPreviewProps {
  job:JobEntity,
  jobStatus?:JobStatusEntity
}

const JobDetailsPreview = (props:JobDetailsPreviewProps) => {

  const jobStatus = props?.jobStatus?.jobStatus ?? props.job.status ?? JobStatus.published

  return <React.Fragment>
    <div>
      <EQInlineLabel  title="Job" label={props?.job?.title??""}/>
      <EQInlineLabel  title="ID" label={props?.job?.jobID??""}/>
      <EQInlineLabel  title="Dates" label={`${parseFromFirebaseDate(props?.job?.startDate)} - ${parseFromFirebaseDate(props?.job?.endDate)}`}/>
      <EQInlineLabel  title="Status" label={JobStatusLabels[jobStatus]} class={`${getJobStatusColor(jobStatus)} font-weight-bolder`}/>
      <EQInlineLabel  title="Description" label={props?.job?.description ??""}/>
    </div>
  </React.Fragment>;
};

export default JobDetailsPreview;
