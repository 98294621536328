import { Timestamp } from "firebase/firestore"
import { FirebaseInventoriesCollections, FirebaseJobCollections, FirebaseRootCollections } from "../../firebase/FirebaseSchema"
import { CrewEntity } from "../Crew"

export class JobEntity {
    id?: string | null
    clientID?: string | null
    clients?: string[] | null
    jobClients?: JobClient[] | null
    jobID?: string | null
    description?: string | null
    startDate?: Timestamp | null
    endDate?: Timestamp | null
    isOverdue?: boolean = false
    locationLat?: number | null
    locationLong?: number | null
    locationName?: string | null
    photos?: string[] | null
    status?: JobStatus | null
    title?: string | null
    totalFootage?: string | null
    crews?:CrewEntity [] | null
}

export class JobClient {
    clientID?: string
    contactIDs: string[] = []

    constructor(clientID: string, contactIDs: string[]){
        this.clientID = clientID
        this.contactIDs = contactIDs
    }
}

export interface JobStatusEntity {
    documentID?: string | null;
    jobStatus: JobStatus;
    bidStatus?: BidStatus | null;
    dateQuoted?: Timestamp | null;
    quotedNotes?: string | null;
    reasonLost?: string | null;
    dateAwarded?: Timestamp | null;
    dateOnHold?: Timestamp | null;
    dateToResume?: Timestamp | null;
    reasonOnHold?: string | null;
    dateCompleted?: Timestamp | null;
    datePaymentApproved?: Timestamp | null;
    approvedBy?: string | null;
    datePaid?: Timestamp | null;
    paymentNotes?: string | null;
    createdAt: Timestamp;
    userName?: string | null;
    userID?: string | null;
  }

export enum JobStatus {
    draft = "draft",
    upcoming = "upcoming",
    published = "published",
    onHold = "onHold",
    completed = "completed",
}

export const getJobStatusColor = (status:JobStatus)=>{
    switch(status){
        case JobStatus.draft: return "job-status-bid"
        case JobStatus.upcoming: return "job-status-upcoming"
        case JobStatus.completed: return "job-status-completed"
        case JobStatus.onHold: return "job-status-on-hold"
        case JobStatus.published: return "job-status-active"
    }
}

export const JobStatusLabels:Record<JobStatus, string> = {
    [JobStatus.draft]: "Bid",
    [JobStatus.upcoming]: "Upcoming Job",
    [JobStatus.published]: "Active",
    [JobStatus.onHold]: "On Hold",
    [JobStatus.completed]: "Completed",
}

export enum BidStatus{
    readyForQuoting = "readyForQuoting",
    quotedWaiting = "quotedWaiting",
    quotedLost = "quotedLost",
    quotedWon = "quotedWon"
}

export const BidStatusLabels:Record<BidStatus, string> = {
    [BidStatus.readyForQuoting]: "Ready for Quoting",
    [BidStatus.quotedWaiting]: "Quoted - Waiting",
    [BidStatus.quotedLost]: "Quoted - Lost",
    [BidStatus.quotedWon]: "Quoted - Won",
}

export class DailyProductionCheckInEntity {
    jobID?: string | null;
    userID?: string | null;
    day?: string | null;
    startTime?: string | null;
    endTime?: string | null;
    accomplishments?: string | null;
    crewmembers?: string[] | null = [];
    hurdles?: string | null = null;
    notes?: string | null = null;
    saveFile: boolean = true;
    recipients: string[] = [];
    workTimes: WorkTimeEntity[] = []
    safetyTalk: string = "Yes"
    pdfExcel: number = 0
    timezone: number = 0
}

export default class WorkTimeEntity {
    firstName: string
    lastName: string
    startTime?: string | null = null
    endTime?: string | null = null
    crewName?: string | null = null
    travelTime: string = "0"
    phoneNumber?: string | null = null
    email?: string | null = null
    signature?: string | null = null
    safetyTalk: boolean = false
    isExternal: boolean = false
    notOnJob: boolean | null = false

    constructor(firstName:string, lastName:string)
    constructor(
        firstName: string, 
        lastName: string,
        startTime: string | null = null,
        endTime: string | null = null,
        crewName: string | null = null,
        phoneNumber: string | null = null,
        email: string | null = null,
        signature: string | null = null,
        travelTime: string = "0",
        safetyTalk: boolean = false,
        isExternal: boolean = false) {
        this.firstName = firstName
        this.lastName = lastName
        this.startTime = startTime
        this.endTime = endTime
        this.crewName = crewName
        this.travelTime = travelTime
        this.phoneNumber = phoneNumber
        this.email = email
        this.signature = signature
        this.safetyTalk = safetyTalk
        this.isExternal = isExternal
    }
}

export const buildJobPhotoEntityPath = (inventoryId: string, jobId:string) => {
    return `/${FirebaseRootCollections.INVENTORIES}/${inventoryId}/${FirebaseInventoriesCollections.JOBS}/${jobId}/${FirebaseJobCollections.PHOTOS}`
}

export const buildJobFileEntityPath = (inventoryId: string, jobId:string) => {
    return `/${FirebaseRootCollections.INVENTORIES}/${inventoryId}/${FirebaseInventoriesCollections.JOBS}/${jobId}/${FirebaseJobCollections.FILES}`
}

export const buildJobFileUploadPath = (companyId: string, jobId: string, folderName: string) => {
    return `${companyId}/Files/${jobId}/${folderName}`
}

export const buildJobFilesPath = (companyId:string, jobId: string) => {
    return `${companyId}/Files/${jobId}`
}

export const buildJobPhotoUploadPath = (companyId: string, jobId: string, folderName: string) => {
    return `${companyId}/job/${jobId}/${folderName}`
}

export const buildJobPhotoPath = (companyId:string, jobId: string) => {
    return `${companyId}/job/${jobId}`
}