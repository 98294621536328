import React, { Component } from 'react';
import BasicForm from '../../../components/Form/BasicForm';
import { resetFormValues, touchField, updateFormOnChange, fieldsToObject } from '../../../shared/FormHelper';
import { AuthContext } from "../../../shared/Auth";
import { addMaterial, uploadImage, getMaterial } from "../../../shared/Api";
import { getMessages } from '../../../components/Messages/GetMessages';

class JobEquipmentNew extends Component {
    static contextType = AuthContext;

    state = {
        loading: false,
        newEquipmentForm: {
            name: 'add-new-equipment',
            isValid: false,
            submitErrorMessage: null,
            loading: false,
            fields: [
                {
                    name: 'title',
                    value: '',
                    defaultValue: '',
                    type: 'text',
                    label: 'Equipment Name',
                    placeholder: 'Equipment Name',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('equipment_name'),
                    required: true,
                    validators: [
                        {
                            type: 'required',
                            message: getMessages('equipment_name_required')
                        }
                    ]
                },
                {
                    name: 'description',
                    value: '',
                    defaultValue: '',
                    type: 'textarea',
                    label: 'Description (optional)',
                    placeholder: 'Description',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('equipment_description'),
                    required: false,
                    rows: 4
                },
                {
                    name: 'image',
                    value: '',
                    defaultValue: '',
                    type: 'dropzone',
                    label: 'Equipment Photo',
                    placeholder: 'Equipment Photo',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: '',
                    required: false,
                    localSrc: '',
                    onDrop: (acceptedFiles) => { this.onImageDrop(acceptedFiles); },
                    onRemove: () => { this.onImageRemove(); }
                }
            ],
            buttons: [
                {
                    title: 'Add Equipment',
                    className: 'btn btn-primary font-weight-bolder text-uppercase px-9 py-4 mr-2 ',
                    onClick: () => this.addMaterial(),
                    disabled: () => false
                },
                {
                    title: 'Cancel',
                    className: 'btn btn-light-secondary font-weight-bolder text-uppercase px-9 py-4',
                    onClick: () => this.props.history.goBack(),
                    disabled: () => false
                }
            ]
        }
    };

    onImageDrop = (acceptedFiles) => {
        let form = { ...this.state.newEquipmentForm };
        let imageField = form.fields.find(x => x.name === 'image');

        const loc = window.URL.createObjectURL(acceptedFiles[0]);

        imageField.value = acceptedFiles[0];
        imageField.localSrc = loc;
        this.setState({ newEquipmentForm: form });
    }

    onImageRemove = () => {
        let form = { ...this.state.newEquipmentForm };
        let imageField = form.fields.find(x => x.name === 'image');

        imageField.value = '';
        imageField.localSrc = '';
        this.setState({ newEquipmentForm: form });
    }

    addMaterial = async () => {
        const currentUser = this.context.currentUser;
        const inventoryId = currentUser.company.inventoryID;
        const companyId = currentUser.userProfile.companyID;
        const jobId = this.props.match.params.id;
        const equipment = fieldsToObject(this.state.newEquipmentForm.fields);
        equipment.associatedDate = new Date();
        equipment.jobID = jobId;

        const imageField = this.state.newEquipmentForm.fields.find(x => x.name === 'image');
        if (imageField.value) {
            equipment.imageTimeStamp = new Date();
            equipment.hasProfileImage = true;
        }

        const eq = await addMaterial(inventoryId, companyId, equipment);

        if (imageField.value) {
            const uploadedEquipment = await getMaterial(inventoryId, eq.data);
            await uploadImage(companyId, 'customChecklist', eq.data, uploadedEquipment.data.imageTimeStamp, imageField.value)
        }        
        this.props.history.goBack()
        // this.props.history.push('/jobs/' + jobId + '/details/checklist');
    }

    prepareAddEquipmentForm = () => {
        let form = this.state.newEquipmentForm;

        form.handleInputChange = (event, obj) => {
            const updatedForm = updateFormOnChange(event, obj, this.state.newEquipmentForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        form.touchField = (event) => {
            const updatedForm = touchField(event, this.state.newEquipmentForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        return form;
    }

    render() {
        const addEquipmentForm = this.prepareAddEquipmentForm();

        return (
            <div className="card">
                <div className="card-body">
                    <h3 className="mb-10 font-weight-bold text-dark">Add new material</h3>
                    <div className="mb-20">
                        <BasicForm {...addEquipmentForm} />
                    </div>
                </div>
            </div>
        );
    }
}

export default JobEquipmentNew;