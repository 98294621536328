import React, { useEffect } from "react";
import moment from 'moment';
import { isEmpty } from "../../../shared/Validation";
import Moment from "react-moment";
// If using moment-range, you might need to extend moment as follows:
// import { extendMoment } from 'moment-range';
// const momentRange = extendMoment(moment);

interface DateRangePickerProps {
  id: string;
  title?: string | null;
  hint?: string | null;
  required?: boolean;
  startDate?: moment.Moment | string | null;
  endDate?: moment.Moment | string | null;
  minDate?: moment.Moment | string | null;
  handleChange: (
    range: { startDate: moment.Moment; endDate: moment.Moment }
  ) => void;
  invalidRanges?: {
    startDate: moment.Moment | string;
    endDate: moment.Moment | string;
  }[];
  className?: string;
  index?: number;
  placeholder?: string;
}

const EQDateRangePicker: React.FC<DateRangePickerProps> = (props) => {
  // Checks if the given date falls within any invalid range.
  const isValid = (date: moment.Moment): boolean => {
    if (!props.invalidRanges) return true;
    let valid = true;
    props.invalidRanges.forEach((element) => {
      // Using moment-range; adjust this if you're using an alternative approach.
      const range = (moment as any).range(element.startDate, element.endDate);
      if (range.contains(date)) {
        valid = false;
      }
    });
    return valid;
  };

  useEffect(() => {

    const $input = (window as any).$(`#${props.id}`);

    // Destroy any existing daterangepicker instance.
    if ($input.data("daterangepicker")) {
      $input.data("daterangepicker").remove();
    }

    // Determine if we should auto-update the input:
    // If both startDate and endDate are provided, let the plugin update the input automatically.
    const autoUpdate = !isEmpty(props.startDate) && !isEmpty(props.endDate);

    $input.daterangepicker(
      {
        autoUpdateInput: autoUpdate,
        buttonClasses: " btn",
        applyClass: "btn-primary",
        cancelClass: "btn-secondary",
        startDate: props.startDate ? moment(props.startDate) : moment,
        endDate: props.endDate ? moment(props.endDate) : moment,
        minDate: props.minDate,
        isInvalidDate: (date: moment.Moment) => !isValid(date),
      },
      function (start: moment.Moment, end: moment.Moment, label: string) {
          props.handleChange({ startDate: start, endDate: end });
      }
    );

  }, [
    props.id,
    props.startDate,
    props.endDate,
    props.minDate,
    props.invalidRanges,
    props.handleChange,
  ]);

  // Effect to clear the input when dates are cleared.
  useEffect(() => {
    if (!props.startDate && !props.endDate) {
      const $input = (window as any).$(`#${props.id}`);
      $input.val(""); // clear the input text
    }
  }, [props.startDate, props.endDate, props.id]);

  return (
    <div className="form-group">
      {props.title && (
        <label>
          {props.title}{" "}
          <span className="text-danger">{props.required ? "*" : null}</span>
        </label>
      )}
      <input
        type="text"
        className="form-control"
        autoFocus={props.index === 0}
        id={props.id}
        readOnly
        placeholder={props.placeholder}
      />
      {props.hint && <div className="form-text text-muted">{props.hint}</div>}
    </div>
  );
};

export default EQDateRangePicker;
