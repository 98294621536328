import React, { useState } from "react";

interface EQLabelProps {
  title: string;
  label: string;
  numberOfLines?: number;
  class?: string;
}

const EQInlineLabel = (props: EQLabelProps) => {
  return (
    <React.Fragment>
      <div className="d-flex justify-content-between mb-2">
        <span className="text-dark-75 font-weight-bolder mr-2">
          {props.title}
        </span>
        {props.label && (
          <span className={`${props.class ?? "text-muted text-hover-primary"}`}>
            {props.label}
          </span>
        )}
      </div>
    </React.Fragment>
  );
};

export default EQInlineLabel;
