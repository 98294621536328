import React, { useState } from "react";
import EQSelect from "../../../components/Form/Inputs/EQSelect";
import EQInputText from "../../../components/Form/Inputs/EQInputText";
import { parseFromFirebaseDate, toFirebaseDate } from "../../../shared/Util";
import {
  BidStatus,
  BidStatusLabels,
  JobStatus,
  JobStatusEntity,
  JobStatusLabels,
} from "../../../shared/data/job/Job";
import EQDatePicker from "../../../components/Form/Inputs/EQDatePicker";
import { Timestamp } from "firebase/firestore";
import EQCheckBox from "../../../components/Form/Inputs/EQCheckBox";

/**
 * JobStatusComponentProps
 * @interface
 * @property {JobStatusEntity} jobStatus
 * @property {boolean} isJobCreation this flag is used to determine if the job is being created or updated. When creating a job, the not all statuses are available.
 * @property {(jobStatus: JobStatusEntity) => void} onJobStatusChanged
 */
interface JobStatusComponentProps {
  jobStatus: JobStatusEntity;
  isJobCreation: boolean;

  onJobStatusChanged: (jobStatus: JobStatusEntity) => void;
}

const JobStatusComponent = (props: JobStatusComponentProps) => {
  const [initialJobStatus, setInitialJobsStatus] = useState<JobStatusEntity>(
    props.jobStatus
  );

  const [formState, setFormState] = useState({
    approvedForPayment: props?.jobStatus?.datePaymentApproved != null,
    fullPayed: props?.jobStatus?.datePaid != null,
  });

  const getJobStatuses = () => {
    return Object.values(JobStatus)
      .map((status) => {
        return {
          key: status,
          value: JobStatusLabels[status],
        };
      })
      .filter((status) => {
        return showStatus(status.key);
      });
  };

  /**
   * Determines whether a specific job status should be available as an option based on the current job state.
   *
   * The function evaluates several conditions to prevent displaying statuses that are not applicable given the job's
   * current or initial state.
   *
   * Conditions:
   * 1. When creating a job (`props.isJobCreation` is true):
   *    - The statuses "on hold" and "completed" should not be available.
   *
   * 2. If the initial job status was "upcoming":
   *    - The status "draft" should not be allowed (to avoid reverting to an earlier state).
   *
   * 3. If the initial job status was "published", "on hold", or "completed":
   *    - The statuses "draft" and "upcoming" should be disallowed, preventing regression to earlier stages.
   *
   * @param {JobStatus} jobStatus - The candidate job status to evaluate.
   * @returns {boolean} - Returns true if the job status can be shown as an option; otherwise, returns false.
   */
  const showStatus = (jobStatus: JobStatus) => {
    // Condition 1: During job creation, do not show "on hold" or "completed" statuses.
    if (
      props.isJobCreation &&
      (jobStatus === JobStatus.onHold || jobStatus === JobStatus.completed)
    )
      return false;

    // Condition 2: If the initial status is "upcoming", "active" or "on hold", disallow transitioning back to "draft".
    if (
      (initialJobStatus?.jobStatus == JobStatus.upcoming ||
        initialJobStatus?.jobStatus == JobStatus.published ||
        initialJobStatus?.jobStatus == JobStatus.onHold) &&
      jobStatus == JobStatus.draft
    )
      return false;

    // Condition 3: If the initial status is "completed", disallow transitioning back to "draft" and "upcoming".
    if (
      initialJobStatus?.jobStatus == JobStatus.completed &&
      (jobStatus == JobStatus.upcoming || jobStatus == JobStatus.draft)
    )
      return false;

    // If none of the above conditions are met, the status is valid and can be shown.
    return true;
  };

  const getCurrentJobStatus = () => {
    const status = props.jobStatus?.jobStatus ?? JobStatus.published;
    return JobStatusLabels[status];
  };

  const getBidStatuses = () => {
    return Object.values(BidStatus).map((status) => {
      return {
        key: status,
        value: BidStatusLabels[status],
      };
    });
  };

  const getCurrentBidStatus = () => {
    return BidStatusLabels[props.jobStatus?.bidStatus ?? "readyForQuoting"];
  };

  const getBidJobStatus = () => {
    return (
      <div>
        <EQSelect
          title="Bid Status"
          selectorTitle="Select Bid Status"
          selectedValue={getCurrentBidStatus()}
          items={getBidStatuses()}
          onSelectionChanged={(item) => {
            const selectedKey = item.key?.toString() ?? "readyForQuoting";
            props.onJobStatusChanged({
              ...props.jobStatus,
              createdAt: Timestamp.now(),
              bidStatus: BidStatus[selectedKey as keyof typeof BidStatus],
            });
          }}
        />
        {props.jobStatus.bidStatus === BidStatus.quotedLost && (
          <EQInputText
            title="Reason lost"
            inputText={props.jobStatus.reasonLost ?? ""}
            numberOfLines={3}
            onOnInputChange={(value) => {
              props.onJobStatusChanged({
                ...props.jobStatus,
                reasonLost: value.toString(),
              });
            }}
          />
        )}
        {props.jobStatus.bidStatus === BidStatus.quotedWaiting && (
          <div>
            <EQDatePicker
              title="Date Quoted"
              date={parseFromFirebaseDate(props?.jobStatus?.dateQuoted)}
              onDateSelected={(dateSelected) => {
                props.onJobStatusChanged({
                  ...props.jobStatus,
                  dateQuoted: toFirebaseDate(dateSelected),
                });
              }}
            />
            <EQInputText
              title="Notes"
              placeholder="Add Notes"
              inputText={props.jobStatus.quotedNotes ?? ""}
              numberOfLines={3}
              onOnInputChange={(value) => {
                props.onJobStatusChanged({
                  ...props.jobStatus,
                  quotedNotes: value.toString(),
                });
              }}
            />
          </div>
        )}
      </div>
    );
  };

  const getUpcomingJobStatus = () => {
    return (
      <div>
        <EQDatePicker
          title="Date Awarded"
          date={parseFromFirebaseDate(props?.jobStatus?.dateAwarded)}
          onDateSelected={(dateSelected) => {
            props.onJobStatusChanged({
              ...props.jobStatus,
              dateAwarded: toFirebaseDate(dateSelected),
            });
          }}
        />
      </div>
    );
  };

  const getPublishedJobStatus = () => {
    return <div></div>;
  };

  const getOnHoldStatus = () => {
    return (
      <div>
        <EQDatePicker
          title="Date job put on hold"
          date={parseFromFirebaseDate(props?.jobStatus?.dateOnHold)}
          onDateSelected={(dateSelected) => {
            props.onJobStatusChanged({
              ...props.jobStatus,
              dateOnHold: toFirebaseDate(dateSelected),
            });
          }}
        />
        <EQDatePicker
          title="Date job expected to Resume"
          date={parseFromFirebaseDate(props?.jobStatus?.dateToResume)}
          onDateSelected={(dateSelected) => {
            props.onJobStatusChanged({
              ...props.jobStatus,
              dateToResume: toFirebaseDate(dateSelected),
            });
          }}
        />
        <EQInputText
          title="Reason for job being On Hold"
          placeholder="Add Notes"
          inputText={props.jobStatus.reasonOnHold ?? ""}
          numberOfLines={3}
          onOnInputChange={(value) => {
            props.onJobStatusChanged({
              ...props.jobStatus,
              reasonOnHold: value.toString(),
            });
          }}
        />
      </div>
    );
  };

  const getCompletedJobStatus = () => {
    return (
      <div>
        <EQDatePicker
          title="Date completed"
          date={parseFromFirebaseDate(props?.jobStatus?.dateCompleted)}
          onDateSelected={(dateSelected) => {
            props.onJobStatusChanged({
              ...props.jobStatus,
              dateCompleted: toFirebaseDate(dateSelected),
            });
          }}
        />
        <EQCheckBox
          title="Approved for payment"
          checked={formState.approvedForPayment}
          onChecked={(checked) => {
            setFormState({ ...formState, approvedForPayment: checked });
          }}
        />
        {formState.approvedForPayment && (
          <div>
            <EQDatePicker
              title="Date approved for Payment"
              date={parseFromFirebaseDate(props.jobStatus?.datePaymentApproved)}
              onDateSelected={(dateSelected) => {
                props.onJobStatusChanged({
                  ...props.jobStatus,
                  datePaymentApproved: toFirebaseDate(dateSelected),
                });
              }}
            />
            <EQInputText
              title="Approved by Who?"
              placeholder="Approved by Who?"
              inputText={props.jobStatus.approvedBy ?? ""}
              onOnInputChange={(value) => {
                props.onJobStatusChanged({
                  ...props.jobStatus,
                  approvedBy: value.toString(),
                });
              }}
            />
            <EQCheckBox
              title="Fully paid"
              checked={formState.fullPayed}
              onChecked={(checked) => {
                setFormState({ ...formState, fullPayed: checked });
              }}
            />
            {formState.fullPayed && (
              <div>
                <EQDatePicker
                  title="Date payed"
                  date={parseFromFirebaseDate(props?.jobStatus?.datePaid)}
                  onDateSelected={(dateSelected) => {
                    props.onJobStatusChanged({
                      ...props.jobStatus,
                      datePaid: toFirebaseDate(dateSelected),
                    });
                  }}
                />
                <EQInputText
                  title="Payment Notes"
                  placeholder="Payment Notes"
                  inputText={props.jobStatus.paymentNotes ?? ""}
                  numberOfLines={3}
                  onOnInputChange={(value) => {
                    props.onJobStatusChanged({
                      ...props.jobStatus,
                      paymentNotes: value.toString(),
                    });
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const getJobStatusView = (jobStatus: JobStatusEntity) => {
    switch (jobStatus?.jobStatus) {
      case JobStatus.draft:
        return getBidJobStatus();
      case JobStatus.upcoming:
        return getUpcomingJobStatus();
      case JobStatus.published:
        return getPublishedJobStatus();
      case JobStatus.onHold:
        return getOnHoldStatus();
      case JobStatus.completed:
        return getCompletedJobStatus();
      default:
        return <div></div>;
    }
  };

  return (
    <React.Fragment>
      <EQSelect
        title="Job Status"
        selectorTitle="Select Job Status"
        selectedValue={getCurrentJobStatus()}
        items={getJobStatuses()}
        onSelectionChanged={(item) => {
          const selectedKey = item.key?.toString() ?? "published";
          props.onJobStatusChanged({
            jobStatus: JobStatus[selectedKey as keyof typeof JobStatus],
            createdAt: Timestamp.now(),
          });
        }}
      />
      {getJobStatusView(props.jobStatus)}
    </React.Fragment>
  );
};

export default JobStatusComponent;
