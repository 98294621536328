import { Timestamp } from "firebase/firestore";

export function getKeyFromValue<T extends Record<string, string>>(enumObj: T, value: T[keyof T]): keyof T | undefined {
    return (Object.keys(enumObj) as (keyof T)[]).find((key) => enumObj[key] === value);
  }

export const getFirstElement = <T>(arr: T[]): T | null => {
    return arr.length > 0 ? arr[0] : null;
};

export function isNullOrEmpty(str: string | null | undefined): boolean {
  return !str || str.trim() === '';
}

export function capitalizeFirstLetter(input: string): string {
  if (!input) return ''
  return input.charAt(0).toUpperCase() + input.slice(1)
}

export function isBetweenDates(startDate?: Date | null, endDate?: Date | null, checkDate?: Date | null): boolean {
  return (checkDate?.getTime() ?? 1) <= (endDate?.getTime() ?? 0) && (checkDate?.getTime() ?? 1) >= (startDate?.getTime() ?? 2);
}

export function isTimeSpanWithin(outerStart: Date, outerEnd: Date, innerStart?: Date | null, innerEnd?: Date | null): boolean {
  if(innerStart == null || innerEnd == null) return false
  return innerStart <= outerEnd && innerEnd >= outerStart;

}

export function getStartOfDayTimestamp(date?: Timestamp): Timestamp | null {
  const jsDate = date?.toDate();
  if (!jsDate) {
    return null;
  }
  jsDate.setHours(0, 0, 0, 0); // Set time to 00:00:00
  return Timestamp.fromDate(jsDate);
};

export function getTimezoneOffset(): number {
  return new Date().getTimezoneOffset() / -60;
}

Promise.allSettled = Promise.allSettled || (<T>(promises: Promise<T>[]) => Promise.all(
  promises?.map(p => p
    .then(value => ({
      status: "fulfilled" as const,
      value
    }))
    .catch(reason => ({
      status: "rejected" as const,
      reason
    }))
  ) ?? []
));

export function convertToNumberOrNull(value:any): number | null {
  const number = Number(value);
  return isNaN(number) || value === '' ? null : number;
};