import { Component } from 'react';
import JobDetailsStep from './Steps/JobDetailsStep';
import JobLocationStep from './Steps/JobLocationStep';
import ClientDetailsStep from './Steps/ClientDetailsStep';
import EquipmentStep from './Steps/EquipmentStep';
import MaterialsStep from './Steps/MaterialsStep';
import CrewmembersStep from './Steps/CrewmembersStep';
import { Route } from 'react-router-dom'
import { AuthContext } from "../../../shared/Auth";
import { addJob, addReservation, addCustomChecklist, addCrewSchedule, uploadImage, getCustomChecklists, getMaterial, getEquipment, getCrewmembers, addCrewAppoitment, getCrewSchedules, getReservations, getCrewAppointments, getJobs, getPresetFolders, addFolder } from '../../../shared/Api';
import { ArrowRight, Company, CrewmemberIos, CrewsIos, EquipmentIos, Folder, JobsIos, Marker, Materials, PlusIcon } from '../../../components/Icons/Svg';
import CrewStep from './Steps/CrewStep';
import { isOverlap } from '../../../shared/Util';
import moment from 'moment';
import JobFoldersStep from './Steps/JobFoldersStep';
import { updateJob } from '../../../shared/Api';
import { JobStatus } from '../../../shared/data/job/Job';
import { Timestamp } from "firebase/firestore";
import { saveJobStatus } from '../../../shared/api/JobApi';


class JobNew extends Component {
  static contextType = AuthContext;
  isTutorial = this.context.isTutorial;
  tutorialStep = this.context.tutorialStep;

  state = {
    obj: {
      job: null,
      jobStatusState: null,
    },
    step: null,
    saving: false,
    equipment: [],
    crewmembers: [],
    unavailableEquipment: [],
    unavailableCrewmembers: [],
    unavailableCrews: [],
  };

  componentDidMount = async () => {
    if (
      this.isTutorial &&
      ["jobReport", "bores"].includes(this.tutorialStep) &&
      localStorage.getItem("tmp-job-id")
    ) {
      this.props.history.push(
        "/jobs/" + localStorage.getItem("tmp-job-id") + "/details"
      );
    }

    this.initJobStatus();
    await this.loadCrewmembers();
    await this.loadEquipment();
    this.onDateChange(moment(), moment().add("days", 7));
  };

  initJobStatus = () => {
    const jobStatusState = {
      jobStatus: JobStatus.upcoming,
      createdAt: Timestamp.now(),
      userName: this.context?.currentUser?.displayName ?? "",
      userID: this.context?.currentUser?.uid ?? "",
    };

    let updatedObj = { ...this.state.obj };
    updatedObj.jobStatusState = jobStatusState;
    this.setState({ obj: updatedObj });
  };

  loadEquipment = async () => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const companyId = currentUser.userProfile.companyID;

    const equipment = await getEquipment(inventoryId, companyId, true, false);

    this.setState({ equipment: equipment.data });
  };

  loadCrewmembers = async () => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const companyId = currentUser.userProfile.companyID;

    const equipment = await getCrewmembers(inventoryId, companyId, true, false);
    this.setState({ crewmembers: equipment.data });
  };

  updateCrewmembers = (crewmembers) => {
    this.setState({ crewmembers: crewmembers });
  };

  updateEquipment = (equipment) => {
    this.setState({ equipment: equipment });
  };

  setJobDetails = async (job) => {
    let updatedObj = { ...this.state.obj };
    updatedObj.job = job;
    this.setState({ obj: updatedObj });
    await this.saveJob(job);
    await this.saveStatus(job.documentId);
    return this.state.obj.job;
  };

  saveStatus = async (jobId) => {
    var jobStatus = {
      ...this.state.obj.jobStatusState,
      userID: this.context?.currentUser?.uid ?? "",
      userName: this.context?.currentUser?.displayName ?? "",
    };
    const result = await saveJobStatus(
      this.context.currentUser?.company?.inventoryID ?? "",
      jobId ?? "",
      jobStatus
    );
  };

  setJobStatus = (jobStatus) => {
    let updatedObj = { ...this.state.obj };
    updatedObj.jobStatusState = jobStatus;
    this.setState({ obj: updatedObj });
  };

  setJobClients = (jobClients) => {
    let updatedObj = { ...this.state.obj };
    updatedObj.job.jobClients = jobClients;

    this.setState({ obj: updatedObj });
  };

  setJobClient = async (clientId) => {
    let updatedObj = { ...this.state.obj };
    updatedObj.job.clientID = clientId;

    this.setState({ obj: updatedObj });
  };

  onDateChange = (startDate, endDate) => {
    this.loadUnavailableEquipment(startDate, endDate);
    this.loadUnavailableCrewmembers(startDate, endDate);
    this.loadUnavailableCrews(startDate, endDate);
  };

  loadUnavailableEquipment = async (startDate, endDate) => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    const reservations = await getReservations(inventoryId);

    const conflicts = reservations.data.filter((r) =>
      isOverlap(startDate, endDate, r.startDate.toDate(), r.endDate.toDate())
    );

    const unavailable = conflicts.map((x) => x.equipmentID);
    this.setState({ unavailableEquipment: unavailable });
  };

  loadUnavailableCrewmembers = async (startDate, endDate) => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    const reservations = await getCrewSchedules(inventoryId);

    const conflicts = reservations.data.filter((r) =>
      isOverlap(startDate, endDate, r.startDate.toDate(), r.endDate.toDate())
    );

    const unavailable = conflicts.map((x) => x.crewMemberID);
    this.setState({ unavailableCrewmembers: unavailable });
  };

  loadUnavailableCrews = async (startDate, endDate) => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    const reservations = await getCrewAppointments(inventoryId);
    const jobs = await getJobs(inventoryId);
    const jobIds = jobs.data.map((x) => x.id);

    const conflicts = reservations.data.filter(
      (r) =>
        jobIds.includes(r.jobID) &&
        isOverlap(startDate, endDate, r.startDate.toDate(), r.endDate.toDate())
    );

    const unavailable = conflicts.map((x) => x.crewID);
    this.setState({ unavailableCrews: unavailable });
  };

  setCrews = async (crewIds) => {
    let updatedObj = { ...this.state.obj };
    updatedObj.selectedCrewIds = crewIds;

    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    const allEquipment = await getEquipment(inventoryId, null, false, false);
    const selectedEquipment = allEquipment.data.filter((x) =>
      crewIds.includes(x.crewID)
    );

    const allCrewmembers = await getCrewmembers(
      inventoryId,
      null,
      false,
      false
    );
    const selectedCrewmembers = allCrewmembers.data.filter((x) =>
      crewIds.includes(x.crewID)
    );

    updatedObj.equipment = selectedEquipment.map((x) => x.id);
    updatedObj.crew = selectedCrewmembers.map((x) => x.id);

    this.setState({ obj: updatedObj });
  };

  setJobEquipment = async (equipment) => {
    let updatedObj = { ...this.state.obj };
    updatedObj.equipment = equipment;

    this.setState({ obj: updatedObj });
  };

  setCrewMembers = async (crew) => {
    let updatedObj = { ...this.state.obj };
    updatedObj.crew = crew;

    this.setState({ obj: updatedObj });
  };

  setDocuments = async (documents) => {
    let updatedObj = { ...this.state.obj };
    updatedObj.documents = documents;

    this.setState({ obj: updatedObj });
  };

  setJobLocation = async (address, lat, lng) => {
    let updatedObj = { ...this.state.obj };
    updatedObj.job.locationLat = lat;
    updatedObj.job.locationLong = lng;
    updatedObj.job.locationName = address;

    this.setState({ obj: updatedObj });
  };

  setJobMaterials = async (materials) => {
    let updatedObj = { ...this.state.obj };
    updatedObj.materials = materials;

    this.setState({ obj: updatedObj });
  };

  setStep = (step) => {
    this.setState({ step: step });
  };

  saveJob = async (jobDetails) => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    if (this.state.saving) return;

    this.setState({ saving: true });

    const jobObj = jobDetails;
    if (!jobObj.clientID) jobObj.clientID = null;

    jobObj.status = this.state.obj.jobStatusState.jobStatus


    const job =
      this.state.obj.job != null && this.state.obj.job.jobID != null
        ? await updateJob(inventoryId, this.state.obj.job.documentId, jobObj)
        : await addJob(inventoryId, jobObj);

    let updatedObj = { ...this.state.obj };

    updatedObj.job.documentId = job.data;

    this.setState({ obj: updatedObj, saving: false });
  };

  save = async () => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const companyId = currentUser.userProfile.companyID;

    if (!this.state.obj || !this.state.obj.job || this.state.saving) return;

    this.setState({ saving: true });

    const jobObj = this.state.obj.job;
    if (!jobObj.clientID) jobObj.clientID = null;

    const job = await updateJob(inventoryId, jobObj.documentId, jobObj);

    if (this.state.obj.equipment) {
      this.state.obj.equipment.forEach((equipmentId) => {
        if (!this.state.unavailableEquipment.includes(equipmentId)) {
          let reservation = {
            equipmentID: equipmentId,
            jobID: job.data,
            type: 1,
            startDate: this.state.obj.job.startDate,
            endDate: this.state.obj.job.endDate,
          };

          addReservation(inventoryId, reservation);
        }
      });
    }

    if (this.state.obj.materials) {
      await Promise.all(
        this.state.obj.materials.map(async (material) => {
          const imageField = material.imageField;
          delete material.imageField;
          material.jobID = job.data;
          material.associatedDate = new Date();

          if (imageField) {
            material.imageTimeStamp = new Date();
          }

          const doc = await addCustomChecklist(inventoryId, material);

          if (imageField) {
            const fromDb = await getMaterial(inventoryId, doc.data);
            const result = uploadImage(
              companyId,
              "customChecklist",
              doc.data,
              fromDb.data.imageTimeStamp,
              imageField.value
            );
          }
        })
      );
    }

    if (this.state.obj.crew) {
      this.state.obj.crew.forEach((crewID) => {
        let crewSchedule = {
          crewMemberID: crewID,
          jobID: job.data,
          startDate: this.state.obj.job.startDate,
          endDate: this.state.obj.job.endDate,
        };

        addCrewSchedule(inventoryId, crewSchedule);
      });
    }

    if (this.state.obj.selectedCrewIds) {
      this.state.obj.selectedCrewIds.forEach((selectedCrewId) => {
        let crewAppoitment = {
          crewID: selectedCrewId,
          jobID: job.data,
          startDate: this.state.obj.job.startDate,
          endDate: this.state.obj.job.endDate,
        };

        addCrewAppoitment(inventoryId, crewAppoitment);
      });
    }

    const presetFolders = await getPresetFolders(inventoryId);
    await Promise.all(
      presetFolders.data.map(async (presetFolder) => {
        await addFolder(inventoryId, job.data, presetFolder.name, null);
      })
    );

    this.setState({ saving: false });

    if (this.isTutorial) {
      const setTutorialStep = this.context.nextTutorialStep;
      setTutorialStep("jobReport");
      localStorage.setItem("tmp-job-id", job.data);
    }

    window.location.replace("/jobs/" + job.data + "/details");

    // this.props.history.replace('/jobs/' + job.data + '/details');
  };

  arrow = () => {
    return (
      <span className="svg-icon svg-icon-xl wizard-arrow">
        <ArrowRight />
      </span>
    );
  };

  render() {
    return (
      <>
        <div className="card card-custom">
          <div className="card-body p-0">
            <div
              className="wizard wizard-1"
              id="kt_wizard"
              data-wizard-state="step-first"
              data-wizard-clickable="false"
            >
              <div className="wizard-nav border-bottom">
                <div className="wizard-steps p-8 p-lg-10">
                  <div
                    className="wizard-step"
                    data-wizard-type="step"
                    data-wizard-state={
                      this.state.step === "job" ? "current" : null
                    }
                  >
                    <div className="wizard-label">
                      <div className="wizard-icon">
                        <JobsIos width={"3.75rem"} height={"4.2rem"} />
                      </div>
                      <h3 className="wizard-title">1. Job Details</h3>
                    </div>
                  </div>
                  <div
                    className="wizard-step"
                    data-wizard-type="step"
                    data-wizard-state={
                      this.state.step === "location" ? "current" : null
                    }
                  >
                    <div className="wizard-label">
                      <div className="wizard-icon">
                        <Marker width={"3.75rem"} height={"4.2rem"} />
                      </div>
                      <h3 className="wizard-title">2. Job Location</h3>
                    </div>
                  </div>
                  <div
                    className="wizard-step"
                    data-wizard-type="step"
                    data-wizard-state={
                      this.state.step === "client" ? "current" : null
                    }
                  >
                    <div className="wizard-label">
                      <div className="wizard-icon">
                        <Company width={"3.75rem"} height={"4.2rem"} />
                      </div>
                      <h3 className="wizard-title">3. Client Details</h3>
                    </div>
                  </div>
                  <div
                    className="wizard-step"
                    data-wizard-type="step"
                    data-wizard-state={
                      this.state.step === "crews" ? "current" : null
                    }
                  >
                    <div className="wizard-label">
                      <div className="wizard-icon">
                        <CrewsIos width={"3.75rem"} height={"4.2rem"} />
                      </div>
                      <h3 className="wizard-title">4. Crews</h3>
                    </div>
                  </div>
                  <div
                    className="wizard-step"
                    data-wizard-type="step"
                    data-wizard-state={
                      this.state.step === "equipment" ? "current" : null
                    }
                  >
                    <div className="wizard-label">
                      <div className="wizard-icon">
                        <EquipmentIos width={"3.75rem"} height={"4.2rem"} />
                      </div>
                      <h3 className="wizard-title">5. Equipment</h3>
                    </div>
                  </div>
                  <div
                    className="wizard-step"
                    data-wizard-type="step"
                    data-wizard-state={
                      this.state.step === "materials" ? "current" : null
                    }
                  >
                    <div className="wizard-label">
                      <div className="wizard-icon">
                        <Materials width={"3.75rem"} height={"4.2rem"} />
                      </div>
                      <h3 className="wizard-title">6. Materials</h3>
                    </div>
                  </div>
                  <div
                    className="wizard-step"
                    data-wizard-type="step"
                    data-wizard-state={
                      this.state.step === "crewmembers" ? "current" : null
                    }
                  >
                    <div className="wizard-label">
                      <div className="wizard-icon">
                        <CrewmemberIos width={"3.75rem"} height={"4.2rem"} />
                      </div>
                      <h3 className="wizard-title">7. Crewmembers</h3>
                    </div>
                  </div>
                  <div
                    className="wizard-step"
                    data-wizard-type="step"
                    data-wizard-state={
                      this.state.step === "folders" ? "current" : null
                    }
                  >
                    <div className="wizard-label">
                      <div className="wizard-icon">
                        <Folder width={"3.75rem"} height={"4.2rem"} />
                      </div>
                      <h3 className="wizard-title">8. Document Folders</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
                <div className="col-xl-12 col-xxl-7">
                  <div
                    className="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <Route
                      path={`${this.props.match.path}`}
                      exact={true}
                      render={(props) => (
                        <JobDetailsStep
                          obj={this.state.obj}
                          setStep={this.setStep}
                          setJobDetails={this.setJobDetails}
                          saving={this.state.saving}
                          onDateChange={this.onDateChange}
                          isTutorial={this.isTutorial}
                          tutorialStep={this.tutorialStep}
                          setJobStatus={this.setJobStatus}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      path={`${this.props.match.path}/location`}
                      exact={true}
                      render={(props) => (
                        <JobLocationStep
                          obj={this.state.obj}
                          setJobLocation={this.setJobLocation}
                          save={this.save}
                          setStep={this.setStep}
                          saving={this.state.saving}
                          isTutorial={this.isTutorial}
                          tutorialStep={this.tutorialStep}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      path={`${this.props.match.path}/client`}
                      exact={true}
                      render={(props) => (
                        <ClientDetailsStep
                          obj={this.state.obj}
                          setJobClient={this.setJobClient}
                          setJobClients={this.setJobClients}
                          save={this.save}
                          setStep={this.setStep}
                          saving={this.state.saving}
                          isTutorial={this.isTutorial}
                          tutorialStep={this.tutorialStep}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      path={`${this.props.match.path}/crews`}
                      exact={true}
                      render={(props) => (
                        <CrewStep
                          obj={this.state.obj}
                          setCrews={this.setCrews}
                          save={this.save}
                          setStep={this.setStep}
                          saving={this.state.saving}
                          equipment={this.state.equipment}
                          crewmembers={this.state.crewmembers}
                          updateCrewmembers={this.updateCrewmembers}
                          updateEquipment={this.updateEquipment}
                          unavailableCrews={this.state.unavailableCrews}
                          isTutorial={this.isTutorial}
                          tutorialStep={this.tutorialStep}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      path={`${this.props.match.path}/equipment`}
                      exact={true}
                      render={(props) => (
                        <EquipmentStep
                          obj={this.state.obj}
                          setJobEquipment={this.setJobEquipment}
                          save={this.save}
                          setStep={this.setStep}
                          saving={this.state.saving}
                          unavailableEquipment={this.state.unavailableEquipment}
                          isTutorial={this.isTutorial}
                          tutorialStep={this.tutorialStep}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      path={`${this.props.match.path}/materials`}
                      exact={true}
                      render={(props) => (
                        <MaterialsStep
                          obj={this.state.obj}
                          setJobMaterials={this.setJobMaterials}
                          save={this.save}
                          setStep={this.setStep}
                          saving={this.state.saving}
                          isTutorial={this.isTutorial}
                          tutorialStep={this.tutorialStep}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      path={`${this.props.match.path}/crewmembers`}
                      exact={true}
                      render={(props) => (
                        <CrewmembersStep
                          obj={this.state.obj}
                          setCrewMembers={this.setCrewMembers}
                          save={this.save}
                          setStep={this.setStep}
                          saving={this.state.saving}
                          unavailableCrewmembers={
                            this.state.unavailableCrewmembers
                          }
                          isTutorial={this.isTutorial}
                          tutorialStep={this.tutorialStep}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      path={`${this.props.match.path}/folders`}
                      exact={true}
                      render={(props) => (
                        <JobFoldersStep
                          obj={this.state.obj}
                          save={this.save}
                          setStep={this.setStep}
                          saving={this.state.saving}
                          unavailableCrewmembers={
                            this.state.unavailableCrewmembers
                          }
                          isTutorial={this.isTutorial}
                          tutorialStep={this.tutorialStep}
                          {...props}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default JobNew;