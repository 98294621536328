import React, { useEffect, useState } from "react";

interface EQSelectProps {
  title: string;
  items: EQSelectItem[];
  selectorTitle?: string;
  selectedValue?: string;
  onSelectionChanged: (value: EQSelectItem) => void;
}

export interface EQSelectItem {
  key?: string;
  value: string;
}

const EQSelect = (props: EQSelectProps) => {
  const [selectedValue, setSelectedValue] = useState<string>(
    props.selectedValue ?? ""
  );

  const [items, setItems] = useState<EQSelectItem[]>(props.items);

  useEffect(() => {
    setItems(props.items);
    setSelectedValue(props.selectedValue ?? "");
  }, [props]);

  return (
    <React.Fragment>
      <div key={`eq-selector-${props.title}`} className="form-group">
        <label>{props.title}</label>
        <select
          key={`eq-selector-select-${props.title}}`}
          className="form-control"
          value={selectedValue}
          onChange={(event) => {
            const selectedItem = {
              key: items.find((item) => item.value == event.target.value)?.key,
              value: event.target.value,
            };

            setSelectedValue(event.target.value);
            props.onSelectionChanged(selectedItem);
          }}
        >
          {props.selectorTitle && (
            <option value="" disabled selected>
              {props.selectorTitle ?? ""}
            </option>
          )}
          {items?.map((item, index) => {
            return (
              <option
                key={item.key ?? "" + index}
                value={item.value}
              >
                {item.value}
              </option>
            );
          })}
          ;
        </select>
      </div>
    </React.Fragment>
  );
};

export default EQSelect;
