import React, { useContext, useState } from "react";
import { functions } from "../../firebase";
import { getTimezoneOffset } from "../../shared/Utils";
import { showToast } from "../../shared/Util";
import { AuthContext } from "../../shared/Auth";

interface ExportLocateTicketsReportProps {
  jobId: string;
  recipients: string[];
}

const ExportLocateTicketsReport = (
  props: ExportLocateTicketsReportProps
) => {
  const authContext = useContext(AuthContext);
  const [sending, setSending] = useState(false);

  const sendReport = async () =>{
        if (!sending) {
          setSending(true);
          let sendJobReportFunc = functions.httpsCallable("exportLocateTickets");
    
          await sendJobReportFunc({
            jobID: props.jobId,
            userID: authContext.currentUser.uid,
            recipients: props.recipients.join(","),
            timeZone: getTimezoneOffset()
          })
            .then((result) => {
              showToast("success", "Report has been sent successfully.");
            })
            .catch((exception) => {
              showToast("danger", "Error sending report.");
            });
          setSending(false);
        }
  }


  return (
    <React.Fragment>
      <div className="form-group float-right">
        <button
          id="export-locate-ticket-btn"
          disabled={sending}
          data-toggle="tooltip"
          onClick={() => {
            sendReport();
          }}
          className={
            sending
              ? "btn btn-primary font-weight-bold mr-4 w-100 spinner spinner-white spinner-right"
              : "btn btn-primary font-weight-bold mr-4 w-100"
          }
        >
          Export locate tickets
        </button>
      </div>
    </React.Fragment>
  );
};

export default ExportLocateTicketsReport;
