import { doc, getDoc, Timestamp, } from "firebase/firestore"
import { db } from "../../firebase"
import { getLocateTicketCurrentStatus, LocateTicketEntity, LocateTicketFileEntity } from "../data/LocateTickets"
import { ResponseEntity } from "../data/Response"
import { FirebaseInventoriesCollections, FirebaseLocateTicketCollection, FirebaseRootCollections } from "../firebase/FirebaseSchema"
import { JobEntity } from "../data/job/Job"
import { FileEntity, toFileEntity } from "../data/folder/FolderStructure"

export const getLocateTicketsJobs = async (jobIds: string[], inventoryId?:string | null) => {

    const validJobIds = jobIds.filter((id)=>(id && id!=''))

    const uniqueJobIds = [...new Set(validJobIds)];

    try {
        const documentPromises = uniqueJobIds.map((id) => {
            const docRef = doc(db, `${FirebaseRootCollections.INVENTORIES}/${inventoryId}/${FirebaseInventoriesCollections.JOBS}/${id}`);
            return getDoc(docRef)
        })

        const documentSnapshot = await Promise.all(documentPromises)
        const documents: JobEntity[] = documentSnapshot.filter((documentSnapshot)=>(documentSnapshot?.data()!=null)).map((documentSnapshot) => {
            return { id: documentSnapshot.id, ...documentSnapshot.data() }
        })
        return new ResponseEntity<JobEntity[]>(documents, "")
    } catch (error: any) {
        return new ResponseEntity<JobEntity[]>([], error)
    }

}

export const getLocateTickets = async (inventoryId:string, jobId?: string) => {
    try {
        const ref = db.collection(FirebaseRootCollections.LOCATE_TICKETS)
        var query = ref.where("inventoryID", '==', inventoryId)

        query = (jobId) ? ref.where('jobID', '==', jobId) : query

        const firstResult = await query.get()

        const firstLocateTickets = firstResult.docs.map((doc: any) => {
            return { ...doc.data(), id: doc.id }
        })
    
        firstLocateTickets.forEach(async (locateTicket) => {
            try {
                await setLocateTicketStatusExpired(locateTicket)
            } catch (error: any) {
                console.error(error)
            }
        })

        const result = await query.get()

        const locateTickets = result.docs.map((doc: any) => {
            return { ...doc.data(), id: doc.id }
        })

        return new ResponseEntity<LocateTicketEntity[]>(locateTickets, "")
    } catch (error: any) {
        return new ResponseEntity<LocateTicketEntity[]>([], error)
    }
}

export const setLocateTicketStatusExpired = async (locateTicket:LocateTicketEntity) =>{
    try {
        const lastStatus = getLocateTicketCurrentStatus(locateTicket)
        var updatedLocateTicket = locateTicket
        if (lastStatus?.type == "approved" && locateTicket.expirationDate) {
            const today = new Date()
            today.setHours(0, 0, 0, 0)
            if (locateTicket.expirationDate?.toDate() < today) {
                const locateTicketToUpdate = { ...locateTicket }
                locateTicketToUpdate.expirationDate = null
                locateTicketToUpdate.status?.push({
                        createdAt: Timestamp.now(),
                        date: locateTicket.expirationDate, 
                        type: "expired",
                        userID: lastStatus.userID, 
                        userName: lastStatus.userName
            })
                updatedLocateTicket = (await saveLocateTicket(locateTicketToUpdate)).data ?? locateTicket
            }
        }
        return new ResponseEntity<LocateTicketEntity>(updatedLocateTicket, "")
    } catch (error:any) {
        console.log(error)
        return new ResponseEntity<LocateTicketEntity>(null, error)
    }
}

export const getLocateTicket = async (documentId?: string) => {
    try {

        const result = await db.collection(FirebaseRootCollections.LOCATE_TICKETS).doc(documentId).get();

        const locateTicket: LocateTicketEntity = result.data() as LocateTicketEntity
        locateTicket.id = result.id

        const updatedLocateTicket = await setLocateTicketStatusExpired(locateTicket)

        return new ResponseEntity<LocateTicketEntity | null>(updatedLocateTicket.data ?? locateTicket, "")
    } catch (error: any) {
        return new ResponseEntity<LocateTicketEntity | null>(null, error)
    }
}

export const getLocateTicketFiles = async (documentId: string) => {
    try {
        const result = await db
            .collection(FirebaseRootCollections.LOCATE_TICKETS)
            .doc(documentId)
            .collection(FirebaseLocateTicketCollection.FILES)
            .get()

        const files = result.docs.map((doc: any) => {
            return { ...doc.data(), id: doc.id }
        })

        return new ResponseEntity<FileEntity[]>(files.map((file:any)=> toFileEntity(file, file.id)) ?? [], "")
    } catch (error: any) {
        return new ResponseEntity<FileEntity[] | null>(null, "")

    }
}

export const saveLocateTicketFiles = async (documentId: string, files: LocateTicketFileEntity[]) => {
    try {
        await db
            .collection(FirebaseRootCollections.LOCATE_TICKETS)
            .doc(documentId)
            .collection(FirebaseLocateTicketCollection.FILES)
            .get().then((querySnapshot) => {
                Promise.all(querySnapshot.docs.map(doc => doc.ref.delete()))
            }).then(() => {
                Promise.all(files.map((file: any) => {
                    db.collection(FirebaseRootCollections.LOCATE_TICKETS)
                        .doc(documentId)
                        .collection(FirebaseLocateTicketCollection.FILES)
                        .add(file)
                }))
            })

        return new ResponseEntity<LocateTicketFileEntity[]>(files, "")
    } catch (error: any) {
        return new ResponseEntity<LocateTicketFileEntity[] | null>(null, "")

    }
}

export const saveLocateTicket = async (locateTicket: LocateTicketEntity) => {
    try {
        const request = db.collection(FirebaseRootCollections.LOCATE_TICKETS)
        const { id, ...locateTicketWithoutId } = locateTicket

        if (!locateTicket.id) {
            const response = await request.add(locateTicketWithoutId)
            return new ResponseEntity<LocateTicketEntity>({ ...locateTicket, id: response.id }, "")
        } else {
            await request.doc(locateTicket.id).update(locateTicketWithoutId)
            return new ResponseEntity<LocateTicketEntity>(locateTicket, "")
        }

    } catch (error: any) {
        console.log(error)
        return new ResponseEntity<LocateTicketEntity>(locateTicket, error)
    }

}

export const deleteLocateTicket = async (documentId: string) => {
    try {
        await db.collection(FirebaseRootCollections.LOCATE_TICKETS).doc(documentId).delete()
        return new ResponseEntity<boolean>(true, "")
    } catch (error: any) {
        return new ResponseEntity<boolean>(false, error)
    }
}

export const deleteAllJobLocateTickets = async (inventoryId: string, jobId: string) => {
    try {
        if(jobId){
        const locateTickets = await getLocateTickets(inventoryId, jobId)
        const deletionPromises = (locateTickets?.data || []).map(async (locateTicket) => {
            return db.collection(FirebaseRootCollections.LOCATE_TICKETS)
            .doc(locateTicket.id ?? "")
            .delete()
        });
        await Promise.all(deletionPromises)
    }
    } catch (error) {
        console.error(error)
    }
}