import React, { Component } from 'react';
import RichDataTable from '../../components/Tables/RichDataTable';
import { db } from '../../firebase';
import { AuthContext } from "../../shared/Auth";
import Loader from '../../components/Layout/Loader/Loader';
import { EquipmentIos, ImportIcon, PlusIcon } from '../../components/Icons/Svg';
import moment from 'moment';
import CustomFilter from './EquipmentFilters/CustomFilter';
import { CSVLink } from "react-csv";
import CSVReader from 'react-csv-reader';
import { addEquipment, getCategories, getEquipment, loadEquipmentImages, updateUser } from '../../shared/Api';
import { showToast, sortAlphabetically } from '../../shared/Util';

class Equipment extends Component {
  static contextType = AuthContext;
  user = this.context.currentUser.userProfile;
  isTutorial = this.context.isTutorial;
  tutorialStep = this.context.tutorialStep;

  state = {
    fields: [
      {
        display: 'Equipment Id',
        name: 'equipmentID'
      },
      {
        display: 'Make',
        name: 'make'
      },
      {
        display: 'Model',
        name: 'model'
      },
      {
        display: 'Serial Number',
        name: 'serialNumber'
      },
      {
        display: 'Category',
        name: 'category'
      },
    ],
    filtered: {
      beingServicedToday: false,
      byJobID: '',
      onJobToday: false,
      availableOn: '',
      availableToday: false,
      byCategory: '',
      bySubCategory: '',
    },
    reservations: [],
    data: null,
    displayData: null,
    jobIDs: [],
    selectedJobID: "",
    selectedCategory: "",
    selectedSubCategory: "",
    categories:[],
    equipmentSubs: null,
    toolingSubs: null,
    deleteEquipmentModalActive: false,
    selectedEquipmentId: null,
    importing: false,
    imagesLoaded: false,
  };

  componentDidMount() {
    const currentUser = this.context.currentUser;

    this.loadEquipment();

    const updateJobIdBack = this.context.updateJobIdBack;
    updateJobIdBack(null);
  }

  componentWillUnmount = () => {
    window.$('[data-toggle="tooltip"]').tooltip('hide');
    document.getElementById("skip-tutorial-button").style.display = "none";
  }

  loadEquipment = async () => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const companyId = currentUser.userProfile.companyID;

    const getEquipmentResult = await getEquipment(inventoryId, companyId, false, true);
    const categoryResponse = await getCategories();
    let equipmentSubs = categoryResponse.data[0].subCategories.map(x => { return {label: x.name, value: x.name}});
    let toolingSubs = categoryResponse.data[1].subCategories.map(x => { return {label: x.name, value: x.name}});


    var transformedData = getEquipmentResult.data;

    transformedData = transformedData.map((data)=>{
      const category = data.category??"Equipment"
      const subcategory = data.subCategory?` - ${data.subCategory}`:""
      data.category = `${category}${subcategory}`
      return data
    })

    transformedData.sort((a, b) => {
      return (a.equipmentID > b.equipmentID) ? 1 : ((b.equipmentID > a.equipmentID) ? -1 : 0);
  });

    this.setState({ data: transformedData, displayData: transformedData, equipmentSubs: equipmentSubs, toolingSubs: toolingSubs, categories:categoryResponse.data });
    this.loadFilterData();

    if(this.isTutorial && this.tutorialStep === 'addEquipment') {
      this.showTutorialMessage();
    }

    this.loadEquipmentImages();
  }

  showTutorialMessage = () => {
    document.getElementById("black-layer-container").style.display = "block";
    document.getElementById("add-equipment-btn").style.zIndex = "1001";
    
    window.$('[data-toggle="tooltip"]').tooltip({ trigger: 'manual' });
    window.$('[data-toggle="tooltip"]').tooltip('hide');
    window.$('[data-toggle="tooltip"]').tooltip('show');

    document.getElementById("skip-tutorial-button").style.display = "block";
  }

  loadEquipmentImages = async () => {
    const currentUser = this.context.currentUser;
    const companyId = currentUser.userProfile.companyID;

    if(!this.state.data)
      return;

    const withImages = await loadEquipmentImages(companyId, this.state.data);
    const data = [...this.state.data];
    const displayData = [...this.state.displayData];

    for(let i = 0; i < data.length; i++) {
      let item = data[i];
      const tmp = withImages.find(x => x.id === item.id);
      data[i] = {...data[i], image: tmp.image};
    }

    for(let i = 0; i < displayData.length; i++) {
      let item = displayData[i];
      const tmp = withImages.find(x => x.id === item.id);
      displayData[i] = {...displayData[i], image: tmp.image};
    }

    this.setState({ data: data, displayData: displayData, imagesLoaded: true });
  }

  loadFilterData = async () => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    const reservations = await db.collection("inventories")
      .doc(inventoryId)
      .collection("reservations")
      .get();

    const dataReservations = await Promise.all(reservations.docs.map(async (doc) => {
      let dat = doc.data();
      dat.id = doc.id;
      return dat;
    }));

    const job = await db.collection("inventories")
      .doc(inventoryId)
      .collection("jobs")
      .get();

    let jobData = job.docs.map(doc => { return { ...doc.data(), id: doc.id } });


    const getJobIDs = jobData.map(x => (
      {
        value: x.id,
        label: x.jobID,
      }
      
    ));
    sortAlphabetically(getJobIDs, "id")

    this.setState({ reservations: dataReservations, jobIDs: getJobIDs });
  }

  resetFilters = () => {
    const filtered = {
      beingServicedToday: false,
      byJobID: '',
      onJobToday: false,
      availableOn: '',
      availableToday: false,
      byCategory: '',
      bySubCategory: ''
    }

    this.setState({ filtered: filtered });
    const filteredData = this.filter(filtered);

    this.setState({ displayData: filteredData, selectedCategory: "", selectedSubCategory: "", selectedJobID: "" });
  }

  deleteEquipment = () => {
    const id = this.state.selectedEquipmentId;
    if (!id)
      return;

    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    db.collection("inventories").doc(inventoryId)
      .collection('equipment').doc(id).delete()
      .then(() => {
        const data = [...this.state.data];
        const newData = data.filter(function (obj) {
          return obj.id !== id;
        });

        this.setState({ data: newData });
      }).catch((error) => {

      });

    this.setState({ deleteEquipmentModalActive: false });
  }

  handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const filterName = name;
    const filters = { ...this.state.filtered };

    if (type === "checkbox") {
      filters[filterName] = checked;
    } else {
      filters[filterName] = value;
    };

    this.setState({ filtered: filters });
    const filteredData = this.filter(filters);
    this.setState({ displayData: filteredData });
  }

  handleSelect = (event) => {
    if (!event) {
      const filters = { ...this.state.filtered };
      filters["byJobID"] = "";
      const filteredData = this.filter(filters);
      this.setState({ selectedJobID: null, filtered: filters, displayData: filteredData })
      return
    }

    const jobValue = event;
    this.setState({ selectedJobID: jobValue })

    const filters = { ...this.state.filtered };
    filters["byJobID"] = event.value;

    this.setState({ filtered: filters });
    const filteredData = this.filter(filters);
    this.setState({ displayData: filteredData });
  }

  handleCategorySelect = (event) => {
    
    if (!event) {
      const filters = { ...this.state.filtered };
      filters["byCategory"] = "";
      filters["bySubCategory"] = "";

      const filteredData = this.filter(filters);
      this.setState({ selectedCategory: null, filtered: filters, displayData: filteredData })
      return
    }

    const jobValue = event;
    
    this.setState({ selectedCategory: jobValue, selectedSubCategory: "" })

    const filters = { ...this.state.filtered };
    filters["byCategory"] = event.value;
    filters["bySubCategory"] = "";

    this.setState({ filtered: filters });
    const filteredData = this.filter(filters);
    this.setState({ displayData: filteredData });
  }

  handleSubCategorySelect = (event) => {
    if (!event) {
      const filters = { ...this.state.filtered };
      filters["bySubCategory"] = "";
      const filteredData = this.filter(filters);
      this.setState({ selectedSubCategory: null, filtered: filters, displayData: filteredData })
      return
    }
    const jobValue = event;
    this.setState({ selectedSubCategory: jobValue })

    const filters = { ...this.state.filtered };
    filters["bySubCategory"] = event.value;

    this.setState({ filtered: filters });
    const filteredData = this.filter(filters);
    this.setState({ displayData: filteredData });
  }

  handleSubCategoryOptions = () => {
    if(!this.state.selectedCategory){
      return null
    }
    if(this.state.selectedCategory.label === "Equipment")
    return this.state.equipmentSubs
    if(this.state.selectedCategory.label === "Tooling")
    return this.state.toolingSubs
  }

  filter = (filters) => {
    let tmpData = this.state.data;

    if (filters.availableToday) {
      let today = new Date().toDateString();
      tmpData = this.checkDateAvailability(today, tmpData);
    }

    if (filters.availableOn) {
      tmpData = this.checkDateAvailability(filters.availableOn, tmpData);
    }

    if (filters.beingServicedToday) {
      let today = new Date().toDateString();
      tmpData = this.checkServicedToday(today, tmpData);
    }

    if (filters.onJobToday) {
      let today = new Date().toDateString();
      tmpData = this.checkJobToday(today, tmpData);
    }

    if (filters.byJobID) {
      tmpData = this.checkJobID(filters.byJobID, tmpData);
    }

    if (filters.byCategory) {
      tmpData = this.checkCategory(filters.byCategory, tmpData);
    }

    if (filters.bySubCategory) {
      tmpData = this.checkSubCategory(filters.bySubCategory, tmpData);
    }

    return tmpData;
  }

  checkDateAvailability = (date, data) => {
    let equipmentData = data;
    let equipmentReservations = this.state.reservations;
    let reserved = [];

    equipmentReservations.forEach(reservation => {
      if (moment(date).isBetween(moment(reservation.startDate.toDate()).format("MM/DD/YY"), moment(reservation.endDate.toDate()).format("MM/DD/YY"), undefined, '[]')) {
        reserved.push(reservation)
      } else {
        return;
      }
    });

    let unavailableIDs = reserved.map(x => x.equipmentID);
    let available = equipmentData.filter(item => !unavailableIDs.includes(item.id));

    return available;
  }

  checkServicedToday = (today, data) => {
    let equipmentData = data;
    let equipmentReservations = this.state.reservations;
    let reservedToday = [];

    equipmentReservations.forEach(reservation => {
      if ((moment(today).isBetween(moment(reservation.startDate.toDate()).format("MM/DD/YY"), moment(reservation.endDate.toDate()).format("MM/DD/YY"), undefined, '[]')) && reservation.type === 0) {
        reservedToday.push(reservation)
      } else {
        return;
      }
    })

    let reservedTodayIDs = reservedToday.map(x => x.equipmentID);
    let servicedEquipment = equipmentData.filter(item => reservedTodayIDs.includes(item.id));

    return servicedEquipment;
  }

  checkJobToday = (today, data) => {
    let equipmentData = data;
    let equipmentReservations = this.state.reservations;
    let reservedToday = [];

    equipmentReservations.forEach(reservation => {
      if ((moment(today).isBetween(moment(reservation.startDate.toDate()).format("MM/DD/YY"), moment(reservation.endDate.toDate()).format("MM/DD/YY"), undefined, '[]')) && reservation.type === 1) {
        reservedToday.push(reservation)
      } else {
        return;
      }
    })

    let reservedTodayIDs = reservedToday.map(x => x.equipmentID);
    let equipmentOnJob = equipmentData.filter(item => reservedTodayIDs.includes(item.id));

    return equipmentOnJob;
  }

  checkJobID = (id, data) => {
    let equipmentData = data;
    let equipmentReservations = this.state.reservations;
    let wantedReservations = [];

    equipmentReservations.forEach(reservation => {
      if (reservation.jobID === id) {
        wantedReservations.push(reservation)

      } else {
        return;
      }
    });

    let wantedReservationsIDs = wantedReservations.map(x => x.equipmentID)
    let equipmentWanted = equipmentData.filter(item => wantedReservationsIDs.includes(item.id))

    return equipmentWanted;
  }

  checkCategory = (category, data) => {
    return data.filter(x => x.category && x.category.includes(category));
  }

  checkSubCategory = (subcategory, data) => {
    return data.filter(x => x.subCategory && x.subCategory.includes(subcategory));
  }

  equipmentFilters = () => {
    return [
      {
        name: 'availableToday',
        type: 'checkbox',
        display: 'Available Today',
        value: this.state.filtered.availableToday,
        onChange: this.handleChange
      },
      {
        name: 'availableOn',
        type: 'datepicker',
        display: 'Available On',
        value: this.state.filtered.availableOn,
        onChange: this.handleChange
      },
      {
        name: 'beingServicedToday',
        type: 'checkbox',
        display: 'Being Serviced Today',
        value: this.state.filtered.beingServicedToday,
        onChange: this.handleChange
      },
      {
        name: 'byJobID',
        type: 'text',
        display: 'By Job ID',
        value: this.state.filtered.byJobID,
        onChange: this.handleSelect
      },
      {
        name: 'onJobToday',
        type: 'checkbox',
        display: 'On Job Today',
        value: this.state.filtered.onJobToday,
        onChange: this.handleChange
      },
      {
        name: 'byCategory',
        type: 'text',
        display: 'By Category',
        value: this.state.filtered.byCategory,
        onChange: this.handleCategorySelect
      },
      {
        type: 'placeholder'
      },
      {
        name: 'bySubCategory',
        type: 'text',
        display: 'By SubCategory',
        value: this.state.filtered.bySubCategory,
        onChange: this.handleSubCategorySelect
      }
    ];
  }

  checkDuplicateEquipmentID = (equipmentObj, equipmentList) => {
    const id = this.props.match.params.id;

    return equipmentList.some(x => x.equipmentID === equipmentObj.equipmentID);
  }

  importEquipment = async (data, fileInfo) => {
    this.setState({ importing: true });
    const equipments = [];
    const equipmentList = [...this.state.data];

    for (let index = 0; index < data.length; index++) {
      let element = data[index];

      if (!element.equipment_id) {
        const error = "Equipment id is missing for item at row " + (index + 1) + ".";
        showToast("danger", error);
        this.setState({ importing: false });
        return;
      }

      if (!element.make) {
        const error = "Make is missing for item at row " + (index + 1) + ".";
        showToast("danger", error);
        this.setState({ importing: false });
        return;
      }

      if (!element.model) {
        const error = "Model is missing for item at row " + (index + 1) + ".";
        showToast("danger", error);
        this.setState({ importing: false });
        return;
      }

      const category = this.state.categories.find((category)=>category.name.toLowerCase()==element.category.toLowerCase())??null
      const subCategory = category?.subCategories?.find((subCategory)=>subCategory.name.toLowerCase()==element.subcategory.toLowerCase())??null

      const equipment = {
        equipmentID: element.equipment_id,
        make: element.make,
        model: element.model,
        serialNumber: element.serial_number,
        tag: element.tag,
        year: element.year,
        category:category?.name??null,
        subCategory:subCategory?.name??null,
        subCategoryTypes: subCategory?.types??null,
        description: element.description ? element.description : "",
        hasProfileImage: null
      }

      if(this.checkDuplicateEquipmentID(equipment, equipmentList)) {
        showToast('danger', 'Equipment ID: ' + equipment.equipmentID + ' has already been taken.');
        this.setState({ importing: false });
        return;
      }

      equipmentList.push(equipment);
      equipments.push(equipment);
    }

    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const companyId = currentUser.userProfile.companyID;

    await Promise.all(equipments.map(async (element) => {
      const result = await addEquipment(inventoryId, companyId, element);
    }));

    this.loadEquipment();
    this.setState({ importing: false });

    if (equipments.length !== 0) {
      showToast("success", "Equipment imported");
    } else {
      showToast("error", "Importing equipment failed");
    }
  }

  handleImportClick = () => {
    document.getElementById("import-button").click();
  }

  render() {
    if(!this.state.data) {
      return <Loader />
    }
    if(this.state.data.length === 0) {
      return (
        <>
        <div className="d-flex align-items-center mb-10">
          <div className="d-flex flex-column flex-grow-1 font-weight-bold align-items-center">
              <div className="mainpage-graphic">
                <EquipmentIos width={'200px'} height={'200px'} opacity={'0.3'}/>
                </div>
              <span className="font-italic">You don't have any equipment at the moment. You can start adding by clicking on one of the buttons below.</span>&nbsp;
              {this.user.permissions === 'crewmember' ? null 
              : 
              <div className="card-toolbar">
                <div className="dropdown dropdown-inline mr-2">
                  <button type="button" className={this.state.importing ? "btn btn-light-primary font-weight-bolder dropdown-toggle spinner spinner-white spinner-right"
                  : "btn btn-light-primary font-weight-bolder dropdown-toggle"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span className="svg-icon svg-icon-md">
                      <ImportIcon />
                    </span>Import</button>
                  <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                    <ul className="navi flex-column navi-hover py-2">
                      <li className="navi-header font-weight-bolder text-uppercase font-size-sm text-primary pb-2">Choose an option:</li>
                        <li className="navi-item">
                          <a role="button" onClick={this.handleImportClick} className="navi-link">
                            <span className="navi-icon">
                              <i className="la la-file-import" />
                            </span>
                            <span className="navi-text">Import</span>
                          </a>
                        </li>
                        <li className="navi-item">
                          <CSVLink
                            filename="import-template-csv.csv"
                            data={[]}
                            headers={[
                              { label: "Equipment Id", key: "equipmentID" },
                              { label: "Make", key: "make" },
                              { label: "Model", key: "model" },                    
                              { label: "Year", key: "year" },
                              { label: "Serial Number", key: "serialNumber" },
                              { label: "Tag", key: "tag" },
                              { label: "Description", key: "description" },
                              { label: "Category", key: "category" },
                              { label: "Subcategory", key: "subCategory" }
                            ]}
                            className="navi-link">
                            <span className="navi-icon">
                              <i className="la la-file-excel-o" />
                            </span>
                            <span className="navi-text">Template</span>
                          </CSVLink>
                        </li>
                    </ul>
                  </div>
                </div>
                &nbsp;
                <a id="add-equipment-btn" onClick={() => { this.props.history.push('/equipment/new')}} className="btn btn-primary font-weight-bolder">
                  <span className="svg-icon svg-icon-md">
                    <PlusIcon />
                  </span>Add equipment
                </a>
              </div>}
              <CSVReader
                  inputStyle={{ display: 'none' }}
                  onFileLoaded={(data, fileInfo) => { this.importEquipment(data, fileInfo); }}
                  onError={(err) => { console.log(err); }}
                  inputId="import-button"
                  /* ref={importButtonRef} */
                  parserOptions={{
                    header: true,
                    dynamicTyping: true,
                    skipEmptyLines: true,
                    transformHeader: header =>
                      header
                        .toLowerCase()
                        .replace(/\W/g, '_')
                  }}
                />
          </div>
        </div>
        </>
      );
    }
    return (
      <React.Fragment>
        <div className="d-flex flex-column-fluid">
          <div className="container">
            <RichDataTable
              title="Equipment list"
              newRecord={this.user.permissions === 'crewmember' ? null : {
                title: 'Add equipment',
                id: "add-equipment-btn",
                onClick: () => { 
                  if(this.isTutorial) {
                    window.$('[data-toggle="tooltip"]').tooltip('hide');
                    const nextTutorialStep = this.context.nextTutorialStep;
                    nextTutorialStep();
                    document.getElementById("black-layer-container").style.display = "none";
                  }
                  this.props.history.push('/equipment/new') 
                },
                dataToggle: "tooltip",
                toggleTitle: "Let's add some iron!"
              }}
              fields={this.state.fields}
              data={this.state.displayData}
              waitForImages={this.state.imagesLoaded}
              export={{
                csv: {
                  filename: 'equipment-list.csv',
                  headers: [
                    { label: "Equipment Id", key: "equipmentID" },
                    { label: "Make", key: "make" },
                    { label: "Model", key: "model" },                    
                    { label: "Year", key: "year" },
                    { label: "Serial Number", key: "serialNumber" },
                    { label: "Tag", key: "tag" },
                    { label: "Description", key: "description" },
                    { label: "Category", key: "category" },
                    { label: "Subcategory", key: "subCategory" }
                  ],
                  data: this.state.data
                },
                pdf: {
                  filename: 'equipment-list.pdf',
                  title: 'Eqipment list',
                  headers: [
                    { label: "Equipment Id", key: "equipmentID" },
                    { label: "Make", key: "make" },
                    { label: "Model", key: "model" },
                    { label: "Year", key: "year" },
                    { label: "Serial Number", key: "serialNumber" },
                    { label: "Tag", key: "tag" },
                    { label: "Description", key: "description" },
                    { label: "Category", key: "category" },
                    { label: "Subcategory", key: "subCategory" }
                  ],
                  data: this.state.data
                },
                print: true
              }}
              import={{
                onImport: this.importEquipment,
                importing: this.state.importing
              }}
              actions={{
                delete: {
                  enabled: false,
                  onClick: (id) => {
                    this.setState({ selectedEquipmentId: id, deleteEquipmentModalActive: true })
                  }
                },
                edit: {
                  enabled: this.user.permissions !== 'crewmember',
                  onClick: (id) => {
                    this.props.history.push('/equipment/' + id + '/edit');
                  }
                },
                detail: {
                  enabled: true,
                  onClick: (id) => {
                    this.props.history.push('/equipment/' + id);
                  }
                },
                duplicate: {
                  enabled: true,
                  onClick: (id) => {
                    this.props.history.push('/equipment/new/' + id);
                  }
                }
              }}
              customFiltered={true}
              customFilter={<CustomFilter
                equipmentFilters={this.equipmentFilters()}
                selectedCategory={this.state.selectedCategory}
                selectedSubCategory={this.state.selectedSubCategory}
                ids={this.state.jobIDs}
                categories={[{value: "Equipment", label: "Equipment"}, {value: "Tooling", label: "Tooling"}]}
                eqSubs={this.state.equipmentSubs}
                toSubs={this.state.toolingSubs}
                selectedJobID={this.state.selectedJobID}
                handleSubCategoryOptions={this.handleSubCategoryOptions}
                />}
              onHideFilters={() => this.resetFilters()}
              showExportButton={true}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Equipment;