import React, { useState } from "react";
import { ContactEntity } from "../../../../shared/data/ContactEntity";
import { JobClient } from "../../../../shared/data/job/Job";

interface SelectContactsProps {
  contacts: ContactEntity[];
  preSelectedContacts: string[];
  onContactsSelected: (contacts?: ContactEntity[] | null) => void;
}

const SelectContactList = ({
  contacts = [],
  preSelectedContacts = [],
  onContactsSelected = () => {},
}: SelectContactsProps) => {
  const [selectedContacts, setSelectedContacts] = useState<ContactEntity[]>(
    contacts.filter((contact) => preSelectedContacts.includes(contact.id ?? ""))
  );

  return (
    <React.Fragment>
      <div key={`job-client-contact-list`}>
        <div className="navbar-nav-scroll h-200px">
          {contacts?.map((contact) => {
            return (
              <div key={contact.id ?? ""}>
                <div className="d-flex flex-row align-items-center">
                  <div
                    className="d-flex flex-column flex-row-fluid pointer"
                    onClick={() => {
                      // history.push("/clients/" + client.id + "/edit");
                    }}
                  >
                    <h6>{`${contact?.firstName} ${contact?.lastName}`}</h6>
                    <span>
                      {contact?.email?.trim()
                        ? contact.email
                        : contact?.phoneNumber?.trim()
                        ? contact.phoneNumber
                        : contact?.phoneNumber2?.trim()
                        ? contact.phoneNumber2
                        : "No phone number"}
                    </span>
                  </div>
                  <input
                    type="checkbox"
                    className="mr-5"
                    onChange={(event) => {
                      const checked = event.currentTarget.checked;
                      const contactList = checked
                        ? [...selectedContacts, contact]
                        : selectedContacts.filter((c) => c.id !== contact.id);
                      setSelectedContacts(contactList);
                    }}
                    checked={selectedContacts?.some((c) => c.id === contact.id)}
                  />
                </div>
                <div className="separator separator-solid my-1"></div>
              </div>
            );
          }) ?? <div></div>}
        </div>
        <div className="mt-8">
          <button
            className="btn btn-primary text-uppercase float-right"
            onClick={() => {
              onContactsSelected(selectedContacts);
            }}
          >
            Save
          </button>
          <button
            className="btn btn-danger text-uppercase float-right mr-5"
            onClick={() => {
              onContactsSelected(null);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SelectContactList;
