import React, { Component } from 'react';
import { AuthContext } from "../../shared/Auth";
import Loader from '../../components/Layout/Loader/Loader';
import { addCrewAppoitment, addCrewSchedule, addReservation, deleteCrewAppoitment, deleteCrewSchedule, deleteReservation, getCrew, getCrewAppointments, getCrewAppointmentsByJob, getCrewmembers, getCrews, getCrewSchedules, getCrewSchedulesForJob, getEquipment, getJob, getJobs, getReservations, getReservationsForAJob, updateCrewmember, updateEquipment } from '../../shared/Api';
import { fromRGBValue, isOverlap } from '../../shared/Util';
import AddRemoveModal from '../../components/Modals/AddRemoveModal';
import ConfirmModal from '../../components/Modals/ConfirmModal';
import { Edit } from '../../components/Icons/Svg';
import * as moment from 'moment';
import $ from 'jquery';
import { Link } from 'react-router-dom'

class CrewsDetails extends Component {
  static contextType = AuthContext;
  user = this.context.currentUser.userProfile;

  state = {
    allCrews: [],
    crew: null,
    crewmembers: null,
    crewmembersModalOpen: false,
    overrideCrewmembersCrewModal: false,
    overrideCrewmembersCrew: null,
    equipment: null,
    equipmentModalOpen: false,
    overrideEquipmentCrewModal: false,
    overrideEquipmentCrew: null,
    jobId: null,
    updatingJob: false,
    originalCrewmembers: [],
    savingCrewmembers: false,
    originalEquipment: [],
    savingEquipment: false,
    schedules: [],
    calendar: null,
    view: 'month',
  }

  componentDidMount = async () => {
    this.context.updateBackButtonPoint(this.props.location.pathname);
    await this.loadCrew();
    this.loadAppoitment();
    this.loadCrewmembers();
    this.loadEquipment();
    this.loadSchedules();
    this.loadAllCrews()
  }

  loadAppoitment = async () => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const crewId = this.props.match.params.id;
    const jobId = this.props.match.params.jobId;

    if (!jobId)
      return;

    const job = await getJob(inventoryId, jobId);
    const jobStartDate = job.data.startDate.toDate();
    const jobEndDate = job.data.endDate.toDate();

    const crewAppoitments = await getCrewAppointments(inventoryId);

    const conflicts = crewAppoitments.data.filter(crewAp => crewAp.crewID === crewId &&
      isOverlap(jobStartDate, jobEndDate, crewAp.startDate.toDate(), crewAp.endDate.toDate())).filter((conflict)=>conflict.jobID!=jobId);

    const appoitments = await getCrewAppointmentsByJob(inventoryId, jobId);
    const appoitment = appoitments.data.find(x => x.crewID === crewId);
    this.setState({ jobId: jobId, appoitment: appoitment, job: job.data, canAdd: conflicts.length === 0 });
  }

  loadSchedules = async () => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const id = this.props.match.params.id;
    const jobId = this.props.match.params.jobId;

    if(!jobId) {
      const crewAppoitments = await getCrewAppointments(inventoryId);
      const jobs = await getJobs(inventoryId);
      let schedules = crewAppoitments.data;

      schedules = schedules.filter(x => x.crewID === id);
      schedules.forEach(schedule => {
        schedule.job = jobs.data.find(x => x.id === schedule.jobID);
        if (schedule.job) {
          schedule.title = schedule.job.jobID;
          schedule.description = schedule.job.description;
        }
      });

      this.setState({ schedules: schedules.sort((a, b) => (a.startDate > b.startDate) ? -1 : 1) });
      this.drawCalendar(schedules);
    }
  }

  loadAllCrews = async () =>{
    const allCrews = await getCrews(this.context.currentUser.company.inventoryID)
    this.setState({allCrews:allCrews.data})
  }

  drawCalendar = (schedules) => {
    if (schedules.length <= 0)
      return;

    const self = this;

    var todayDate = moment().startOf('day');
    var YM = todayDate.format('YYYY-MM');
    var YESTERDAY = todayDate.clone().subtract(1, 'day').format('YYYY-MM-DD');
    var TODAY = todayDate.format('YYYY-MM-DD');
    var TOMORROW = todayDate.clone().add(1, 'day').format('YYYY-MM-DD');

    var calendarEl = document.getElementById('kt_calendar');
    var calendar = new window.FullCalendar.Calendar(calendarEl, {
      plugins: ['bootstrap', 'interaction', 'dayGrid', 'basicWeek', 'list'],
      themeSystem: 'bootstrap',

      //isRTL: KTUtil.isRTL(),

      header: {
        left: 'prev,next today',
        center: 'title'
      },

      height: 800,
      contentHeight: 780,
      aspectRatio: 3,  // see: https://fullcalendar.io/docs/aspectRatio

      nowIndicator: true,
      now: TODAY,

      views: {
        dayGridMonth: { buttonText: 'month' },
        dayGridWeek: { buttonText: 'week' }
      },

      defaultView: 'dayGridMonth',
      defaultDate: TODAY,

      editable: true,
      eventLimit: true, // allow "more" link when too many events
      navLinks: true,
      eventColor: '#378006',
      events: schedules.map(x => {
        return {
          id: x.crewID,
          jobId: x.jobID,
          title: x.title,
          description: x.description,
          start: x.startDate.toDate(),
          end: x.endDate.toDate(),
          className: "fc-event-" + this.getClass(x.type)
        };
      }),

      eventRender: function (info) {
        var element = $(info.el);

        if (info.event.extendedProps && info.event.extendedProps.description) {
          if (element.hasClass('fc-day-grid-event')) {
            element.data('content', info.event.extendedProps.description);
            element.data('placement', 'top');
            //window.KTApp.initPopover(element);
          } else if (element.hasClass('fc-time-grid-event')) {
            element.find('.fc-title').append('<div class="fc-description">' + info.event.extendedProps.description + '</div>');
          } else if (element.find('.fc-list-item-title').length !== 0) {
            element.find('.fc-list-item-title').append('<div class="fc-description">' + info.event.extendedProps.description + '</div>');
          }
        }

        if (info.event.extendedProps.type === 1) {
          element.css('background-color', '#C9F7F5');
        } else if (info.event.extendedProps.type === 0) {
          element.css('background-color', '#FFA800');
        }
      },
      eventClick: function (info) {
        if (info.event.extendedProps.type === 0) {
          self.props.history.push("/equipment/" + info.event.extendedProps.equipmentId + '/schedule/' + info.event.id);
        } else {
          self.props.history.push("/jobs/" + info.event.extendedProps.jobId + '/details');
        }
      }
    });

    this.setState({ calendar: calendar });

    try {
      calendar.render();
    } catch (err) { }
  }

  getClass = (type) => {
    switch (type) {
      case 0:
        return "warning";
      case 1:
        return "primary";
      default:
        return "primary";
    }
  }

  goTo = (view) => {
    switch (view) {
      case 'list':
        this.setState({ view: 'list' });
        break;
      case 'month':
        this.setState({ view: 'month' });
        this.state.calendar.changeView("dayGridMonth");
        break;
      case 'week':
        this.setState({ view: 'week' });
        this.state.calendar.changeView("dayGridWeek");
        break;
    }
  }

  saveCrewmembers = async () => {
    const crewId = this.props.match.params.id;
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    if (!this.state.crewmembers)
      return;

    this.setState({ savingCrewmembers: true });
    const originalCrewmemberIds = this.state.originalCrewmembers.filter(x => x.crewID === crewId).map(x => x.id);

    await Promise.all(this.state.crewmembers.map(async (element) => {
      if (originalCrewmemberIds.includes(element.id)) {
        if (element.crewID !== crewId) {
          await updateCrewmember(inventoryId, element.id, { crewID: element.crewID });
        }
      } else {
        if (element.crewID === crewId) {
          await updateCrewmember(inventoryId, element.id, { crewID: crewId });
        }
      }
    }));

    await this.loadCrewmembers();
    this.setState({ savingCrewmembers: false, crewmembersModalOpen: false });
  }

  saveEquipment = async () => {
    const crewId = this.props.match.params.id;
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    if (!this.state.equipment)
      return;

    this.setState({ savingEquipment: true });
    const originalEquipmentIds = this.state.originalEquipment.filter(x => x.crewID === crewId).map(x => x.id);

    await Promise.all(this.state.equipment.map(async (element) => {
      if (originalEquipmentIds.includes(element.id)) {
        if (element.crewID !== crewId) {
          await updateEquipment(inventoryId, element.id, { crewID: element.crewID });
        }
      } else {
        if (element.crewID === crewId) {
          await updateEquipment(inventoryId, element.id, { crewID: crewId });
        }
      }
    }));

    await this.loadEquipment();
    this.setState({ savingEquipment: false, equipmentModalOpen: false });
  }

  loadCrew = async () => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const crewId = this.props.match.params.id;

    const crewResult = await getCrew(inventoryId, crewId);
    this.setState({ crew: crewResult.data });
  }

  loadCrewmembers = async () => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const companyId = currentUser.userProfile.companyID;
    const crewId = this.props.match.params.id;

    const getCrewmembersResult = await getCrewmembers(inventoryId, companyId, true, false);

    this.setState({ crewmembers: getCrewmembersResult.data, originalCrewmembers: getCrewmembersResult.data });
  }

  loadEquipment = async () => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const companyId = currentUser.userProfile.companyID;
    const crewId = this.props.match.params.id;

    const getEquipmentResult = await getEquipment(inventoryId, companyId, true, false);
    this.setState({ equipment: getEquipmentResult.data, originalEquipment: getEquipmentResult.data });
  }

  onChangeCrewmember = async (id) => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    const crewmembers = [...this.state.crewmembers];
    const crewmember = { ...crewmembers.find(x => x.id === id) };
    const index = crewmembers.findIndex(x => x.id === crewmember.id);
    const newCrewIdValue = !crewmember.crewID || crewmember.crewID !== this.state.crew.id ? this.state.crew.id : null;

    if (newCrewIdValue && crewmember.crewID) {
      this.setState({ overrideCrewmembersCrewModal: true, overrideCrewmembersCrew: id, crewmembersModalOpen:false });
      return;
    }

    crewmember.crewID = newCrewIdValue;
    crewmembers[index] = crewmember;
    //updateCrewmember(inventoryId, id, { crewID: newCrewIdValue });

    this.setState({ crewmembers: crewmembers });
  }

  addToJob = async () => {
    if (!this.state.jobId)
      return;

    this.setState({ updatingJob: true });

    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const crewId = this.props.match.params.id;

    const job = this.state.job;
    let crewAppoitment = {
      crewID: crewId,
      jobID: job.id,
      startDate: job.startDate,
      endDate: job.endDate
    };

    await addCrewAppoitment(inventoryId, crewAppoitment);

    const crewmembers = await getCrewmembers(inventoryId, null, false, false);
    const crewsCrewmembers = crewmembers.data.filter(x => x.crewID === crewId);
    const crewSchedules = await getCrewSchedules(inventoryId);

    crewsCrewmembers.forEach(element => {
        addCrewSchedule(inventoryId, {
          crewMemberID: element.id,
          jobID: job.id,
          startDate: job.startDate,
          endDate: job.endDate
        });
    });

    const equipment = await getEquipment(inventoryId, null, false, false);
    const crewsEquipment = equipment.data.filter(x => x.crewID === crewId);
    const reservations = await getReservations(inventoryId);

    crewsEquipment.forEach(element => {
        addReservation(inventoryId, {
          equipmentID: element.id,
          jobID: job.id,
          type: 1,
          startDate: job.startDate,
          endDate: job.endDate
        });
    });

    this.props.history.push("/jobs/" + this.state.jobId + "/details/crews");
  }

  removeFromJob = async () => {
    if (!this.state.jobId || !this.state.appoitment)
      return;

    this.setState({ updatingJob: true });

    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const crewId = this.props.match.params.id;

    await deleteCrewAppoitment(inventoryId, this.state.appoitment.id);

    const crewmembers = await getCrewmembers(inventoryId, null, false, false);
    const crewsCrewmembers = crewmembers.data.filter(x => x.crewID === crewId);
    let crewSchedules = await getCrewSchedulesForJob(inventoryId, this.state.jobId);
    crewSchedules.data.forEach(element => {
      if (crewsCrewmembers.map(x => x.id).includes(element.crewMemberID)) {
        deleteCrewSchedule(inventoryId, element.id);
      }
    });

    const equipment = await getEquipment(inventoryId, null, false, false);
    const crewsEquipment = equipment.data.filter(x => x.crewID === crewId);
    let reservations = await getReservationsForAJob(inventoryId, this.state.jobId);
    reservations.data.forEach(element => {
      if (crewsEquipment.map(x => x.id).includes(element.equipmentID)) {
        deleteReservation(inventoryId, element.id);
      }
    });

    this.props.history.push("/jobs/" + this.state.jobId + "/details/crews");
  }

  onChangeEquipment = async (id) => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    const equipment = [...this.state.equipment];
    const pieceOfEquipment = { ...equipment.find(x => x.id === id) };
    const index = equipment.findIndex(x => x.id === pieceOfEquipment.id);

    const newCrewIdValue = !pieceOfEquipment.crewID || pieceOfEquipment.crewID !== this.state.crew.id ? this.state.crew.id : null;

    if (newCrewIdValue && pieceOfEquipment.crewID) {
      this.setState({ overrideEquipmentCrewModal: true, overrideEquipmentCrew: id, equipmentModalOpen:false });
      return;
    }

    pieceOfEquipment.crewID = newCrewIdValue;
    equipment[index] = pieceOfEquipment;
    //updateEquipment(inventoryId, id, { crewID: newCrewIdValue });

    this.setState({ equipment: equipment });
  }

  overrideCrewmembersCrew = async () => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const id = this.state.overrideCrewmembersCrew;

    if (!id)
      return;

    const crewmembers = [...this.state.crewmembers];
    const crewmember = { ...crewmembers.find(x => x.id === id) };
    const index = crewmembers.findIndex(x => x.id === crewmember.id);
    const newCrewIdValue = this.state.crew.id;

    crewmember.crewID = newCrewIdValue;
    crewmembers[index] = crewmember;
    //updateCrewmember(inventoryId, id, { crewID: newCrewIdValue });

    this.setState({ crewmembers: crewmembers, crewmembersModalOpen:false });
  }

  overrideEquipmentCrew = async () => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const id = this.state.overrideEquipmentCrew;

    if (!id)
      return;

    const equipment = [...this.state.equipment];
    const pieceOfEquipment = { ...equipment.find(x => x.id === id) };
    const index = equipment.findIndex(x => x.id === pieceOfEquipment.id);
    const newCrewIdValue = this.state.crew.id;

    pieceOfEquipment.crewID = newCrewIdValue;
    equipment[index] = pieceOfEquipment;
    //updateEquipment(inventoryId, id, { crewID: newCrewIdValue });

    this.setState({ equipment: equipment, equipmentModalOpen: false });
  }

  getAddedCrewmembers = () => {
    return this.state.originalCrewmembers.filter(x => x.crewID === this.state.crew.id);
  }

  getAddedEquipment = () => {
    return this.state.originalEquipment.filter(x => x.crewID === this.state.crew.id);
  }

  getInitials = (item) => {
    return (item.firstName + " " + item.lastName).split(" ").map((n) => n[0]).join("");
  }

  render() {
    if (!this.state.crew) {
      return <Loader />
    }

    const jobId = this.props.match.params.jobId;
    return (
      <React.Fragment>
        <div className="card card-custom">
          <div className="card-header h-auto py-4">
            <div className="card-title">
              <h3 className="card-label">
                Crew details
                <span className="d-block text-muted pt-2 font-size-sm">
                  {this.state.crew.crewName}
                </span>
                {this.state.jobId && !this.state.canAdd && (
                  <span className="d-block text-danger pt-2 font-size-sm">
                    This crew is already assigned to a job
                  </span>
                )}
              </h3>
            </div>
            <div className="card-toolbar">
              {this.state.jobId && (
                <>
                  {this.state.appoitment ? (
                    <a
                      onClick={() => {
                        this.removeFromJob();
                      }}
                      className={
                        this.state.updatingJob
                          ? "btn btn-danger btn-sm font-weight-bold mr-4 spinner spinner-white spinner-right"
                          : "btn btn-danger btn-sm font-weight-bold mr-4"
                      }
                    >
                      Remove from Job
                    </a>
                  ) : (
                    <>
                      <a
                        onClick={() => {
                          this.addToJob();
                        }}
                        className={
                          this.state.updatingJob
                            ? "btn btn-primary btn-sm font-weight-bold mr-4 spinner spinner-white spinner-right"
                            : "btn btn-primary btn-sm font-weight-bold mr-4"
                        }
                      >
                        Add to Job
                      </a>
                    </>
                  )}
                </>
              )}
              {this.user.permissions !== "crewmember" && (
                <a
                  onClick={() => {
                    this.state.jobId
                      ? this.props.history.push(
                          "/jobs/" +
                            this.state.jobId +
                            "/crews/" +
                            this.state.crew.id +
                            "/edit"
                        )
                      : this.props.history.push(
                          "/crews/" + this.state.crew.id + "/edit"
                        );
                  }}
                  className="btn btn-success btn-sm font-weight-bold mr-4"
                >
                  <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                    <Edit />
                  </span>
                  Edit
                </a>
              )}
            </div>
          </div>
          <div className="card-body py-4">
            <div className="form-group row my-2">
              <label className="col-4 col-form-label">Crew Id:</label>
              <div className="col-8">
                <span className="form-control-plaintext font-weight-bolder">
                  {this.state.crew.crewID}
                </span>
              </div>
            </div>
            <div className="form-group row my-2">
              <label className="col-4 col-form-label">Crew Name:</label>
              <div className="col-8">
                <span className="form-control-plaintext font-weight-bolder">
                  {this.state.crew.crewName}
                </span>
              </div>
            </div>
            <div className="form-group row my-2">
              <label className="col-4 col-form-label">Color:</label>
              <div className="col-8 pt-3">
                <span
                  className="dot crew-dot"
                  style={{
                    backgroundColor:
                      "rgba(" +
                      fromRGBValue(this.state.crew.red) +
                      ", " +
                      fromRGBValue(this.state.crew.green) +
                      ", " +
                      fromRGBValue(this.state.crew.blue) +
                      ", " +
                      this.state.crew.alpha +
                      ")",
                  }}
                ></span>{" "}
              </div>
            </div>
          </div>
        </div>
        {this.state.crewmembers && this.state.equipment && (
          <div className="row mt-6">
            <>
              <div className="col-xl-6 pt-10 pt-xl-0">
                <div
                  className="card card-custom card-stretch"
                  id="kt_todo_view"
                >
                  <div className="card-header align-items-center flex-wrap justify-content-between border-0 py-6 h-auto">
                    <div className="d-flex flex-column mr-2 py-2">
                      <a className="text-dark text-hover-primary font-weight-bold font-size-h4 mr-3">
                        Crewmembers
                      </a>
                    </div>
                    <div className="card-toolbar">
                      <a
                        onClick={() => {
                          this.setState({ crewmembersModalOpen: true });
                        }}
                        className="btn btn-primary btn-sm font-weight-bold mr-4"
                      >
                        Add/Remove
                      </a>
                    </div>
                  </div>
                  <div className="separator separator-dashed mt-3 mb-2"></div>
                  <div className="card-body pt-4 justify-content-between">
                    {this.getAddedCrewmembers().length > 0 ? (
                      <>
                        {this.getAddedCrewmembers().map((item, index) => {
                          return (
                            <Link
                              to={"/crewmembers/" + item.id}
                              key={"jb-crw-gtadd" + index}
                              className="d-flex align-items-center mb-7"
                            >
                              <div
                                key={"jb-crw-gtadd" + index}
                                className="d-flex align-items-center mb-7"
                              >
                                <div className="flex-shrink-0 mr-4 mt-lg-0 mt-3">
                                  {item.image ? (
                                    <div className="symbol symbol-lg-50">
                                      <img alt="Pic" src={item.image} />
                                    </div>
                                  ) : (
                                    <div className="symbol symbol-lg-50 symbol-primary">
                                      <span className="symbol-label font-size-h3 font-weight-boldest">
                                        {this.getInitials(item)}
                                      </span>
                                    </div>
                                  )}
                                </div>
                                <div className="d-flex flex-column">
                                  <div>
                                    <span className="text-dark font-weight-bold font-size-h4 mb-0 text-hover-primary">
                                      {item.firstName + " " + item.lastName}
                                    </span>
                                    <br />
                                    <span className="text-dark font-size-lg mb-0">
                                      {item.position}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          );
                        })}
                      </>
                    ) : (
                      <span className="font-italic">(No items)</span>
                    )}
                  </div>
                </div>
              </div>
              <AddRemoveModal
                id="crewmembers-select"
                title="Crewmembers"
                items={this.state.crewmembers.map((x) => {
                  const currentCrew = this.state.allCrews.find(
                    (crew) => x.crewID == crew.id
                  );
                  const crewName = currentCrew
                    ? "(" + currentCrew.crewName + ")"
                    : "";

                  return {
                    id: x.id,
                    display: x.firstName + " " + x.lastName + " " + crewName,
                    value: this.state.crew.id === x.crewID,
                  };
                })}
                show={this.state.crewmembersModalOpen}
                toggle={() => {
                  this.setState({
                    crewmembers: this.state.originalCrewmembers,
                    crewmembersModalOpen: false,
                  });
                }}
                save={this.saveCrewmembers}
                saving={this.state.savingCrewmembers}
                onChangeHandler={this.onChangeCrewmember}
              />
              <ConfirmModal
                id="crewmembers"
                show={this.state.overrideCrewmembersCrewModal}
                title="Move crewmember"
                body="This crew member is already associated with another Crew. Would you like to move him to this crew?"
                yesButton={{
                  title: "Yes",
                  onClick: () => {
                    this.overrideCrewmembersCrew();
                    this.setState({
                      overrideCrewmembersCrewModal: false,
                      crewmembersModalOpen: true,
                    });
                  },
                }}
                noButton={{
                  title: "Cancel",
                  onClick: () => {
                    this.setState({
                      overrideCrewmembersCrewModal: false,
                      crewmembersModalOpen: true,
                    });
                  },
                }}
              />
            </>
            <>
              <div className="col-xl-6 pt-10 pt-xl-0">
                <div
                  className="card card-custom card-stretch"
                  id="kt_todo_view"
                >
                  <div className="card-header align-items-center flex-wrap justify-content-between border-0 py-6 h-auto">
                    <div className="d-flex flex-column mr-2 py-2">
                      <a className="text-dark text-hover-primary font-weight-bold font-size-h4 mr-3">
                        Equipment
                      </a>
                    </div>
                    <div className="card-toolbar">
                      <a
                        onClick={() => {
                          this.setState({ equipmentModalOpen: true });
                        }}
                        className="btn btn-primary btn-sm font-weight-bold mr-4"
                      >
                        Add/Remove
                      </a>
                    </div>
                  </div>
                  <div className="separator separator-dashed mt-3 mb-2"></div>
                  <div className="card-body pt-4 justify-content-between">
                    {this.getAddedEquipment().length > 0 ? (
                      <>
                        {this.getAddedEquipment().map((item, index) => {
                          return (
                            <Link
                              to={`/equipment/${item.id}`}
                              key={`jb-crw-gtadd${index}`}
                              className="d-flex align-items-center mb-7 text-hover-primary"
                            >
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 mr-4 mt-lg-0 mt-3">
                                  {item.image ? (
                                    <div className="symbol symbol-lg-50">
                                      <img alt="Pic" src={item.image} />
                                    </div>
                                  ) : (
                                    <div className="symbol symbol-lg-50 symbol-primary">
                                      <span className="symbol-label font-size-h3 font-weight-boldest"></span>
                                    </div>
                                  )}
                                </div>
                                <div className="d-flex flex-column">
                                  <div>
                                    <div className="text-dark font-weight-bold font-size-h6 mb-0 text-hover-primary">
                                      ID: {item.equipmentID}
                                    </div>
                                    <div className="text-dark font-size-lg mb-0">
                                      Make: {item.make}
                                    </div>
                                    {item.model && (
                                      <div className="text-dark font-size-lg mb-0">
                                        Model: {item.model}
                                      </div>
                                    )}
                                    {item.year && (
                                      <div className="text-dark font-size-lg mb-0">
                                        Year: {item.year}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Link>
                          );
                        })}
                      </>
                    ) : (
                      <span className="font-italic">(No items)</span>
                    )}
                  </div>
                </div>
              </div>
              <AddRemoveModal
                id="equipment-select"
                title="Equipment"
                items={this.state.equipment
                  .map((x) => {
                    const currentCrew = this.state.allCrews.find(
                      (crew) => x.crewID == crew.id
                    );
                    const crewName = currentCrew
                      ? "(" + currentCrew.crewName + ")"
                      : "";
                    return {
                      id: x.id,
                      display:
                        x.equipmentID +
                        ": " +
                        x.make +
                        "-" +
                        x.model +
                        " " +
                        crewName,
                      value: this.state.crew.id === x.crewID,
                    };
                  })
                  .sort((a, b) => {
                    return a.display > b.display
                      ? 1
                      : b.display > a.display
                      ? -1
                      : 0;
                  })}
                show={this.state.equipmentModalOpen}
                toggle={() => {
                  this.setState({
                    equipment: this.state.originalEquipment,
                    equipmentModalOpen: false,
                  });
                }}
                save={this.saveEquipment}
                saving={this.state.savingEquipment}
                onChangeHandler={this.onChangeEquipment}
              />
              <ConfirmModal
                id="equipment-select-confirmation"
                show={this.state.overrideEquipmentCrewModal}
                title="Move equipment"
                body="This equipment is already associated with another Crew. Would you like to move it to this crew?"
                yesButton={{
                  title: "Yes",
                  onClick: () => {
                    this.overrideEquipmentCrew();
                    this.setState({
                      overrideEquipmentCrewModal: false,
                      equipmentModalOpen: true,
                    });
                  },
                }}
                noButton={{
                  title: "Cancel",
                  onClick: () => {
                    this.setState({
                      overrideEquipmentCrewModal: false,
                      equipmentModalOpen: true,
                    });
                  },
                }}
              />
            </>
          </div>
        )}
        {!jobId && (
          <div className="card card-custom mt-10">
            <div className="card-header">
              <div className="card-title">
                <h3 className="card-label">Crew schedule</h3>
              </div>
              <div className="card-toolbar">
                {this.state.schedules.length > 0 && (
                  <>
                    <a
                      onClick={() => this.goTo("month")}
                      className="btn btn-icon btn-light-secondary mr-2"
                    >
                      <i className="flaticon2-calendar-2"></i>
                    </a>
                    <a
                      onClick={() => this.goTo("week")}
                      className="btn btn-icon btn-light-secondary mr-2"
                    >
                      <i className="flaticon2-calendar-1"></i>
                    </a>
                    <a
                      onClick={() => this.goTo("list")}
                      className="btn btn-icon btn-light-secondary mr-2"
                    >
                      <i className="flaticon-list-2"></i>
                    </a>
                  </>
                )}
              </div>
            </div>
            <div className="card-body">
              {this.state.schedules.length > 0 ? (
                <>
                  <div className="card-body">
                    <div
                      style={{
                        display: this.state.view !== "list" ? "none" : "block",
                      }}
                    >
                      <div className="timeline timeline-5">
                        <div className="timeline-items">
                          {this.state.schedules.map((schedule, index) => (
                            <Link
                              to={"/jobs/" + schedule.jobID + "/details"}
                              key={"eq-schdl-itm" + index}
                              className="timeline-item"
                            >
                              <div className="timeline-media bg-light-primary">
                                <span
                                  className={
                                    "svg-icon svg-icon-" +
                                    this.getClass(schedule.type) +
                                    " svg-icon-md"
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      />
                                      <path
                                        d="M15.9956071,6 L9,6 C7.34314575,6 6,7.34314575 6,9 L6,15.9956071 C4.70185442,15.9316381 4,15.1706419 4,13.8181818 L4,6.18181818 C4,4.76751186 4.76751186,4 6.18181818,4 L13.8181818,4 C15.1706419,4 15.9316381,4.70185442 15.9956071,6 Z"
                                        fill="#000000"
                                        fillRule="nonzero"
                                        opacity="0.3"
                                      />
                                      <path
                                        d="M10.1818182,8 L17.8181818,8 C19.2324881,8 20,8.76751186 20,10.1818182 L20,17.8181818 C20,19.2324881 19.2324881,20 17.8181818,20 L10.1818182,20 C8.76751186,20 8,19.2324881 8,17.8181818 L8,10.1818182 C8,8.76751186 8.76751186,8 10.1818182,8 Z"
                                        fill="#000000"
                                      />
                                    </g>
                                  </svg>
                                </span>
                              </div>
                              <div className="timeline-desc timeline-desc-light-primary">
                                <span className="font-weight-bolder text-secondary">
                                  {moment(schedule.startDate.toDate()).format(
                                    "MMM DD, YYYY"
                                  )}
                                </span>
                                <br />
                                <span className="font-weight-bolder text-secondary">
                                  {schedule.title}{" "}
                                  <span className="font-weight-normal text-dark-50 pb-2">
                                    (
                                    {moment(schedule.startDate.toDate()).format(
                                      "MMM DD, YYYY"
                                    ) +
                                      " - " +
                                      moment(schedule.endDate.toDate()).format(
                                        "MMM DD, YYYY"
                                      )}
                                    )
                                  </span>
                                </span>
                                <p className="font-weight-normal text-dark-50 pb-2">
                                  {schedule.description}
                                </p>
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: this.state.view === "list" ? "none" : "block",
                      }}
                      id="kt_calendar"
                    />
                  </div>
                  <div
                    style={{
                      display: this.state.view === "list" ? "none" : "block",
                    }}
                    id="kt_calendar"
                  />
                </>
              ) : (
                <span className="font-italic">(No items)</span>
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default CrewsDetails;