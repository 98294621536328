import React from "react";

const JobEquipmentFilter = () =>{

    return (
        <React.Fragment>
            
        </React.Fragment>
    )
}

export default JobEquipmentFilter;