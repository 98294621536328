import { useEffect, useState } from "react"
import { getLocateTickets, getLocateTicketsJobs } from "../../shared/api/LocateTicketsApi";
import { LocateTicketEntity } from "../../shared/data/LocateTickets";
import { JobEntity, JobStatus } from "../../shared/data/job/Job";
import { getJobs } from "../../shared/Api";

function useLocateTickets(inventoryId:string) {
    const [locateTickets, setLocateTickets] = useState<LocateTicketEntity[]>([]);
    const [jobs, setJobs] = useState<JobEntity[]>([])

    useEffect(() => {
        const fetchData = async () => {
            const responseLocateTickets = await getLocateTickets(inventoryId);
            const responseJobs:any = await getJobs(inventoryId)
            const responseLocateTicketData = (responseLocateTickets.data ?? []).sort((a, b) => (a.ticketID ?? "") > (b.ticketID ?? "") ? 1 : -1);
            const responseActiveJobs = responseJobs.data.filter((job:JobEntity)=>{return job.status==JobStatus.published})

            setLocateTickets(responseLocateTicketData)
            setJobs(responseActiveJobs)
        }

        fetchData()
    }, [inventoryId])

    return { locateTickets, jobs }

}

export default useLocateTickets