import React, { Component } from "react";
import { Edit } from "../../../components/Icons/Svg";
import JobDetailsPreview from "./JobDetailsPreview";
import JobDetailsEdit from "./JobDetailsEdit";
import { getJobStatuses } from "../../../shared/api/JobApi";
import { AuthContext } from "../../../shared/Auth";
import { getFirstElement } from "../../../shared/Utils";

class JobDetailsContainer extends Component {
  static contextType = AuthContext;

  state = {
    editMode: false,
    jobStatuses: [],
  };

  componentDidMount = async () => {
    await this.loadData();
  };

  loadData = async () => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    const jobStatuses = await getJobStatuses(inventoryId, this.props.job.id);

    this.setState({ jobStatuses: jobStatuses.data });
  };

  toggleEditMode = () => {
    this.setState({ editMode: !this.state.editMode });
  };

  render() {
    return (
      <React.Fragment>
        <div className="card card-custom gutter-b card-stretch">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="d-flex flex-column mr-auto">
                <a
                  href="#"
                  className="card-title text-hover-primary font-weight-bolder font-size-h5 text-dark mb-1"
                >
                  Job Details
                </a>
              </div>
              {this.props.canEdit && !this.props.form.loading && (
                <div className="card-toolbar mb-auto">
                  <div
                    className="dropdown dropdown-inline"
                    data-toggle="tooltip"
                    title="Quick actions"
                    data-placement="left"
                  >
                    <a
                      onClick={this.toggleEditMode}
                      className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span className="svg-icon svg-icon-primary svg-icon-2x">
                        <Edit />
                      </span>
                    </a>
                  </div>
                </div>
              )}
            </div>
            <div className="p-6">
              {this.state.editMode ? (
                <JobDetailsEdit
                  job={this.props.job}
                  jobStatus={getFirstElement(this.state.jobStatuses)}
                  onSaveComplete={() => {
                    this.loadData();
                    this.toggleEditMode();
                    this.props.handleSubmit();
                  }}
                  onCancel={this.toggleEditMode}
                />
              ) : (
                <JobDetailsPreview
                  job={this.props.job}
                  jobStatus={getFirstElement(this.state.jobStatuses)}
                />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default JobDetailsContainer;
