import React from "react";
import { SegmentEntity } from "../../../shared/data/taskmanagement/Segment";
import { TaskEntity } from "../../../shared/data/taskmanagement/Task";
import TaskItemComponent from "./Task/TaskItemComponent";
import SegmentItemComponent from "./Segment/SegmentItemComponent";
import { JobEntity } from "../../../shared/data/job/Job";
import { CrewEntity } from "../../../shared/data/Crew";
import Loader from '../../../components/Layout/Loader/Loader';

interface SegmentWithTasks {
  segment: SegmentEntity;
  tasks?: TaskEntity[] | null;
}

interface TaskManagementProps {
  job?: JobEntity;
  segmentsWithTasks?: SegmentWithTasks[];
  tasksWithoutSegment?: TaskEntity[];
  allCrews?: CrewEntity[];
  canEdit: false;
  isLoading: false;
  onSegmentDeleted?: (segment: SegmentEntity) => void;
  onTaskDeleted?: (task: TaskEntity) => void;
}

export const TaskManagementList = ({
  job,
  segmentsWithTasks,
  tasksWithoutSegment,
  allCrews,
  canEdit,
  isLoading,
  onSegmentDeleted = () => {},
  onTaskDeleted = () => {},
}: TaskManagementProps) => {
  const notCompletedTasks = tasksWithoutSegment?.filter((tasks)=>tasks?.taskStatus!='closed')??[]
  const completedTasks = tasksWithoutSegment?.filter((tasks)=>tasks?.taskStatus=='closed')??[]

  const tasks = [...notCompletedTasks, ...completedTasks]
  
  return (
    <React.Fragment>
    {isLoading ? (<Loader height={"320px"} />) :(<div>
      {(segmentsWithTasks?.length ?? 0) > 0 ||
      (tasksWithoutSegment?.length ?? 0) > 0 ? (
        <div>
          <div className="h3">JOB SEGMENTS</div>
          {segmentsWithTasks?.map((segmentWithTasks, index) => {
            return (
              <SegmentItemComponent
                job={job}
                segment={segmentWithTasks.segment}
                segmentTasks={segmentWithTasks.tasks ?? []}
                allCrews={allCrews}
                allTaskCrews={[]}
                canEdit={canEdit}
                onSegmentDeleted={(segment) => {
                  onSegmentDeleted(segment);
                }}
              />
            );
          })}

          <div className="h3 mt-8">JOB TASKS</div>
          {tasks?.map((task, index) => {
            return (
              <TaskItemComponent
                task={task}
                allCrews={allCrews}
                canEdit={canEdit}
                onTaskDeleted={(task) => {
                  onTaskDeleted(task);
                }}
              />
            );
          })}
        </div>
      ) : (
        <div className="h2 text-center">No segments or Tasks Found</div>
      )}
    </div>)}
    </React.Fragment>
  );
};
