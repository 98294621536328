import React, { Component } from 'react';
import SimpleForm from '../../components/Form/SimpleForm';
import { validateField } from '../../shared/Validation';

import { AuthContext } from "../../shared/Auth";
import { getMessages } from '../../components/Messages/GetMessages';
import { addClient, addContact, updateJob } from '../../shared/Api';
import { normalizeInput } from '../../shared/FormHelper';

class ClientNew extends Component {
  static contextType = AuthContext;

  state = {
    fields: [
      {
        name: "companyName",
        value: "",
        defaultValue: "",
        type: "text",
        label: "New Company Name",
        placeholder: "New Company Name",
        isValid: true,
        validationMessage: "",
        isTouched: false,
        additionalMessage: getMessages("enter_company"),
        required: true,
        validators: [
          {
            type: "required",
            message: getMessages("company_required"),
          },
        ],
      },
      {
        name: "phoneNumber",
        value: "",
        defaultValue: "",
        placeholder: "Contact Phone",
        type: "text",
        subtype: "phone",
        label: "Contact Phone",
        isValid: true,
        validationMessage: "",
        isTouched: false,
        additionalMessage: getMessages("enter_client_phone"),
        required: false,
      },
      {
        name: "address",
        value: "",
        defaultValue: "",
        placeholder: "Address",
        type: "text",
        label: "Address",
        isValid: true,
        validationMessage: "",
        isTouched: false,
        additionalMessage: getMessages("enter_client_address"),
        required: false,
      }, {
        name: "email",
        value: "",
        defaultValue: "",
        placeholder: "Email",
        type: "text",
        label: "Email",
        isValid: true,
        validationMessage: "",
        isTouched: false,
        additionalMessage: getMessages("enter_client_email"),
        required: false,
      },
      {
        name: "notes",
        value: "",
        defaultValue: "",
        placeholder: "Notes",
        type: "text",
        label: "Notes",
        isValid: true,
        validationMessage: "",
        isTouched: false,
        additionalMessage: getMessages("enter_client_notes"),
        required: false,
      },
      {
        name: "firstName",
        value: "",
        defaultValue: "",
        placeholder: "First Name",
        type: "text",
        label: "First Name",
        isValid: true,
        validationMessage: "",
        isTouched: false,
        additionalMessage: getMessages("enter_client_firstname"),
        required: true,
        validators: [
          {
            type: "required",
            message: getMessages("client_firstname_required"),
          },
        ],
      },
      {
        name: "lastName",
        value: "",
        defaultValue: "",
        placeholder: "Last Name",
        type: "text",
        label: "Last Name",
        isValid: true,
        validationMessage: "",
        isTouched: false,
        additionalMessage: getMessages("enter_client_lastname"),
        required: true,
        validators: [
          {
            type: "required",
            message: getMessages("client_lastname_required"),
          },
        ],
      },

      {
        name: "contactEmail",
        value: "",
        defaultValue: "",
        placeholder: "Email",
        type: "text",
        label: "Email",
        isValid: true,
        validationMessage: "",
        isTouched: false,
        additionalMessage: getMessages("enter_contact_email"),
        required: false,
      },
      {
        name: "contactPhoneNumber",
        value: "",
        defaultValue: "",
        placeholder: "Phone Number",
        type: "text",
        subtype: "phone",
        label: "Phone Number",
        isValid: true,
        validationMessage: "",
        isTouched: false,
        additionalMessage: getMessages("enter_contact_phone"),
        required: false,
      },
      {
        name: "phoneNumber2",
        value: "",
        defaultValue: "",
        placeholder: "Other phone number",
        type: "text",
        subtype: "phone",
        label: "Other phone number",
        isValid: true,
        validationMessage: "",
        isTouched: false,
        additionalMessage: getMessages("enter_contact_phone"),
        required: false,
        validators: [
          {
            type: "phoneNumber",
            message: getMessages("invalid_phone"),
          },
        ],
      },
      {
        name: "position",
        value: "",
        defaultValue: "",
        placeholder: "Position",
        type: "text",
        label: "Position",
        isValid: true,
        validationMessage: "",
        isTouched: false,
        additionalMessage: getMessages("enter_contact_position"),
        required: false,
      },
      {
        name: "contactNotes",
        value: "",
        defaultValue: "",
        placeholder: "Notes",
        type: "textarea",
        rows: 4,
        label: "Notes",
        isValid: true,
        validationMessage: "",
        isTouched: false,
        additionalMessage: getMessages("enter_contact_notes"),
        required: false,
      }
    ],
    isValid: false,
    submitError: false,
    submitErrorMessage: "",
    loading: false,
    accountCreated: false,
  };

  validate = () => {
    this.state.fields.forEach((field) => {
      validateField(field);
    });
  };

  clearValues() {
    let updatedFields = [...this.state.fields];
    updatedFields.forEach((element) => {
      element.value = element.defaultValue;
    });

    this.setState({ fields: updatedFields });
  }

  getGlobalValid = (fields) => {
    let isValid = true;
    fields.forEach((element) => {
      if ((element.required && !element.value) || !element.isValid)
        isValid = false;
    });

    return isValid;
  };

  touchField = (field) => {
    let fields = this.state.fields;
    let updatedField = fields.find((x) => x.name === field.name);
    if (!updatedField) return;

    updatedField.isTouched = true;
    this.setState({ fields: fields });
  };

  addValidationClass(field, cl) {
    if (field.isValid && field.value) return cl + " is-valid";
    if (!field.isValid && field.value) return cl + " is-invalid";

    return cl;
  }

  generateInputId(field) {
    return "ca-input-" + field.name;
  }

  handleChange = (event) => {
    let fields = [...this.state.fields];
    let field = fields.find((x) => x.name === event.target.name);
    let clientFields = fields.slice(0, 3);
    let contactFields = fields.slice(-7);

    if (!field) return;

    if (field.subtype === "phone") {
      field.value = normalizeInput(event.target.value, field.value);
    } else {
      field.value = event.target.value;
    }
    this.validate();

    if (!field.value) field.isTouched = false;

    const contactHasValues = contactFields.some((x) => {
      return x.value;
    });
    const clientValid = this.getGlobalValid(clientFields);

    if (clientValid && !contactHasValues) {
      this.setState({ fields: fields, isValid: true });
    } else {
      const globalValid = this.getGlobalValid(fields);
      this.setState({ fields: fields, isValid: globalValid });
    }
  };

  getObjectFromFields = (fields) => {
    let obj = {};
    fields.forEach((item) => {
      if (item.name !== "image") obj[item.name] = item.value;
    });

    return obj;
  };

  addClientToJob = async (clientId, contactId, inventoryId)=>{
    const job = {...this.props.location.state.job}
    if(job){
      job?.jobClients?.push({clientID:clientId, contactIDs:[contactId]})
      await updateJob(inventoryId, job.id, {jobClients:job.jobClients})
    }
  }

  handelSubmit = async (event) => {
    this.setState({ loading: true });
    let fields = this.state.fields;
    let clientFields = fields.slice(0, 4);
    let contactFields = fields.slice(-7);
    const contactValid = this.getGlobalValid(contactFields);

    const clientObj = this.getObjectFromFields(clientFields);
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    const addedClient = await addClient(inventoryId, clientObj);

    if (contactValid && addedClient.status === "success") {

      const contactObj = {};
      contactFields.forEach((item) => {
        if (item.name === "contactPhoneNumber") {
          contactObj.phoneNumber = item.value;
        } else if (item.name === "contactNotes") {
          contactObj.notes = item.value;
        } else if (item.name === "contactEmail") {
          contactObj.email = item.value;
        }else {
          contactObj[item.name] = item.value;
        }
        return contactObj;
      });
      const addedContact = await addContact(
        inventoryId,
        addedClient.data,
        contactObj
      );

      // it job object is present we need to add this client to it
      // because this page is open from the job
      if (this.props.location.state.job) {
        await this.addClientToJob(addedClient.data, addedContact.data, inventoryId)
      }
    }
    this.props.history.goBack();
  };

  submitButton = () => {
    const submitBtnClasses = "btn btn-primary mr-2";
    const submitBtnClassesWithError = this.state.submitError
      ? submitBtnClasses + " is-invalid"
      : submitBtnClasses;
    const submitBtnClassesFinal = this.state.loading
      ? submitBtnClassesWithError + " spinner spinner-white spinner-right"
      : submitBtnClassesWithError;

    return {
      title: "Save",
      className: submitBtnClassesFinal,
      onClick: () => {
        this.handelSubmit();
      },
      disabled: !this.state.isValid || this.state.loading,
    };
  };

  cancelButton = () => {
    return {
      title: "Cancel",
      className: "btn btn-secondary mr-2",
      onClick: () => {
        this.props.history.goBack();
      },
    };
  };

  render() {
    const { fields } = this.state;
    const clientFields = fields.slice(0, 4);
    const contactFields = fields.slice(-7);

    return (
      <React.Fragment>
        <div className="card card-custom">
          <div className="card-header">
            <h3 className="card-title">Add new client</h3>
          </div>
          <SimpleForm
            formUniqueName="add-client"
            fields={clientFields}
            otherFields={contactFields}
            touchField={this.touchField}
            submitButton={this.submitButton()}
            cancelButton={this.cancelButton()}
            handleInputChange={this.handleChange}
            onSubmit={this.handelSubmit}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default ClientNew;