import React, { Component } from 'react';
import { AuthContext } from "../../../shared/Auth";
import { getReservationsForAJob, getSingleEquipment, updateReservation, getCustomChecklists, getJob, updateMaterial } from '../../../shared/Api';
import * as moment from 'moment';
import Loader from '../../../components/Layout/Loader/Loader';
import { showToast, getUnixDate } from '../../../shared/Util';

class JobEquipmentCheckoutList extends Component {
    static contextType = AuthContext;
    user = this.context.currentUser.userProfile;
    canEdit = this.user.permissions !== 'crewmember';

    state = {
        standardEquipmentList: [],
        customEquipmentList: [],
        loading: false
    };

    componentDidMount = async () => {
        await this.loadCheckLists();
    }

    loadCheckLists = async () => {
        this.setState({ loading: true });
        const id = this.props.match.params.id;
        const currentUser = this.context.currentUser;
        const inventoryId = currentUser.company.inventoryID;
        const companyId = currentUser.userProfile.companyID;
        const date = this.props.match.params.date;

        const job = await getJob(inventoryId, id);

        let reservations = await getReservationsForAJob(inventoryId, id);

        let checkoutList = [];
        await Promise.all(reservations.data.map(async (reservation) => {
            const tmpDate = getUnixDate(reservation.startDate.toDate());
            if (date == tmpDate) {
                const equipment = await getSingleEquipment(inventoryId, reservation.equipmentID, companyId, true);
                let eqData = equipment.data;
                eqData.checked = !!reservation.checkOutDate;
                eqData.reservationId = reservation.id;
                checkoutList.push(eqData);
            }
        }));

        let customChecklists = await getCustomChecklists(inventoryId, id, companyId, true);

        let customEquipmentList = [];

        const jobStartDate = getUnixDate(job.data.startDate.toDate());
        if (jobStartDate == date) {
            customEquipmentList = customChecklists.data;
        }

        this.setState({ standardEquipmentList: checkoutList, customEquipmentList: customEquipmentList, loading: false });
    }

    checkOutReservation = async (reservationId) => {
        const currentUser = this.context.currentUser;
        const inventoryId = currentUser.company.inventoryID;

        let standardEquipmentList = [...this.state.standardEquipmentList];
        let eq = standardEquipmentList.find(x => x.reservationId === reservationId);
        if (!eq)
            return;

        eq.checked = !eq.checked;

        this.setState({ standardEquipmentList: standardEquipmentList });
        await updateReservation(inventoryId, reservationId, { checkOutDate: eq.checked ? moment().toDate() : null });

        if(eq.checked) {
            showToast("success", "Item checked out.");
        }
    }

    checkCustomCheckList = async (customCheckListId) => {
        const currentUser = this.context.currentUser;
        const inventoryId = currentUser.company.inventoryID;

        const date = moment();
        let customEquipmentList = [...this.state.customEquipmentList];
        let eq = customEquipmentList.find(x => x.id === customCheckListId);
        if (!eq)
            return;

        eq.checkOutDate = eq.checkOutDate ? null : date;

        this.setState({ customEquipmentList: customEquipmentList });
        await updateMaterial(inventoryId, customCheckListId, { checkOutDate: eq.checkOutDate ? date.toDate() : null });

        if(eq.checkOutDate) {
            showToast("success", "Item checked out.");
        }
    }

    render() {
        if (this.state.loading)
            return <Loader />

        return (
            <div className="card card-custom">
                <div className="card-body p-0">
                    <div className="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
                        <div className="col-xl-12 col-xxl-7">
                            <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                <h3 className="mb-10 font-weight-bold text-dark">Standard Equipment List</h3>
                                <div className="separator separator-dashed mt-3 mb-7"></div>
                                {this.state.standardEquipmentList.length > 0 ?
                                    <>
                                        {this.state.standardEquipmentList.map((equipment,index) => (
                                            <div key={"jb-eq-chkout-stnd"+index} className="d-flex align-items-center mb-10">
                                                <div className="d-flex align-items-center">

                                                    <div className="symbol symbol-100 symbol-sm flex-shrink-0">
                                                        {equipment.image ?
                                                            <img className="" src={equipment.image} alt="photo" />
                                                            : <span className="symbol-label"></span>}
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column flex-grow-1 font-weight-bold ml-5">
                                                    <a className="text-dark text-hover-primary mb-1 font-size-lg">
                                                        {equipment.make} {equipment.model}
                                                    </a>
                                                    <span className="text-muted">{equipment.equipmentID} { }</span>
                                                </div>
                                                <label className="checkbox checkbox-lg checkbox-light-primary checkbox-inline flex-shrink-0 m-0 mx-4">
                                                    <input disabled={!this.canEdit}
                                                        onChange={() => this.checkOutReservation(equipment.reservationId)}
                                                        checked={equipment.checked}
                                                        type="checkbox" defaultValue={1} />
                                                    <span />
                                                </label>
                                            </div>
                                        ))}
                                    </>
                                    :
                                    <span className="font-italic">(No equipment)</span>}

                                <h3 className="mt-25 mb-10 font-weight-bold text-dark">Custom Equipment List</h3>
                                <div className="separator separator-dashed mt-3 mb-7"></div>
                                {this.state.customEquipmentList.length > 0 ?
                                    <>
                                        {this.state.customEquipmentList.map((equipment,index) => (
                                            <div key={"jb-eq-chkout-cstm"+index} className="d-flex align-items-center mb-10">
                                                <div className="d-flex align-items-center">

                                                    <div className="symbol symbol-100 symbol-sm flex-shrink-0">
                                                        {equipment.image ?
                                                            <img className="" src={equipment.image} alt="photo" />
                                                            : <span className="symbol-label"></span>}
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column flex-grow-1 font-weight-bold ml-5">
                                                    <a className="text-dark text-hover-primary mb-1 font-size-lg">
                                                        {equipment.name}
                                                    </a>
                                                    <span className="text-muted">{equipment.description} { }</span>
                                                </div>
                                                <label className="checkbox checkbox-lg checkbox-light-primary checkbox-inline flex-shrink-0 m-0 mx-4">
                                                    <input disabled={!this.canEdit}
                                                        onChange={() => this.checkCustomCheckList(equipment.id)}
                                                        checked={equipment.checkOutDate}
                                                        type="checkbox" defaultValue={1} />
                                                    <span />
                                                </label>
                                            </div>
                                        ))}
                                    </>
                                    :
                                    <span className="font-italic">(No equipment)</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default JobEquipmentCheckoutList;