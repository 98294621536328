import React, { useContext, useEffect, useState } from "react";
import LocateTicketNewMap from "./LocateTicketNewMap";
import {
  LocateTicketEntity,
  LocateTicketStatusEntity,
  buildLocateTicketFileEntityPath,
  buildLocateTicketFileUploadPath,
  buildLocateTicketFilesPath,
  getLocateTicketCurrentStatus,
} from "../../../shared/data/LocateTickets";
import { Timestamp } from "firebase/firestore";
import moment from "moment";
import { parseFromFirebaseDate, showToast, toFirebaseDate } from "../../../shared/Util";
import {
  saveLocateTicket,
} from "../../../shared/api/LocateTicketsApi";
import { useHistory } from "react-router-dom";
import { Location } from "../../../shared/data/Location";
import { AuthContext } from "../../../shared/Auth";
import { ContextEntity } from "../../../shared/data/context/ContextEntity";
import DropzoneInput from "../../../components/Form/Inputs/DropzoneInput";
import MultipleItems from "../../../components/Carousels/MultipleItems";
import { isImageFile } from "../../../shared/api/Upload";
import { JobEntity } from "../../../shared/data/job/Job";
import {
  FileUploadEntity,
  getFileData,
  saveFileData,
  saveFileEntity,
} from "../../../shared/api/FilesApi";
import {
  FileEntity,
  toFileEntity,
  toFileEntityDto,
} from "../../../shared/data/folder/FolderStructure";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import EQInputText from "../../../components/Form/Inputs/EQInputText";
import EQDatePicker from "../../../components/Form/Inputs/EQDatePicker";

interface LocateTicketProps {
  locateTicket: LocateTicketEntity;
  jobs?: JobEntity[] | null;
}

const LocateTicketNew = ({ locateTicket, jobs = null }: LocateTicketProps) => {
  const context: ContextEntity = useContext(AuthContext);
  const history = useHistory();

  //indexOfFileToRemove is used for temporary keeping index of file for removal before it is confirmed for deleting it in confirmation dialog
  //this value is set when user hits delete action on file and it is set to null when deletion is confirmed or canceled
  const [formState, setFormState] = useState<{
    saving: boolean;
    showConfirmModal: boolean;
    indexOfFileToRemove: number | null;
  }>({ saving: false, showConfirmModal: false, indexOfFileToRemove: null });

  const [files, setFiles] = useState<FileEntity[]>();

  const [locateTicketState, setLocateTicketState] =
    useState<LocateTicketEntity | null>(locateTicket);

  useEffect(() => {
    window.scrollTo(0, 0);
    loadData();
  }, []);

  const loadData = async () => {
    if (locateTicketState != null && locateTicketState.id) {
      const locateTicketFiles = await getFileData(
        buildLocateTicketFileEntityPath(locateTicketState.id),
        buildLocateTicketFilesPath(
          context.currentUser?.userProfile?.companyID ?? "",
          locateTicketState.id ?? ""
        )
      );
      setFiles(locateTicketFiles?.data ?? []);
    }
  };

  const onRemoveFile = async (fileIndex: number) => {
    const fileToDelete = files?.find((_, index) => index == fileIndex);
    setFiles((prevFiles) =>
      prevFiles?.filter((_, index) => index !== fileIndex)
    );

    //We remove file from the list. In case this is existing locate ticket we try to remove file from backend too
    if (locateTicketState?.id && fileToDelete) {
      const fileUpload: FileUploadEntity = {
        fileEntityPath: buildLocateTicketFileEntityPath(
          locateTicketState.id ?? ""
        ),
        file: null,
        fileUploadState: "delete",
        fileUploadPath: buildLocateTicketFileUploadPath(
          context.currentUser?.userProfile?.companyID ?? "",
          locateTicketState.id ?? "",
          fileToDelete?.createdAt?.seconds?.toString() ?? ""
        ),
        fileEntityDto: toFileEntityDto(fileToDelete),
        fileDocumentId: fileToDelete.id,
      };
      await saveFileData(fileUpload);
    }
  };

  const onAddFile = async (acceptedFiles: any) => {
    await Promise.all(
      acceptedFiles.map(async (file: File, index: number) => {
        // const file: File = acceptedFiles[0];
        const url = window.URL.createObjectURL(file);
        const createdAt = Timestamp.fromMillis(
          Timestamp.now().toMillis() + index * 5000
        );

        const fileEntity: FileEntity = {
          id: null,
          name: "",
          fileName: file.name ?? "",
          fileType: isImageFile(file.name ?? "") ? "image" : "document",
          createdAt: createdAt,
          createdBy: context.currentUser?.displayName,
          url: url,
          file: file,
        };

        //In case this is existing ticket we upload file as soon as it is added and save file entity in locate ticket.
        //In case this is new locate ticket we add file to file list and upload it when new locate ticket is created
        if (locateTicketState?.id) {
          //TODO: add form state for uploading image to show some indicator in case file is too big.
          await uploadFile(fileEntity, locateTicketState.id);
        } else {
          setFiles((prevFiles) => [...(prevFiles ?? []), fileEntity]);
        }
      })
    );
  };

  const uploadFile = async (fileEntity: FileEntity, locateTicketId: string) => {
    const fileUpload: FileUploadEntity = {
      fileEntityPath: buildLocateTicketFileEntityPath(locateTicketId),
      file: fileEntity.file,
      fileUploadState: "upload",
      fileUploadPath: buildLocateTicketFileUploadPath(
        context.currentUser?.userProfile?.companyID ?? "",
        locateTicketId,
        fileEntity?.createdAt?.seconds?.toString() ?? ""
      ),
      fileEntityDto: toFileEntityDto(fileEntity),
      fileDocumentId: null,
    };

    //TODO: add form state for uploading image to show some indicator in case file is too big.
    const result = await saveFileData(fileUpload);

    if (result?.data?.fileEntityDto) {
      const file = toFileEntity(
        result?.data?.fileEntityDto,
        result?.data?.fileDocumentId
      );
      setFiles((prevFiles) => [...(prevFiles ?? []), file]);
    }
  };

  const save = async () => {
    setFormState((previousState) => {
      return { ...previousState, saving: true };
    });
    try {
      const locateTicketToSave = { ...locateTicketState };
      if (locateTicketState) {
        const result = await saveLocateTicket(locateTicketToSave);
        if (!result.message) {
          const locateTicketId = result.documentId ?? result?.data?.id;

          if (locateTicketId) {
            //After ticket is saved we upload all files without id
            const filesToUpload = files?.filter((file) => !file.id);
            Promise.all(
              filesToUpload?.map(async (file) => {
                uploadFile(file, locateTicketId);
              }) ?? []
            );
          }
          history?.goBack();
        } else {
          console.error(result.message)
          showToast("danger", "Error saving locate ticket");
        }
      }
    } catch (error: any) {
      console.error(error);
      showToast("danger", "Error saving locate ticket");
    }
    setFormState((previousState) => {
      return { ...previousState, saving: false };
    });
  };

  const validateForm = () => {
    return (
      locateTicketState?.ticketID != null && locateTicketState.startDate != null && (locateTicketState.status?.length ?? 0) > 0
    );
  };

  const changeFileName = async (fileName: string, fileIndex: number) => {
    const fileToChangeName = files?.find((_, index) => index == fileIndex);
    if (fileToChangeName) {
      const newFiles = files?.map((file, index) => {
        if (index === fileIndex) {
          file.name = fileName;
        }
        return file;
      });
      setFiles(newFiles);
      await saveFileEntity(
        toFileEntityDto(fileToChangeName),
        buildLocateTicketFileEntityPath(locateTicketState?.id ?? ""),
        fileToChangeName.id
      );
    }
  };

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h3 className="mb-10 font-weight-bold text-dark">
            {locateTicketState?.id
              ? `Edit locate ticket`
              : `Add new locate ticket`}
          </h3>
          <EQInputText
            title="Locate Ticket ID"
            required={true}
            hint="ID for this locate ticket"
            inputText={locateTicketState?.ticketID ?? ""}
            onOnInputChange={(value) => {
              setLocateTicketState((previousState) => {
                return { ...previousState, ticketID: value.toString() };
              });
            }}
          />
          {jobs && (
            <div key="locate-ticket-new-job-selection" className="form-group">
              <label>Select job</label>
              <select
                key="task-new-task-type-select"
                className="form-control"
                onChange={(event) => {
                  const jobId = event.target.value;
                  const job = jobs.find((job) => jobId == job.id);
                  setLocateTicketState((previousState) => {
                    return {
                      ...previousState,
                      jobID: jobId,
                      lat: job?.locationLat,
                      lng: job?.locationLong,
                    };
                  });
                }}
              >
                <option value="" disabled selected>
                  Select job
                </option>
                {jobs?.map((item, index) => {
                  return (
                    <option
                      key={"job" + index}
                      value={item.id ?? ""}
                      className="form-control"
                      selected={item.id == locateTicketState?.jobID}
                    >
                      {item.jobID}({item.title})
                    </option>
                  );
                })}
                ;
              </select>
            </div>
          )}
          {locateTicketState &&
            getLocateTicketCurrentStatus(locateTicketState)?.type ==
              "approved" && (
              <div key="locateTicketExpirationDate" className="form-group">
                <label>
                  Expiration date<span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  className="form-control"
                  value={parseFromFirebaseDate(
                    locateTicketState?.expirationDate,
                    "YYYY-MM-DD"
                  )}
                  onChange={(input) => {
                    setLocateTicketState((previousState) => {
                      return {
                        ...previousState,
                        expirationDate: Timestamp.fromDate(
                          moment(input.target.value).toDate()
                        ),
                      };
                    });
                  }}
                ></input>
                <div className="form-text text-muted">
                  Locate ticket expiration date.
                </div>
              </div>
            )}
          <EQDatePicker
            title="Work Start Date"
            required={true}
            hint="The first day of the work."
            date={parseFromFirebaseDate(
              locateTicketState?.startDate,
              "YYYY-MM-DD"
            )}
            onDateSelected={(value) => {
              setLocateTicketState((previousState) => {
                return {
                  ...previousState,
                  startDate: Timestamp.fromDate(moment(value).toDate()),
                };
              });
            }}
          />
          {(locateTicket.status?.length ?? 0) < 1 && (
            <EQDatePicker
              title="Submit date"
              required={true}
              hint="Locate ticket submit date"
              onDateSelected={(date) => {
                setLocateTicketState((previousState) => {
                  const submittedStatus: LocateTicketStatusEntity = {
                    createdAt: Timestamp.now(),
                    date: toFirebaseDate(date),
                    type: "submitted",
                    userID: context.currentUser?.uid,
                    userName: context.currentUser?.displayName,
                  };

                  // const submittedStatus = new LocateTicketStatusEntity(
                  //   Timestamp.now(),
                  //   toFirebaseDate(date),
                  //   "submitted",
                  //   context.currentUser?.uid,
                  //   context.currentUser?.displayName
                  // );
                  return { ...previousState, status: [submittedStatus] };
                });
              }}
            />
          )}
          {/* <div key="locateTicketIdUtilityCompany" className="form-group">
            <label>Utility Location Company</label>
            <input
              type="text"
              className="form-control"
              value={locateTicketState?.utilityCompany ?? ""}
              onChange={(input) => {
                setLocateTicketState((previousState) => {
                  return {
                    ...previousState,
                    utilityCompany: input.target.value,
                  };
                });
              }}
            ></input>
            <div className="form-text text-muted">
              Company responsible to deliver approval for locate tickets.
            </div>
          </div> */}
          {/* <div key="locateTicketIdUtilityCompanyAddress" className="form-group">
            <label>Utility Location Company Address</label>
            <input
              type="text"
              className="form-control"
              value={locateTicketState?.utilityCompanyAddress ?? ""}
              onChange={(input) => {
                setLocateTicketState((previousState) => {
                  return {
                    ...previousState,
                    utilityCompanyAddress: input.target.value,
                  };
                });
              }}
            ></input>
            <div className="form-text text-muted">
              Address of company responsible to deliver approval for locate
              tickets.
            </div>
          </div> */}
          <EQInputText
            title="Notes"
            required={false}
            hint=""
            inputText={locateTicketState?.notes ?? ""}
            onOnInputChange={(value) => {
              setLocateTicketState((previousState) => {
                return { ...previousState, notes: value.toString() };
              });
            }}
          />
          {/* <div key="locateTicketNotes" className="form-group">
            <label>Notes</label>
            <input
              type="text"
              className="form-control"
              value={locateTicketState?.notes ?? ""}
              onChange={(input) => {
                setLocateTicketState((previousState) => {
                  return { ...previousState, notes: input.target.value };
                });
              }}
            ></input>
            <div className="form-text text-muted">Notes about this ticket.</div>
          </div> */}
          <div key="locateTicketMap" className="form-group">
            <label>Locate Ticket Location</label>
            <LocateTicketNewMap
              locateTicketCurrentLocation={
                new Location(
                  locateTicketState?.lat ?? 0,
                  locateTicketState?.lng ?? 0,
                  locateTicketState?.address ?? ""
                )
              }
              isEditMode={locateTicketState?.id != null}
              onLocationAdded={(result) => {
                setLocateTicketState((previousState) => {
                  return {
                    ...previousState,
                    lat: result?.lat ?? null,
                    lng: result?.lng ?? null,
                    address: result?.address ?? null,
                  };
                });
              }}
            />
          </div>
          <DropzoneInput
            {...{
              field: {
                onDrop: onAddFile,
              },
              supportedTypes: "Photos or Documents",
            }}
          />
          <div className="d-flex flex-row">
            <div className="flex-grow-1 min-w-0 mt-7">
              {" "}
              <MultipleItems
                slidesToShow={9}
                laptop={7}
                tablet={5}
                mobile={2}
                editFile={true}
                onNameChanged={(value: string, index: number) => {
                  changeFileName(value, index);
                }}
                images={files?.map((fileEntity) => {
                  return {
                    image: fileEntity.url ?? "",
                    title: fileEntity.fileName,
                    name: fileEntity.name ?? "",
                    createdAt: moment(fileEntity.createdAt?.toDate()).format(
                      "MM/DD/YY, hh:mma"
                    ),
                    uploadedBy: fileEntity.createdBy,
                  };
                })}
                removePhoto={(index: number) => {
                  setFormState((previousState) => {
                    return {
                      ...previousState,
                      showConfirmModal: true,
                      indexOfFileToRemove: index,
                    };
                  });
                }}
              />
            </div>
          </div>
          <div className="justify-content-between border-top mt-5 pt-10">
            <button
              key="segment-new-btn-save"
              className={
                formState.saving
                  ? "btn btn-primary font-weight-bold mr-4 py-4 spinner spinner-white spinner-right"
                  : "btn btn-primary font-weight-bold mr-4 py-4 px-9"
              }
              disabled={!validateForm()}
              onClick={(event) => {
                event.preventDefault();
                save();
              }}
            >
              Save
            </button>
            <button
              onClick={() => {
                history.goBack();
              }}
              className="btn btn-secondary font-weight-bold text-uppercase px-7 py-4"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <ConfirmModal
        show={formState.showConfirmModal}
        title="Delete file"
        body={"Are you sure you want to delete this file?"}
        yesButton={{
          title: "Yes",
          onClick: () => {
            if (formState.indexOfFileToRemove != null)
              onRemoveFile(formState.indexOfFileToRemove);
            setFormState((previousState) => {
              return {
                ...previousState,
                showConfirmModal: false,
                indexOfFileToRemove: null,
              };
            });
          },
        }}
        noButton={{
          title: "Cancel",
          onClick: () => {
            setFormState((previousState) => {
              return {
                ...previousState,
                showConfirmModal: false,
                indexOfFileToRemove: null,
              };
            });
          },
        }}
      />
    </React.Fragment>
  );
};

export default LocateTicketNew;
