import { Component } from 'react';
import app from '../../../firebase';
import {withRouter} from 'react-router-dom';
import AccountDetails from '../AccountDetails/AccountDetails';
import WelcomeScreen from '../WelcomeScreen/WelcomeScreen';
import firebase from 'firebase/compat/app'
import { AuthContext } from "../../../shared/Auth";
import { getMessages } from '../../../components/Messages/GetMessages';
import ReactPixel from 'react-facebook-pixel';

class Signup extends Component {
  static contextType = AuthContext;

    constructor(props) {
      super(props);

      const state = new URLSearchParams(props.location.search).get('state');
      if(state !== 'acceptinvite') {
        localStorage.removeItem('crewmemberInviteState');
      } else {
        let crewmemberInvite = localStorage.getItem('crewmemberInviteState');
        let crewmemberInviteEmail = localStorage.getItem('crewmemberInviteStateEmail');
        if(crewmemberInvite) {
          try {
            crewmemberInvite = JSON.parse(crewmemberInvite);

            let fields = [...this.state.fields];
            let field = fields.find(x => x.name === 'email');

            if(field) {
              field.value = decodeURIComponent(crewmemberInvite.email);
              field.additionalMessage = 'Enter the same email used to send you the invite to Equappment. Then create your own log in password.';
              this.validate(field);

              this.setState({ fields: fields });
            }           
          } catch(err) {
            console.log(err);
            crewmemberInvite = null;
          }
        }         
      }
    }

    state = {
      fields: [
        {
          name: 'email',
          value: '',
          defaultValue: '',
          type: 'email',
          label: 'Email',
          isValid: true,
          validationMessage: '',
          isTouched: false
        },
        {
          name: 'password',
          value: '',
          defaultValue: '',
          type: 'password',
          label: 'Password',
          isValid: true,
          validationMessage: '',
          isTouched: false,
          haveShowPasswordIcon: true,
          showPassword: false
        },
        {
          name: 'passwordConfirm',
          value: '',
          defaultValue: '',
          type: 'password',
          label: 'Confirm Password',
          isValid: true,
          validationMessage: '',
          isTouched: false,
          haveShowPasswordIcon: true,
          showPassword: false
        },
      ],
      isValid: false,
      submitError: false,
      submitErrorMessage: '',
      loading: false,
      accountCreated: false
    };

    componentDidMount = () => {
      ReactPixel.pageView();
    };

    handleSubmit = async event => {
        event.preventDefault();
        if(!this.state.isValid)
          return;

        const email = this.state.fields.find(x => x.name === 'email');
        const password = this.state.fields.find(x => x.name === 'password');

        this.setState({ loading: true });

        try {
            await app.auth()
                     .createUserWithEmailAndPassword(email.value, password.value);

            this.setState({
              submitError: false,
              submitErrorMessage: ''
            });
        } catch(error) {
            if(error.code === 'auth/email-already-in-use') {
              this.setState({
                submitError: true,
                submitErrorMessage: getMessages('email_taken')
              });
            } else {
              this.setState({
                submitError: true,
                submitErrorMessage: getMessages('something_wrong')
              });
            }

            this.setState({ loading: false });
        }
    }

    clearValues() {
      let updatedFields = [...this.state.fields];
      updatedFields.forEach(element => {
        element.value = element.defaultValue;
      });

      this.setState({ fields: updatedFields });
    }

    handleChange = (event) => {
        let fields = [...this.state.fields];
        let field = fields.find(x => x.name === event.target.name);

        if(!field)
          return;

        field.value = event.target.value;
        this.validate(field);

        if(!field.value)
          field.isTouched = false;

        const globalValid = this.getGlobalValid(fields);
        this.setState({fields: fields, isValid: globalValid});
    }

    signInWithGoogle = async (event) => {
      const googleProvider = new firebase.auth.GoogleAuthProvider();
      try {
        var userCred = await app
          .auth()
          .signInWithPopup(googleProvider)
          return userCred;
      } catch (error) {
        console.log(error);
      }
    };
  
    signInWithApple = async (event) => {
      const appleProvider = new firebase.auth.OAuthProvider("apple.com");
      try {
        var userCred = await app
          .auth()
          .signInWithPopup(appleProvider)
          return userCred;
      } catch (error) {
        console.log(error);
      }
    };

    setAccountCreated = () => {
      this.setState({ accountCreated: true });
    }

    validate = (field) => {
      if(field.name === 'email') {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(String(field.value).toLowerCase())) {
          field.isValid = true;
          field.validationMessage = '';
        } else {
          field.isValid = false;
          field.validationMessage = getMessages('invalid_email')
        }
      }

      if(field.name === 'password') {
        let isValid = true;
        if (field.value.search(/[a-z]/) < 0) {
          isValid = false;
          field.validationMessage = getMessages('pass_lowercase');
        } else if (field.value.search(/[A-Z]/) < 0) {
          isValid = false;
          field.validationMessage = getMessages('pass_uppercase');
        } else if (field.value.search(/[0-9]/) < 0) {
          isValid = false;
          field.validationMessage = getMessages('pass_digit');
        } else if(field.value.length < 8) {
          isValid = false;
          field.validationMessage = getMessages('pass_characters');
        }

        field.isValid = isValid;
      }

      if(field.name === 'passwordConfirm') {
        const password = this.state.fields.find(x => x.name === 'password');
        if (field.value === password.value) {
          field.isValid = true;
          field.validationMessage = '';
        } else {
          field.isValid = false;
          field.validationMessage = getMessages('pass_nomatch');
        }
      }
    }

    updateUser = (user) => {
      const updateCurrentUser = this.context.updateCurrentUser;
      updateCurrentUser(user);
    }

    getGlobalValid = (fields) => {
      let isValid = true;
      fields.forEach(element => {
        if(!element.value || !element.isValid) isValid = false;
      });

      return isValid;
    }
 
    touchField = (field) => {
      let fields = this.state.fields;
      let updatedField = fields.find(x => x.name === field.name);
      if(!updatedField)
        return;

      updatedField.isTouched = true;
      this.setState({ fields: fields });
    }

    addValidationClass(field, cl) {
      if(field.isValid && field.value) return cl + ' is-valid';
      if(!field.isValid  && field.value) return cl + ' is-invalid';

      return cl;
    }

    gnerateInputId(field) {
      return "ca-input-" + field.name;
    }

    toggleShowPassword = (field) => {
      let fields = this.state.fields;
      let updatedField = fields.find(x => x.name === field.name);
      if(!updatedField)
        return;

      updatedField.showPassword = !updatedField.showPassword;
      updatedField.type = updatedField.showPassword ? 'text' : 'password';

      this.setState({ fields: fields });
    }

    render() {
      if(this.state.accountCreated) {
        return <WelcomeScreen />
      }

      const currentUser = this.context.currentUser;
      if(currentUser && !currentUser.userProfile) {
        return <AccountDetails currentUser={currentUser}
          updateUser={this.updateUser}
          updateMessageState={this.context.updateMessageState}
          setShowWelcomeScreen={this.context.setShowWelcomeScreen}
          setAccountCreated={this.setAccountCreated} />
      }

      const submitBtnClasses = 'btn btn-block btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3 rounded-0';
      const submitBtnClassesWithError = this.state.submitError ? submitBtnClasses + ' is-invalid' : submitBtnClasses;
      const submitBtnClassesFinal = this.state.loading ? submitBtnClassesWithError + ' spinner spinner-white spinner-right' : submitBtnClassesWithError;

      return (
        <div className="d-flex flex-column flex-root">
          <div className="login login-4 wizard d-flex flex-column flex-lg-row flex-column-fluid">
          
              <div className="login-content d-flex flex-column pt-md-32 pt-12">
                <div className="pb-5 pb-lg-15">
                  <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h2-lg">Try equappment for free.</h3>
                  <div className="font-weight-bold font-size-h4">Get started in minutes, no credit card required.</div>
                  <div className="text-muted font-weight-bold font-size-h6">No-risk, free trial is fully functional with unlimited management of equipment, jobs, crewmembers and much more. Invite you entire team to try Equappment. It's all included.</div>
                </div>
                <div className="row mb-10">
                    <div className="col-lg-6">
                      <button onClick={this.signInWithGoogle} className="btn btn-block btn-light-dark font-weight-bold px-8 py-4 my-3 rounded-0">
                          <i className="socicon-google"></i> Sign in with Google
                      </button>
                    </div>
                    <div className="col-lg-6">
                      <button onClick={this.signInWithApple} className="btn btn-block btn-outline-dark font-weight-bold px-8 py-4 my-3  rounded-0">
                          <i className="socicon-apple"></i> Sign in with Apple
                      </button>
                    </div>
                  </div>
                <div className="login-form">
                  <form onSubmit={this.handleSubmit} className="form" id="kt_login_singin_form" noValidate="novalidate" action="POST">
                    {this.state.fields.map((item, index) => {
                      const field = 
                        <div key={index} className="form-group">
                          <label className="font-size-h6 font-weight-bolder text-dark">{item.label}<span className="text-danger"> *</span></label>
                          <div className="input-group rounded-0">
                            <input
                                aria-describedby="basic-addon2"
                                autoFocus={index === 0}
                                id={this.gnerateInputId(item)}
                                key={index}
                                value={item.value} 
                                className={this.addValidationClass(item, "form-control form-control-solid input-h-over py-7 px-6 rounded-0 border-0")}
                                type={item.type} 
                                name={item.name}
                                onChange={this.handleChange} 
                                onBlur={() => { this.touchField(item) }} />
                              {/* {item.haveShowPasswordIcon && item.value ?
                              <div className="input-group-append">
																<span className="input-group-text border-0">
																	<i onClick={() => this.toggleShowPassword(item)} 
                                  className={item.showPassword ? "la la-eye-slash icon-lg pointer" : "la la-eye icon-lg pointer"}></i>
																</span>
															</div> : null} */}                              
                              <div className="invalid-feedback">{item.validationMessage}</div>
                          </div>
                          {item.additionalMessage ?
                                <span className="form-text text-muted">{item.additionalMessage}</span> : null}                          
                        </div>

                      return field;
                    })}
                      <div className="row">
                        <div className="col-lg-12">
                          <button 
                            disabled={!this.state.isValid || this.state.loading} 
                            type="submit" 
                            id="kt_login_singin_form_submit_button" 
                            className={submitBtnClassesFinal}>Create account</button>
                          <div className="invalid-feedback justify-content-center">{this.state.submitErrorMessage}</div>
                        </div>
                      </div>
                  </form>
                </div>
            </div>
          </div>
        </div>
      );
  }
}

export default withRouter(Signup);