import { db, storage } from "../../firebase";
import { FileEntity, FileEntityDto, toFileEntity } from "../data/folder/FolderStructure"
import { ResponseEntity } from "../data/Response";

/**
 * This method will fetch all file objects from database
 * @param fileEntityPath Path to file collection in firebase
 * @param baseFilePath First part of path on firebase storage it has to be in pattern {companyId}/{collectionName}/{documentId}
 * @returns list of FileEntity
 */
export const getFileData = async (fileEntityPath: string, baseFilePath: string) => {
    try {
        const result = await db.collection(fileEntityPath).get()
        const fileEntitiesDto = result.docs.map((doc: any) => {
            return { ...doc.data(), id: doc.id }
        })

        const fileEntities: FileEntity[] = fileEntitiesDto.map((file: any) => ({ ...toFileEntity(file, file.id) }))

        const files: FileEntity[] = await Promise.all(fileEntities.map(async (file) => {
            if (!file.url) {
                const filePath = `${baseFilePath}/${file.createdAt?.seconds}/${file.fileName ?? "med"}`;
                const fallbackFilePath = `${baseFilePath}/${file.createdAt?.seconds}/med`;
                const thirdFallbackFilePath = `${baseFilePath}/${file.fileName ?? "med"}`;

                try {

                    // Try to get the download URL for the specific file
                    file.url = await storage.ref(filePath).getDownloadURL();
                } catch (error) {
                    console.warn(`Failed to get URL for: ${filePath}. Attempting fallback path...`);

                    try {
                        // Fallback to a default "med" file if the fileName is missing
                        file.url = await storage.ref(fallbackFilePath).getDownloadURL();
                    } catch (fallbackError) {
                        // console.error(`Failed to get URL for fallback path: ${fallbackFilePath}`, fallbackError);
                        try {
                            // Fallback to a default "med" file if the fileName is missing
                            file.url = await storage.ref(thirdFallbackFilePath).getDownloadURL();
                        } catch (error) {
                            console.error(`Failed to get URL for fallback path: ${thirdFallbackFilePath}`, thirdFallbackFilePath);
                            // Handle the final failure, either set a default URL or leave it as undefined
                            file.url = null;
                        }
                    }
                }
            }
            return file
        }))

        return new ResponseEntity<FileEntity[]>(files, "")
    } catch (error: any) {
        console.error(error)
        return new ResponseEntity<FileEntity[]>(null, error)
    }
}

/**
 * This method saves file upload object into collection path provided in parameter.
 * @param fileEntity file upload object
 * @param fileCollectionPath path to file collection where file object should be saved
 * @param fileDocumentId if provided existing document will be updated
 * @returns file upload object
 */
export const saveFileEntity = async (fileEntity: FileEntityDto, collectionPath: string, fileDocumentId?: string | null) => {
    try {
        var documentId = fileDocumentId
        const docRef = db.collection(collectionPath)
        if (fileDocumentId) {
            await docRef.doc(fileDocumentId).set(fileEntity)
        } else {
            const result = await docRef.add(fileEntity)
            documentId = result.id
        }
        return new ResponseEntity<FileEntityDto>(fileEntity, "", documentId)
    } catch (error: any) {
        console.error(`File upload error ${error}`)
        return new ResponseEntity<FileEntityDto>(fileEntity, error)
    }
}


/**
 * This method saves file in firebase storage and than saves FileEntityDto object into
 * appropriate collection whose path is set in fileEntityPath. This method is also used
 * for deleting entity and in that case fileDocumentId has to be set together with
 * file upload state "delete"
 * @param fileUpload file upload object
 * @returns ResponseEntity<FileUploadEntity>
 */
export const saveFileData = async (fileUpload: FileUploadEntity) => {
    try {
        //first we upload/delete file
        const result = await uploadFile(fileUpload)
        //than we add/delete FileEntityDto object into/from appropriate collection
        var documentId = null
        switch (fileUpload.fileUploadState) {
            case "upload": {
                if (result.data) {
                    const documentResult = await db.collection(fileUpload.fileEntityPath).add(result?.data?.fileEntityDto)
                    documentId = documentResult.id
                }
                break
            }
            case "delete": {
                if (fileUpload.fileDocumentId) {
                    await db.collection(fileUpload.fileEntityPath).doc(fileUpload.fileDocumentId).delete()
                }
                break
            }
        }

        fileUpload.fileDocumentId = documentId
        return new ResponseEntity<FileUploadEntity>(fileUpload, "", documentId)
    } catch (error: any) {
        console.error(`File upload error ${error}`)
        return new ResponseEntity<FileUploadEntity>(fileUpload, error)
    }
}

/**
 * This method uploads file to firebase storage
 * @param fileUpload file upload object
 * @returns returns same object with file url set to FileEntityDto object
 */
export const uploadFile = async (fileUpload: FileUploadEntity) => {

    try {
        var fileName = "med"

        var fileUrl = ""
        switch (fileUpload.fileUploadState) {
            case "upload": {

                if (!fileUpload.file) throw new Error("File data is not set while uploading file")

                // if (!isImageFile(fileUpload.file.name)) {
                fileName = fileUpload.file.name ?? "med"
                // }
                const storageRef = storage.ref(fileUpload.fileUploadPath + "/" + fileName);

                await storageRef.put(fileUpload.file)
                fileUrl = await storageRef.getDownloadURL()
                break;
            }
            case "delete": {
                const storageRef = storage.ref(fileUpload.fileUploadPath + "/" + fileUpload.fileEntityDto.fileName)
                const storageRefMed = storage.ref(fileUpload.fileUploadPath + "/med")
                //First try to delete path which ends with file name, if that fails we try to delete files that end with file name "med". Trying to delete "med" files is to keep backward compatibility for old implementation.
                try {
                    await storageRef.delete()
                } catch (error) {
                    await storageRefMed.delete()
                }
                break
            }
        }
        //after file is uploaded we set file url to file entity dto
        fileUpload.fileEntityDto.url = fileUrl
        return new ResponseEntity<FileUploadEntity>(fileUpload, "")
    } catch (error: any) {
        console.error(error)
        return new ResponseEntity<FileUploadEntity>(null, error)
    }

}

export const isImageFile = (fileName: string) => {
    const imageExtensionRegex = /\.(jpg|jpeg|png|gif|bmp|svg|webp)$/i;
    return imageExtensionRegex.test(fileName)
}

export interface FileUploadEntity {
    fileUploadPath: string,
    file?: File | null,
    fileUploadState: FileUploadState,
    fileEntityPath: string,
    fileEntityDto: FileEntityDto,
    fileDocumentId?: string | null
}

type FileUploadState = "upload" | "delete"