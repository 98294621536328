import React, { useContext, useState } from "react";
import {
  JobEntity,
  JobStatus,
  JobStatusEntity,
} from "../../../shared/data/job/Job";
import EQInputText from "../../../components/Form/Inputs/EQInputText";
import { getMessages } from "../../../components/Messages/GetMessages";
import EQDateRangePicker from "../../../components/Form/Inputs/EQDateRangePicker";
import { parseFromFirebaseDate, toFirebaseDate } from "../../../shared/Util";
import { Timestamp } from "firebase/firestore";
import JobStatusComponent from "./JobStatusComponent";
import { updateJob } from "../../../shared/Api";
import { ContextEntity } from "../../../shared/data/context/ContextEntity";
import { AuthContext } from "../../../shared/Auth";
import { saveJobStatus } from "../../../shared/api/JobApi";

interface JobDetailsEditProps {
  job: JobEntity;
  jobStatus?: JobStatusEntity;
  onSaveComplete: () => void;
  onCancel: ()=> void;
}

const JobDetailsEdit = (props: JobDetailsEditProps) => {
  const context: ContextEntity = useContext(AuthContext);

  const [formState, setFormState] = useState({
    saving: false,
  });
  const [jobState, setJobState] = useState<JobEntity>(props.job);
  const [jobStatusState, setJobStatusState] = useState<JobStatusEntity>(
    props.jobStatus ?? {
      jobStatus: JobStatus.published,
      createdAt: Timestamp.now(),
      userID: context.currentUser?.uid,
      userName: context.currentUser?.displayName,
    }
  );

  const save = async () => {
    setFormState({ ...formState, saving: true });
    const result = await updateJob(
      context.currentUser?.company?.inventoryID,
      jobState.id,
      {...jobState, status: jobStatusState.jobStatus}
    );

    await saveStatus();

    setFormState({ ...formState, saving: false });
    props.onSaveComplete();
  };

  const saveStatus = async () => {
    var jobStatus = {
      ...jobStatusState,
      userID: context?.currentUser?.uid ?? "",
      userName: context?.currentUser?.displayName ?? "",
    };

    if (props.jobStatus?.jobStatus == jobStatusState.jobStatus) {
      jobStatus = {
        ...jobStatus,
        documentID: props.jobStatus?.documentID,
      };
    }

    await saveJobStatus(
      context.currentUser?.company?.inventoryID ?? "",
      jobState.id ?? "",
      jobStatus
    );
  };

  return (
    <React.Fragment>
      <div>
        <EQInputText
          title="Job name"
          inputText={jobState?.title ?? ""}
          required={true}
          hint={getMessages("job_name")}
          onOnInputChange={(input) => {
            setJobState({ ...jobState, title: input.toString() });
          }}
        />
        <EQInputText
          title="Job ID"
          inputText={jobState?.jobID ?? ""}
          required={true}
          hint={getMessages("job_id")}
          onOnInputChange={(input) => {
            setJobState({ ...jobState, jobID: input.toString() });
          }}
        />
        <EQDateRangePicker
          id="job_details_date"
          title="Dates"
          hint={getMessages("job_started")}
          startDate={parseFromFirebaseDate(jobState?.startDate)}
          endDate={parseFromFirebaseDate(jobState?.endDate)}
          handleChange={(range) => {
            const startDate = toFirebaseDate(range.startDate.toDate());
            const endDate = toFirebaseDate(range.endDate.toDate());
            setJobState({
              ...jobState,
              startDate: startDate,
              endDate: endDate,
            });
          }}
        />
        <EQInputText
          title="Description (optional)"
          inputText={jobState?.description ?? ""}
          numberOfLines={4}
          hint={getMessages("job_description")}
          onOnInputChange={(input) => {
            setJobState({ ...jobState, description: input.toString() });
          }}
        />
        <JobStatusComponent
          jobStatus={jobStatusState}
          isJobCreation={false}
          onJobStatusChanged={(jobStatus) => {
            setJobStatusState(jobStatus);
          }}
        />
        <div className="justify-content-between border-top mt-5 pt-10">
          <button
            key="segment-new-btn-save"
            className={
              formState.saving
                ? "btn btn-primary font-weight-bold mr-4 py-4 spinner spinner-white spinner-right"
                : "btn btn-primary font-weight-bold mr-4 py-4 px-9"
            }
            // disabled={!isFormValid() || formState.saving}
            onClick={(event) => {
              event.preventDefault();
              save();
            }}
          >
            Save
          </button>
          <button
            onClick={() => {
              props.onCancel()
            }}
            className="btn btn-secondary font-weight-bold text-uppercase px-7 py-4"
          >
            Cancel
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default JobDetailsEdit;
