import React from 'react';
import { isNumeric } from '../../../shared/Util';

const NUMERIC_REGEXP = /[-]{0,1}[\d]*[.]{0,1}[\d]+/g;

function preventNonNumericalInput(e) {
    e = e || window.event;
    var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
    var charStr = String.fromCharCode(charCode);

    var str = e.target.value + charStr;

    if (str !== "" && str !== "-" && !isNumeric(str))
        e.preventDefault();
}

const NumberInput = (props) => {
    return (
        <input
            type="number"
            inputMode='decimal'
            className={props.className}
            autoFocus={props.index === 0}
            value={props.field.value}
            name={props.field.name}
            onChange={props.handleChange}
            onBlur={() => { props.touchField(props.field) }}
            id={props.id}
            min={props.field.min}
            max={props.field.max}
            pattern="[0-9]*"
            onKeyPress={(event) => preventNonNumericalInput(event)}
        />
    );
}

export default NumberInput;