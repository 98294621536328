import React, { useContext, useEffect, useState } from "react";
import { getAllCrewMembers, getJobAssignedCrewMembers } from "../../../shared/api/CrewApi";
import { AuthContext } from "../../../shared/Auth";
import { useHistory, useParams } from "react-router-dom";
import DailyCheckInComponent from "./DailyCheckInComponent";
import DailyCheckInWorkTimeComponent from "./DailyCheckInWorkTimeComponent";
import WorkTimeEntity, {
  DailyProductionCheckInEntity,
} from "../../../shared/data/job/Job";
import DailyCheckInExternal from "./DailyCheckInExternal";
import { functions } from "../../../firebase";
import { showToast } from "../../../shared/Util";
import ReportComponent, { ReportRecipient } from "../ReportComponent";
import { CrewMemberEntity } from "../../../shared/data/Crew";
import {
  getJobReportCrewMembers,
  saveJobReportCrewMembers,
} from "../../../shared/api/JobApi";
import { getTimezoneOffset } from "../../../shared/Utils";

const DailyCheckIn = () => {
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const params: { id: string } = useParams();

  const [dailyReportState, setDailyReportState] =
    useState<DailyProductionCheckInEntity | null>(
      new DailyProductionCheckInEntity()
    );

  const [selectedWorkTime, setSelectedWorkTime] =
    useState<WorkTimeEntity | null>(null);

  const [showExternalWorkTimes, setShowExternalWorkTimes] = useState(false);

  const [showRecipientsModal, setShowRecipientsModal] = useState(false);

  const [sending, setSending] = useState(false);

  const [crewMembers, setCrewMembers] = useState<CrewMemberEntity[]>();

  const [externalWorkTimes, setExternalWorkTimes] = useState<
    WorkTimeEntity[] | null
  >(null);

  const updateDailyReport = (data: DailyProductionCheckInEntity) => {
    setDailyReportState(data);
  };

  const saveExternalCrewMember = async (data?: WorkTimeEntity | null) => {
    const inventoryId = authContext.currentUser.company.inventoryID;
    const crewMember = new CrewMemberEntity();
    crewMember.firstName = data?.firstName;
    crewMember.lastName = data?.lastName;
    crewMember.email = data?.email;
    await saveJobReportCrewMembers(inventoryId, params.id, crewMember)
      .then((result) => {})
      .catch((err) => {
        console.error("Error saving crew member", err);
      });
    getExternalWorkTimes();
  };

  const updateWorkTime = (
    data?: WorkTimeEntity | null,
    saveCrewMember: boolean = false
  ) => {
    if (saveCrewMember) saveExternalCrewMember(data);
    setSelectedWorkTime(null);
    if (!data) return;
    setDailyReportState((previousState) => {
      const workTimes: WorkTimeEntity[] = [];
      //if members is already in the list we update it else we add new one  to the list(which is considered new external member).
      if (
        previousState?.workTimes.find(
          (workTime) => workTime.email == data.email
        )
      ) {
        const updatedWorkTimes =
          previousState?.workTimes?.map((workTime) => {
            if (workTime.email == data.email) return data;
            else return workTime;
          }) ?? [];
        workTimes.push(...updatedWorkTimes);
      } else {
        workTimes.push(...(previousState?.workTimes ?? []), data);
      }
      return {
        ...previousState,
        recipients: previousState?.recipients ?? [],
        workTimes: workTimes,
        safetyTalk: "Yes",
        saveFile: previousState?.saveFile ?? true,
        pdfExcel: previousState?.pdfExcel ?? 0,
        timezone: getTimezoneOffset(),
      };
    });
  };

  //fetching crewmembers which are added through report and are not actual crewmembers
  const getExternalWorkTimes = async () => {
    const inventoryId = authContext.currentUser.company.inventoryID;
    const result = await getJobReportCrewMembers(inventoryId, params.id);
    const workTimes =
      result?.data?.map((crewMember) => {
        const workTime = new WorkTimeEntity(
          crewMember.firstName ?? "Unknown",
          crewMember.lastName ?? "Unknown"
        );
        workTime.email = crewMember.email;
        workTime.phoneNumber = crewMember.phoneNumber;
        workTime.startTime = dailyReportState?.startTime;
        workTime.endTime = dailyReportState?.endTime;
        return workTime;
      }) ?? [];
    setExternalWorkTimes(workTimes);
  };

  const getJobCrewMembers = async () => {
    const inventoryId = authContext.currentUser.company.inventoryID;
    const currentUserEmail = authContext.currentUser.email
    const jobId = params.id;
    const allCrewMembersResult = (await getAllCrewMembers(inventoryId)).data?.filter((crewMember)=>crewMember.email!==currentUserEmail)
    const result = await getJobAssignedCrewMembers(inventoryId, jobId);
    setCrewMembers(allCrewMembersResult ?? []);
    if (!result.message) {
      setDailyReportState((previousState) => {
        const workTimes: WorkTimeEntity[] =
          result.data?.map((crewMember) => {
            var workTime = new WorkTimeEntity(
              crewMember.firstName ?? "Undefined",
              crewMember.lastName ?? "Undefined"
            );
            workTime.email = crewMember.email;
            workTime.phoneNumber = crewMember.phoneNumber;
            return workTime;
          }) ?? [];
        if (previousState) return { ...previousState, workTimes: workTimes };
        else
          var report: DailyProductionCheckInEntity = {
            saveFile: true,
            workTimes: workTimes,
            recipients: [],
            safetyTalk: "Yes",
            pdfExcel: 0,
            timezone: getTimezoneOffset(),
          };
        return report;
      });
    }
  };

  useEffect(() => {
    getJobCrewMembers();
  }, [params]);

  useEffect(() => {
    if (dailyReportState?.startTime && dailyReportState.endTime) {
      getExternalWorkTimes();
    }
  }, [dailyReportState?.startTime, dailyReportState?.endTime]);

  useEffect(() => {
    const workTimes =
      dailyReportState?.workTimes.map((workTime) => {
        workTime.startTime = dailyReportState.startTime;
        workTime.endTime = dailyReportState.endTime;
        return workTime;
      }) ?? [];

    setDailyReportState((previousTime) => {
      return { ...previousTime!, workTimes: workTimes };
    });
    setSelectedWorkTime(null);
    setShowExternalWorkTimes(false);
  }, [
    dailyReportState?.day,
    dailyReportState?.startTime,
    dailyReportState?.endTime,
  ]);

  const getDialogItems = () => {
    const user = authContext.currentUser;

    const crewMemberItems: ReportRecipient[] =
      crewMembers
        ?.filter((crewMember) => crewMember.id != null)
        .map((crewMember) => {
          return new ReportRecipient(
            crewMember.id ?? "",
            `${crewMember.firstName} ${crewMember.lastName}`,
            crewMember.email ?? "Unknown email"
          );
        }) ?? [];

    const items: ReportRecipient[] = [
      new ReportRecipient(
        user.uid,
        user.displayName ?? "Unknown",
        user.email,
        true,
        true
      ),
    ].concat(crewMemberItems);

    return items;
  };

  const sendDailyCheckingReport = async (emailsToSend: string[]) => {
    setSending(true);
    if (!sending) {
      const report = { ...dailyReportState };
      report.jobID = params.id;
      report.userID = authContext.currentUser.uid;
      report.recipients = emailsToSend;

      const sendJobReportFunc = functions.httpsCallable("sendJobCheckInReport");
      await sendJobReportFunc(report)
        .then((result) => {
          showToast("success", "Report has been sent successfully.");
          history.goBack();
        })
        .catch((exception: any) => {
          showToast("danger", "Error sending report.");
        });
      setSending(false);
    }
  };

  return (
    <React.Fragment>
      <div
        className={`${
          authContext.isMobile
            ? "flex-column flex-column-fluid align-item-center"
            : "flex-row flex-row-fluid"
        } d-flex`}
      >
        {dailyReportState && (
          <DailyCheckInComponent
            dailyReport={dailyReportState}
            sending={sending}
            onReportChanged={updateDailyReport}
            onWorkTimeSelected={(selectedWorkTime) => {
              const secondDiv = document
                .getElementById("daily-check-in-second-modal")?.offsetTop??0
                window.scrollTo(0, (secondDiv-150))
              setSelectedWorkTime(selectedWorkTime);
              setShowExternalWorkTimes(false);
            }}
            onConfirm={() => {
              setShowRecipientsModal(true);
            }}
            onAddExternalWorkTime={() => {
              const secondDiv = document
              .getElementById("daily-check-in-second-modal")?.offsetTop??0
              window.scrollTo(0, (secondDiv-150))
              setShowExternalWorkTimes(true);
              setSelectedWorkTime(null);
            }}
          />
        )}
        <div
          id="daily-check-in-second-modal"
          className={`${authContext.isMobile ? "" : "ml-10"}`}
        >
          {showExternalWorkTimes && (
            <DailyCheckInExternal
              externalWorkTimes={externalWorkTimes ?? []}
              startTime={dailyReportState?.startTime ?? ""}
              endTime={dailyReportState?.endTime ?? ""}
              onSelect={(data) => {
                setShowExternalWorkTimes(false);
                setSelectedWorkTime(data);
              }}
            />
          )}
          {selectedWorkTime && (
            <DailyCheckInWorkTimeComponent
              workTime={selectedWorkTime}
              onSaveChanges={updateWorkTime}
            />
          )}
        </div>
      </div>
      <ReportComponent
        id="daily-check-in-report"
        title="Daily production check-in"
        showDialog={showRecipientsModal}
        reportRecipients={getDialogItems()}
        onDismiss={() => {
          setShowRecipientsModal(false);
        }}
        sendReport={(recipients) => {
          setShowRecipientsModal(false);
          setSending(true);
          sendDailyCheckingReport(
            recipients.map((recipients) => recipients.email)
          );
        }}
      />
    </React.Fragment>
  );
};

export default DailyCheckIn;
