import { number, string } from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import CheckOutInItem from "../../../../components/Layout/CheckOutInItem/CheckOutInItem";
import { fromRGBValue } from "../../../../shared/Util";
import { SegmentEntity } from "../../../../shared/data/taskmanagement/Segment";
import { CrewEntity } from "../../../../shared/data/Crew";
import { TaskEntity } from "../../../../shared/data/taskmanagement/Task";
import { JobEntity } from "../../../../shared/data/job/Job";
import "./Segment.css"

interface SegmentProps {
  job?: JobEntity;
  segment?: SegmentEntity;
  segmentTasks?: TaskEntity[];
  allCrews?: CrewEntity[];
  allTaskCrews: String[];
  canEdit: boolean;
  onSegmentDeleted?: (segment: SegmentEntity) => void;
}

export const SegmentItemComponent = ({
  job,
  segment,
  segmentTasks,
  allCrews,
  allTaskCrews,
  canEdit = false,
  onSegmentDeleted = () => {},
}: SegmentProps) => {
  getAllCrews()

  const numberOfTasks = segmentTasks?.length;

  const completedTasks = segmentTasks?.filter(
    (item) => item.taskStatus == "closed"
  ).length;

  const estimatedTime = 0;

  function getPercentage(): number {
    if (numberOfTasks || completedTasks || numberOfTasks != 0)
      return ((completedTasks ?? 1) / (numberOfTasks ?? 0)) * 100;
    else return 0;
  }

  function findCrewByCrewId(crewID : String) {
    return allCrews?.find((crew) => {
      return crew.id === crewID;
    });
  }

  function getAllCrews() {
    allTaskCrews = [];
    segmentTasks?.forEach((tsk) => {
      tsk.crews?.forEach(crw => {
        if (!allTaskCrews.includes(crw)) {
          allTaskCrews.push(crw);
        }
      });
    });
  }

  return (
    <React.Fragment key={`brs-itm-${segment?.segmentID}`}>
      <div className="d-flex d-flex-row align-items-center mb-1 pl-5 pt-5 segment-status-open">
        <Link
          to={{
            pathname: `/jobs/${segment?.jobID}/segments/${segment?.id}`,
            state: {
              segment: segment,
              segmentTasks: segmentTasks,
              job: job,
            },
          }}
          href="#"
          className="d-flex d-flex-row flex-grow-1 align-items-center"
        >
          <div className="flex-grow-1">
            <h5 className="font-weight-bold text-dark">{segment?.title}</h5>
            <h5 className="font-weight-bold text-dark">
              ID: #{segment?.segmentID}
            </h5>
            <h6 className="text-dark-65 font-weight-bold">{`Estimated time: ${segmentTasks
              ?.reduce(
                (total, task) =>
                  (total = total + (task.estimatedDuration ?? 0)),
                0
              )
              ?.toFixed(1)}h`}</h6>
            <h6 className="text-dark-65 font-weight-bold">
              {`Progress: ${completedTasks} of ${numberOfTasks} Tasks complete`}
            </h6>
            <div>
              {allTaskCrews.map((crew, index) => {
                const crw = findCrewByCrewId(crew);
                if (crw != null) {
                  return (
                    <div style={{ position: "relative", display: "flex" }}>
                      <span
                        className="dot"
                        style={{
                          backgroundColor:
                            "rgba(" +
                            fromRGBValue(crw.red) +
                            ", " +
                            fromRGBValue(crw.green) +
                            ", " +
                            fromRGBValue(crw.blue) +
                            ", " +
                            crw.alpha +
                            ")",
                        }}
                      ></span>
                      <h6 className="text-dark-65 font-weight-bold ml-2">
                        {crw.crewName}
                      </h6>
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <div className="d-flex flex-column align-items-center">
            <CheckOutInItem
              id={Math.random() * 1000}
              percentage={getPercentage().toFixed(1)}
            />
          </div>
        </Link>
        <div
          className="dropdown dropdown-inline ml-2"
          data-toggle="tooltip"
          title="Quick actions"
          data-placement="left"
        >
          <a
            className="btn btn-hover-light-primary btn-sm btn-icon"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="ki ki-bold-more-hor" />
          </a>
          <div className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right">
            <ul className="navi navi-hover">
              <li className="navi-item">
                <Link
                  to={{
                    pathname: `/jobs/${segment?.jobID}/segments/${segment?.id}`,
                    state: {
                      segment: segment,
                      segmentTasks: segmentTasks,
                      job: job
                    },
                  }}
                  href="#"
                  className="navi-link"
                >
                  <span className="navi-text">View Details</span>
                </Link>
              </li>
              {canEdit && (
                <li className="navi-item">
                  <Link
                    to={{
                      pathname: "/jobs/" + segment?.jobID + "/segments/edit",
                      state: {
                        startDate: job?.startDate,
                        endDate: job?.endDate,
                        segment: segment,
                      },
                    }}
                    href="#"
                    className="navi-link"
                  >
                    <span className="navi-text">Edit Segment</span>
                  </Link>
                </li>
              )}
              {canEdit && (
                <li className="navi-item">
                  <a
                    href="#"
                    onClick={() => {
                      if (segment) onSegmentDeleted(segment);
                    }}
                    className="navi-link"
                  >
                    <span className="navi-text">Delete Segment</span>
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SegmentItemComponent;
