import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import {
  JobEntity,
  JobStatus,
  JobStatusLabels,
} from "../../shared/data/job/Job";
import EQMarker from "../../components/GoogleMap/EQMarker";
import { parseFromFirebaseDate } from "../../shared/Util";
import { useHistory } from "react-router-dom";
import EQSelect from "../../components/Form/Inputs/EQSelect";
import EQDateRangePicker from "../../components/Form/Inputs/EQDateRangePicker";
import moment from "moment";
import { isBetweenDates, isTimeSpanWithin } from "../../shared/Utils";

interface JobsMapProps {
  jobs: JobEntity[];
}

const JobsMap = (props: JobsMapProps) => {
  const history = useHistory();
  const [mapState, setMapState] = useState({
    zoom: 10,
    center: {
      lat: 37.42216,
      lng: -122.08427,
    },
    draggable: true,
    editable: false,
    jobs: props.jobs,
    clickedLat: null,
    clickedLng: null,
    googleApiLoaded: false,
  });

  const [filterState, setFilterState] = useState({
    status: { key: "", value: "" },
    crew: { key: "", value: "" },
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    let filteredJobs = props.jobs;

    // Filter by crew if a crew is selected.
    if (filterState.crew && filterState.crew.key !== "") {
      filteredJobs = filteredJobs.filter((job) =>
        job.crews?.some((crew) => crew?.id === filterState.crew.key)
      );
    }

    // Filter by status if a status is selected.
    if (filterState.status && filterState.status.key !== "") {
      filteredJobs = filteredJobs.filter(
        (job) => job.status === filterState.status.key
      );
    }

    if (filterState.startDate && filterState.endDate) {
      filteredJobs = filteredJobs.filter((job) => {
        const result =
          isBetweenDates(
            moment(filterState.startDate).toDate(),
            moment(filterState.endDate).toDate(),
            job.startDate?.toDate()
          ) ||
          isBetweenDates(
            moment(filterState.startDate).toDate(),
            moment(filterState.endDate).toDate(),
            job.endDate?.toDate()
          ) ||
          isTimeSpanWithin(
            moment(filterState.startDate).toDate(),
            moment(filterState.endDate).toDate(),
            job.startDate?.toDate(),
            job.endDate?.toDate()
          );

        return result;
      });
    }

    // Update the state with the filtered jobs.
    setMapState((prevState) => ({
      ...prevState,
      jobs: filteredJobs,
    }));
  }, [filterState]);

  const showMarkerInfo = (lat?: number | null, lng?: number | null) => {
    return mapState?.clickedLat == lat && mapState?.clickedLng == lng;
  };

  const getAllCrews = () => {
    const mergedCrews = props.jobs.flatMap((job) => job.crews);

    const distinctCrews = Array.from(
      new Map(mergedCrews.map((crew) => [crew?.id ?? "", crew])).values()
    );

    return distinctCrews.map((crew) => {
      return { key: crew?.id ?? "", value: crew?.crewName ?? "" };
    });
  };

  const onMarkerClicked = (location: any) => {
    setMapState((previousState) => {
      return {
        ...previousState,
        clickedLat: location.lat,
        clickedLng: location.lng,
        center: { lat: location.lat, lng: location.lng },
      };
    });
  };

  function fitBounds(map: any, maps: any) {
    const bounds = new maps.LatLngBounds();
    const jobs = props.jobs;
    jobs.forEach((job) => {
      bounds.extend(new maps.LatLng(job.locationLat, job.locationLong));
    });

    if (jobs?.length) map.fitBounds(bounds);
    maps.event.addDomListenerOnce(map, "idle", () => {
      maps.event.addDomListener(map, maps, "resize", () => {
        map.fitBounds(bounds);
      });
    });
  }

  const getJobIcon = (job: JobEntity) => {
    if (job.status == JobStatus.completed) {
      return "assets/media/png/ic_job_completed.png";
    } else if (job.status == JobStatus.draft) {
      return "assets/media/png/ic_job_bid.png";
    } else if (job.status == JobStatus.upcoming) {
      return "assets/media/png/ic_job_upcoming.png";
    } else if (job.status == JobStatus.onHold) {
      return "assets/media/png/ic_job_on_hold.png";
    } else {
      return "assets/media/png/ic_job_in_progress.png";
    }
  };

  const buildInfoText = (job: JobEntity) => {
    return [
      `ID: ${job.jobID}`,
      `Job name: ${job.title}`,
      `Date: ${parseFromFirebaseDate(job.startDate)} - ${parseFromFirebaseDate(
        job.endDate
      )}`,
      `Status: ${JobStatusLabels[job.status ?? JobStatus.published]}`,
    ];
  };

  const getJobStatuses = () => {
    return Object.values(JobStatus).map((status) => {
      return {
        key: status,
        value: JobStatusLabels[status],
      };
    });
  };

  return (
    <React.Fragment>
      <div className="card-body row align-items-start pt-0">
        <div className="col-lg-4 my-2 my-md-0">
          <EQSelect
            title="Crew"
            selectorTitle="Select crew"
            selectedValue={filterState.crew.value}
            items={getAllCrews()}
            onSelectionChanged={(item) => {
              setFilterState((previousState) => {
                return {
                  ...previousState,
                  crew: { key: item?.key ?? "", value: item?.value ?? "" },
                };
              });
            }}
          />
        </div>
        <div className="col-lg-4 my-2 my-md-0">
          <EQDateRangePicker
            id="jobs_map_filter_dates"
            title="Dates"
            startDate={filterState.startDate}
            endDate={filterState.endDate}
            placeholder="Select date range"
            hint={""}
            handleChange={(range) => {
              setFilterState((previousState) => {
                return {
                  ...previousState,
                  startDate: range.startDate.toDate().toString(),
                  endDate: range.endDate.toDate().toString(),
                };
              });
            }}
          />
        </div>
        <div className="col-lg-3 my-2 my-md-0">
          <EQSelect
            title="Status"
            selectorTitle="Select status"
            selectedValue={filterState.status.value}
            items={getJobStatuses()}
            onSelectionChanged={(item) => {
              setFilterState((previousState) => {
                return {
                  ...previousState,
                  status: { key: item?.key ?? "", value: item?.value ?? "" },
                };
              });
            }}
          />
        </div>
        <button
          onClick={() => {
            const resetFilter = { key: "", value: "" };
            setFilterState({
              status: resetFilter,
              crew: resetFilter,
              startDate: "",
              endDate: "",
            });
          }}
          className="btn btn-primary font-weight-bold mt-8"
        >
          Clear
        </button>
      </div>
      <div style={{ height: "50vh", width: "100%" }}>
        <GoogleMapReact
          options={{
            mapTypeControl: true,
            mapTypeControlOptions: {
              style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
            },
            mapTypeId: "satellite",
          }}
          draggable={mapState.draggable}
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAP_KEY ?? "",
          }}
          defaultZoom={mapState.zoom}
          center={mapState.center}
          yesIWantToUseGoogleMapApiInternals={false}
          onGoogleApiLoaded={({ map, maps }) => {
            fitBounds(map, maps);
          }}
        >
          {mapState?.jobs.map((job, index) => {
            return (
              <EQMarker
                key={index}
                lat={job.locationLat}
                lng={job.locationLong}
                icon={getJobIcon(job)}
                infoTexts={buildInfoText(job)}
                onClick={onMarkerClicked}
                show={showMarkerInfo(job?.locationLat, job?.locationLong)}
                onInfoClicked={() => {
                  history.push({
                    pathname: `/jobs/${job.id}/details/`,
                  });
                }}
              />
            );
          })}
        </GoogleMapReact>
      </div>
    </React.Fragment>
  );
};

export default JobsMap;
