import React, { Component } from "react";
import { Link } from "react-router-dom";
import { EquipmentIos } from "../../../components/Icons/Svg";
import Loader from "../../../components/Layout/Loader/Loader";
import JobEquipmentFilter from "./JobEquipmentFilter";
import CustomFilter from "../../Equipment/EquipmentFilters/CustomFilter";
import { getCategories } from "../../../shared/Api";
import { getEquipmentReservations, getEquipmentSchedules } from "../../../shared/api/EquipmentApi";
import { AuthContext } from "../../../shared/Auth";
import { Timestamp } from "firebase/firestore";
import { parseFromFirebaseDate, toFirebaseDate } from "../../../shared/Util";
import { isBetweenDates } from "../../../shared/Utils";
import moment from "moment";

class JobEquipment extends Component {
  static contextType = AuthContext;
  state = {
    filter: {
      search: "",
      availableOn: "",
      byCategory: "",
      bySubCategory: "",
    },
    showCustomFilters: false,
    categories: [
      { value: "Equipment", label: "Equipment" },
      { value: "Tooling", label: "Tooling" },
    ],
    addedEquipment: [],
    otherEquipment: [],
    equipmentReservations: [],
  };

  componentDidMount() {
    this.props.setTabActive("equipment");
    this.loadCategories();
    // this.loadEquipmentReservations();
    this.setState({
      addedEquipment: this.props.addedEquipment,
      otherEquipment: this.props.otherEquipment,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.filter !== this.state.filter || 
      prevProps.addedEquipment !== this.props.addedEquipment || 
      prevProps.otherEquipment !== this.props.otherEquipment ||
      prevState.equipmentReservations !== this.state.equipmentReservations
    ){
      this.applyFilters();
    }
    if(prevState.filter.availableOn !== this.state.filter.availableOn){
      this.loadEquipmentReservations();
    }
  }

  isTodaySelected = () => {
    return this.state.filter.availableOn === new Date().toISOString().split("T")[0];
  }

  getEquipmentFilters = () => {
    return [
      {
        name: "availableToday",
        type: "checkbox",
        display: "Available Today",
        value: this.isTodaySelected(),
        onChange: this.handleFilterChange,
      },
      {
        name: "availableOn",
        type: "datepicker",
        display: "Available On",
        value: this.state.filter.availableOn,
        onChange: this.handleFilterChange,
      },
      {
        name: "byCategory",
        type: "text",
        display: "Category",
        value: this.state.filter.byCategory,
        onChange: this.handleCategorySelect,
      },
      {
        name: "bySubCategory",
        type: "text",
        display: "SubCategory",
        value: this.state.filter.bySubCategory,
        onChange: this.handleSubCategorySelect,
      },
    ];
  };

  loadCategories = async () => {
    const categoryResponse = await getCategories();
    let equipmentSubs = categoryResponse.data[0].subCategories.map((x) => {
      return { label: x.name, value: x.name };
    });
    let toolingSubs = categoryResponse.data[1].subCategories.map((x) => {
      return { label: x.name, value: x.name };
    });

    this.setState({
      equipmentSubs,
      toolingSubs,
      categories: categoryResponse.data,
    });
  };

  loadEquipmentReservations = async () => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    const equipmentReservations = await getEquipmentReservations(
      inventoryId,
      toFirebaseDate(this.state.filter.availableOn),
      toFirebaseDate(this.state.filter.availableOn)
    )

    this.setState({ equipmentReservations: equipmentReservations.data });

  }

  handleSubCategoryOptions = () => {
    if (!this.state.filter.byCategory) {
      return [];
    }
    if (this.state.filter.byCategory?.value === "Equipment")
      return this.state.equipmentSubs;
    if (this.state.filter.byCategory?.value === "Tooling")
      return this.state.toolingSubs;
  };

  handleCategorySelect = (event) => {
    const filters = { ...this.state.filter };
    filters.bySubCategory = null;
    if (!event) {
      filters.byCategory = null;
    } else {
      filters.byCategory = event;
    }
    this.setState({ filter: filters });
  };

  handleSubCategorySelect = (event) => {
    const filters = { ...this.state.filter };
    if (!event) {
      filters.bySubCategory = null;
    } else {
      filters.bySubCategory = event;
    }
    this.setState({ filter: filters });
  };

  handleFilterChange = (event) => {
    const { name, value } = event.target;
    const filterName = name;
    const filters = { ...this.state.filter };

    if (name === "availableToday") {
      if (!this.isTodaySelected())
        filters.availableOn = parseFromFirebaseDate(
          Timestamp.now(),
          "YYYY-MM-DD"
        );
      else{
       filters.availableOn = "";
      }
    }else {
      filters[filterName] = value;
    }
    this.setState({ filter: filters });
  };

  applyFilters = () => {
    const addedEquipment = [ ...this.props.addedEquipment];
    const otherEquipment = [ ...this.props.otherEquipment];

    const addedEquipmentFilter = addedEquipment.filter((equipment) => {
     
      if (
        this.state.filter.byCategory &&
        equipment.category !== this.state.filter.byCategory.value
      ) {
        return false;
      }
      if (
        this.state.filter.bySubCategory &&
        equipment.subCategory !== this.state.filter.bySubCategory.value
      ) {
        return false;
      }
      return true;
    });

    const otherEquipmentFilter = otherEquipment.filter((equipment) => {
      if(this.state.equipmentReservations?.some((reservation) => {
        return reservation.equipmentID === equipment.id;
      })){
        return false;
      }
      if (
        this.state.filter.byCategory &&
        equipment.category !== this.state.filter.byCategory.value
      ) {
        return false;
      }
      if (
        this.state.filter.bySubCategory &&
        equipment.subCategory !== this.state.filter.bySubCategory.value
      ) {
        return false;
      }
      return true;
    });

    this.setState({
      addedEquipment: addedEquipmentFilter,
      otherEquipment: otherEquipmentFilter,
    });
  };

  toggleCustomFilters = () => {
    this.setState((prevState) => ({
      showCustomFilters: !prevState.showCustomFilters,
    }));
  };

  render() {
    return (
      <React.Fragment>
        <JobEquipmentFilter />
        <div className="row">
          <div className="col-xl-12">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  const filter = { ...this.state.filter };
                  filter.search = e.target.value;
                  this.setState({ filter: filter });
                }}
                placeholder="Search"
              />
            </div>
            <button
              className="btn btn-link pl-5 pr-5"
              onClick={this.toggleCustomFilters}
            >
              {this.state.showCustomFilters ? "Hide Filters" : "Show Filters"}
            </button>
            {this.state.showCustomFilters && (
              <div className="card pl-5 pr-5">
                <CustomFilter
                  equipmentFilters={this.getEquipmentFilters()}
                  ids={this.state.ids}
                  categories={[
                    { value: "Equipment", label: "Equipment" },
                    { value: "Tooling", label: "Tooling" },
                  ]}
                  selectedCategory={this.state.filter.byCategory}
                  selectedSubCategory={this.state.filter.bySubCategory}
                  handleSubCategoryOptions={this.handleSubCategoryOptions}
                />
              </div>
            )}
          </div>
        </div>
        {this.state.showCustomFilters && (
          <div className="row">
            <div className="form-group">
              <div className=""></div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-xl-6 pt-10 pt-xl-0">
            <div className="card card-custom card-stretch" id="kt_todo_view">
              <div className="card-header align-items-center flex-wrap justify-content-between border-0 py-6 h-auto">
                <div className="d-flex flex-column mr-2 py-2">
                  <a
                    href="#"
                    className="text-dark text-hover-primary font-weight-bold font-size-h4 mr-3"
                  >
                    Added equipment
                  </a>
                </div>
              </div>
              <div className="separator separator-dashed mt-3 mb-2"></div>
              <div className="card-body p-0">
                {this.props.equipmentListLoading ? (
                  <Loader height="100px" />
                ) : (
                  <div className="card-body pt-4 d-flex flex-column justify-content-between">
                    {this.state.addedEquipment.length > 0 ? (
                      <>
                        {this.state.addedEquipment.map((item, index) => {
                          return (
                            <Link
                              key={"jb-eq-gtadd" + index}
                              to={
                                "/jobs/" +
                                this.props.job.id +
                                "/equipment/" +
                                item.id
                              }
                            >
                              <div className="d-flex align-items-center mb-7">
                                <div className="flex-shrink-0 mr-4 mt-lg-0 mt-3">
                                  {item.image ? (
                                    <div className="symbol symbol-lg-85">
                                      <img alt="Pic" src={item.image} />
                                    </div>
                                  ) : (
                                    <div className="symbol symbol-lg-85 symbol-primary">
                                      <span className="symbol-label font-size-h3 font-weight-boldest">
                                        {item.name}
                                      </span>
                                    </div>
                                  )}
                                </div>
                                <div className="d-flex flex-column">
                                  <span className="text-muted font-weight-bold">
                                    ID: {item.equipmentID}
                                  </span>
                                  <span className="text-muted font-weight-bold">
                                    Make: {item.make}
                                  </span>
                                  <span className="text-muted font-weight-bold">
                                    Model: {item.model}
                                  </span>
                                  <span className="text-muted font-weight-bold">
                                    Year: {item.year}
                                  </span>
                                </div>
                              </div>
                            </Link>
                          );
                        })}
                      </>
                    ) : (
                      <div className="d-flex align-items-center mb-10">
                        <div className="d-flex flex-column flex-grow-1 font-weight-bold align-items-center">
                          <div className="mainpage-graphic">
                            <EquipmentIos
                              width={"200px"}
                              height={"200px"}
                              opacity={"0.3"}
                            />
                          </div>
                          <span className="font-italic">
                            You don't have any equipment added to the job at the
                            moment. You can start by clicking on a piece of
                            equipment from the list on the right and then
                            selecting 'Reserve'.
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-xl-6 pt-10 pt-xl-0">
            <div className="card card-custom card-stretch" id="kt_todo_view">
              <div className="card-header align-items-center flex-wrap justify-content-between border-0 py-6 h-auto">
                <div className="d-flex flex-column mr-2 py-2">
                  <a
                    href="#"
                    className="text-dark text-hover-primary font-weight-bold font-size-h4 mr-3"
                  >
                    Other equipment
                  </a>
                  <span className="font-italic">
                    Click on a equipment to schedule them to the job
                  </span>
                </div>
              </div>
              <div className="separator separator-dashed mt-3 mb-2"></div>
              <div className="card-body p-0">
                {this.props.equipmentListLoading ? (
                  <Loader height="100px" />
                ) : (
                  <div className="card-body pt-4 d-flex flex-column justify-content-between">
                    {this.state.otherEquipment.length > 0 ? (
                      <>
                        {this.state.otherEquipment.map((item, index) => {
                          return (
                            <Link
                              key={"jb-eq-gtoth" + index}
                              to={
                                "/jobs/" +
                                this.props.job.id +
                                "/equipment/" +
                                item.id
                              }
                            >
                              <div className="d-flex align-items-center mb-7">
                                <div className="flex-shrink-0 mr-4 mt-lg-0 mt-3">
                                  {item.image ? (
                                    <div className="symbol symbol-lg-85">
                                      <img alt="Pic" src={item.image} />
                                    </div>
                                  ) : (
                                    <div className="symbol symbol-lg-85 symbol-primary">
                                      <span className="symbol-label font-size-h3 font-weight-boldest">
                                        {item.name}
                                      </span>
                                    </div>
                                  )}
                                </div>
                                <div className="d-flex flex-column">
                                  <span className="text-muted font-weight-bold">
                                    ID: {item.equipmentID}
                                  </span>
                                  <span className="text-muted font-weight-bold">
                                    Make: {item.make}
                                  </span>
                                  <span className="text-muted font-weight-bold">
                                    Model: {item.model}
                                  </span>
                                  <span className="text-muted font-weight-bold">
                                    Year: {item.year}
                                  </span>
                                </div>
                              </div>
                            </Link>
                          );
                        })}
                      </>
                    ) : (
                      <div className="d-flex align-items-center mb-10">
                        <div className="d-flex flex-column flex-grow-1 font-weight-bold align-items-center">
                          <div className="mainpage-graphic">
                            <EquipmentIos
                              width={"200px"}
                              height={"200px"}
                              opacity={"0.3"}
                            />
                          </div>
                          <span className="font-italic">
                            There is no equipment for you to add to this job.
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default JobEquipment;
