import { documentId } from "firebase/firestore"
import { db } from "../../firebase"
import { CrewEntity, CrewSchedulesEntity, CrewMemberEntity, CrewsAppointmentsEntity } from "../data/Crew"
import { ResponseEntity } from "../data/Response"
import { FirebaseInventoriesCollections, FirebaseRootCollections } from "../firebase/FirebaseSchema"

export const getAllCrews = async (inventoryId: string) => {
    try {
        const result = await db
            .collection(FirebaseRootCollections.INVENTORIES)
            .doc(inventoryId)
            .collection(FirebaseInventoriesCollections.CREWS)
            .get()

        const crews: CrewEntity[] = result.docs.map((doc: any) => {
            return { ...doc.data(), id: doc.id }
        })

        return new ResponseEntity<CrewEntity[]>(crews, "")
    } catch (error: any) {
        return new ResponseEntity<CrewEntity[]>(null, error)
    }
}

export const getAllCrewMembers = async (inventoryId: string) => {
    try {
        const result = await db
            .collection(FirebaseRootCollections.INVENTORIES)
            .doc(inventoryId)
            .collection(FirebaseInventoriesCollections.CREW)
            .get()

        const crewMembers: CrewMemberEntity[] = result.docs.map((doc: any) => {
            return { ...doc.data(), id: doc.id }
        })

        return new ResponseEntity<CrewMemberEntity[]>(crewMembers, "")
    } catch (error: any) {
        return new ResponseEntity<CrewMemberEntity[]>(null, error)
    }
}

export const getAllCrewsSchedules = async (inventoryId: string, jobId: string) => {
    try {
        const result = await db
            .collection(FirebaseRootCollections.INVENTORIES)
            .doc(inventoryId)
            .collection(FirebaseInventoriesCollections.CREWS_APPOINTMENTS)
            .get()

        const crewSchedules: CrewSchedulesEntity[] = result.docs.map((doc: any) => {
            return { ...doc.data(), id: doc.id }
        })

        return new ResponseEntity<CrewSchedulesEntity[]>(crewSchedules, "")
    } catch (error: any) {
        return new ResponseEntity<CrewSchedulesEntity[]>(null, error)
    }
}

export const getJobAssignedCrews = async (inventoryId: string, jobId: string) => {
    try {
        const result = await db
            .collection(FirebaseRootCollections.INVENTORIES)
            .doc(inventoryId)
            .collection(FirebaseInventoriesCollections.CREWS_APPOINTMENTS)
            .where("jobID", "==", jobId)
            .get()

        const crews = await getAllCrews(inventoryId)
        const scheduledCrews = result.docs.map((doc: any) => {
            return { ...doc.data(), id: doc.id }
        })

        const jobCrews = crews?.data?.filter((crew) => scheduledCrews.some((result: CrewsAppointmentsEntity) => result.crewID == crew.id))

        return new ResponseEntity<CrewEntity[]>(jobCrews, "")
    } catch (error: any) {
        return new ResponseEntity<CrewEntity[]>(null, error)
    }
}

export const getJobAssignedCrewMembers = async (inventoryId: string, jobId: string) => {
    try {
        const result = await db
            .collection(FirebaseRootCollections.INVENTORIES)
            .doc(inventoryId)
            .collection(FirebaseInventoriesCollections.CREW_SCHEDULES)
            .where("jobID", "==", jobId)
            .get()

        const crewMembers = await getAllCrewMembers(inventoryId)
        const scheduledCrewMembers = result.docs.map((doc: any) => {
            return { ...doc.data(), id: doc.id }
        })

        const jobCrewMembers = crewMembers?.data?.filter((crewMember) => scheduledCrewMembers.some((result: CrewSchedulesEntity) => result.crewMemberID == crewMember.id))

        return new ResponseEntity<CrewMemberEntity[]>(jobCrewMembers, "")
    } catch (error: any) {
        return new ResponseEntity<CrewMemberEntity[]>(null, error)
    }
}

export const saveCrewMembersSchedules = async (inventoryId: string, crewMemberSchedule: CrewSchedulesEntity) => {
    try {
        const crewScheduleCollection = db
            .collection(FirebaseRootCollections.INVENTORIES)
            .doc(inventoryId)
            .collection(FirebaseInventoriesCollections.CREW_SCHEDULES)

        const { documentId, ...crewScheduleWithoutId } = crewMemberSchedule
        if (!crewMemberSchedule.documentId) {
            const response = await crewScheduleCollection.add(crewScheduleWithoutId)
            crewMemberSchedule.documentId = response.id
        } else {
            const response = await crewScheduleCollection.doc(crewMemberSchedule.documentId).update(crewScheduleWithoutId)
        } return new ResponseEntity<CrewSchedulesEntity>(crewMemberSchedule, "")
    } catch (error: any) {
        return new ResponseEntity<CrewSchedulesEntity>(crewMemberSchedule, error)
    }
}

export const saveCrewSchedules = async (inventoryId: string, crewsSchedule: CrewsAppointmentsEntity) => {
    try {
        const crewScheduleCollection = db
            .collection(FirebaseRootCollections.INVENTORIES)
            .doc(inventoryId)
            .collection(FirebaseInventoriesCollections.CREWS_APPOINTMENTS)

        const { documentId, ...crewScheduleWithoutId } = crewsSchedule
        if (!crewsSchedule.documentId) {
            const response = await crewScheduleCollection.add(crewScheduleWithoutId)
            crewsSchedule.documentId = response.id
        } else {
            const response = await crewScheduleCollection.doc(crewsSchedule.documentId).update(crewScheduleWithoutId)
        } return new ResponseEntity<CrewsAppointmentsEntity>(crewsSchedule, "")
    } catch (error: any) {
        return new ResponseEntity<CrewsAppointmentsEntity>(crewsSchedule, error)
    }
}

export const getCrewMembersSchedules = async (inventoryId: string, jobId: string) => {
    try {
        const result = await db
            .collection(FirebaseRootCollections.INVENTORIES)
            .doc(inventoryId)
            .collection(FirebaseInventoriesCollections.CREW_SCHEDULES)
            .where("jobID", "==", jobId)
            .get()

        const scheduledCrewMembers = result.docs.map((doc: any) => {
            return { ...doc.data(), documentId: doc.id }
        })

        return new ResponseEntity<CrewSchedulesEntity[]>(scheduledCrewMembers, "")
    } catch (error: any) {
        return new ResponseEntity<CrewSchedulesEntity[]>(null, error)
    }
}

export const getCrewsSchedules = async (inventoryId: string, jobId: string) => {
    try {
        const result = await db
            .collection(FirebaseRootCollections.INVENTORIES)
            .doc(inventoryId)
            .collection(FirebaseInventoriesCollections.CREWS_APPOINTMENTS)
            .where("jobID", "==", jobId)
            .get()

        const scheduledCrewMembers = result.docs.map((doc: any) => {
            return { ...doc.data(), documentId: doc.id }
        })

        return new ResponseEntity<CrewsAppointmentsEntity[]>(scheduledCrewMembers, "")
    } catch (error: any) {
        return new ResponseEntity<CrewsAppointmentsEntity[]>(null, error)
    }
}

export const deleteCrewReservations = async (inventoryId: string, jobId: string) => {
    try {
        const crewReservations = await getJobAssignedCrews(inventoryId, jobId)
        const deletionPromises = (crewReservations?.data || []).map(async (reservation) => {
            return db.collection(FirebaseRootCollections.INVENTORIES)
            .doc(inventoryId)
            .collection(FirebaseInventoriesCollections.CREWS)
            .doc(reservation.id ?? "")
            .delete()
        });
        await Promise.all(deletionPromises)
    } catch (error) {
        console.error(error)
    }
}

export const deleteCrewMemberReservations = async (inventoryId: string, jobId: string) => {
    try {
        const crewMemberReservations = await getJobAssignedCrewMembers(inventoryId, jobId)
        const deletionPromises = (crewMemberReservations?.data || []).map(async (reservation) => {
            return db.collection(FirebaseRootCollections.INVENTORIES)
            .doc(inventoryId)
            .collection(FirebaseInventoriesCollections.CREWS)
            .doc(reservation.id ?? "")
            .delete()
        });
        await Promise.all(deletionPromises)
    } catch (error) {
        console.error(error)
    }
}