import React, { useEffect, useState } from 'react';
import app, { db, functions, storage } from "../firebase";
import { getNotifications } from './Api';
import ReactPixel from 'react-facebook-pixel';
import { AppState } from './data/context/ContextEntity';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState('pending');
    const [messageState, setMessageState] = useState(null);
    const [backButtonPoint, setBackButtonPoint] = useState(null);
    const [jobIdBack, setJobIdBack] = useState(null);
    const [showWelcomeScreen, setShowWelcomeScreen] = useState(false);
    const [isTrial, setIsTrial] = useState(false);
    const [isTutorial, setIsTutorial] = useState(false);
    const [tutorialStep, setTutorialStep] = useState("welcomeMessage");
    const [notifications, setNotifications] = useState(null);
    const [newNotifications, setNewNotifications] = useState(null);
    const [publicView, setPublicView] = useState(true);
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [zoomLevel, setZoomLevel] = useState(14);
    const [isMobile, setIsMobile] = useState(
        typeof window !== 'undefined' && window.innerWidth < 1024
      );
    const [appState, setAppState] = useState(new AppState())

    
    const getRecurlyAccount = functions.httpsCallable('api/getRecurlyAccount');


    const updateCurrentUser = (currentUser) => {
        setCurrentUser('pending');
        setCurrentUser(currentUser);
    }

    const updateMessageState = (messageState) => {
        setMessageState(null);
        setMessageState(messageState);
    }

    const updateBackButtonPoint = (url) => {
        setBackButtonPoint(url);
    }

    const updateZoomLevel = (value) => {
        setZoomLevel(14);
        setZoomLevel(value);
    }

    const loadNotifications = async (id) => {
        
        const notificationResult = await getNotifications();
        let read = notificationResult.data.filter(x => x.seenByUser.includes(id));
        let unread = notificationResult.data.filter(x => !x.seenByUser.includes(id));
        
        read.forEach(x => {
            x.read = true;
            x.dateCreated = x.createdAt.toDate().toLocaleString()
        });
        unread.forEach(x=> {
            x.read = false;
            x.dateCreated = x.createdAt.toDate().toLocaleString();

        })

        read.sort((a, b) => (a.createdAt > b.createdAt) ? -1 : 1)
        unread.sort((a, b) => (a.createdAt > b.createdAt) ? -1 : 1)

        let data = [...unread, ...read];
        updateNewNotifications(unread.length);
        updateNotifications(data);
    }

    const updateNewNotifications = (num) => {
        setNewNotifications(null);
        setNewNotifications(num);
    }

    const updateNotifications = (notifications) => {
        setNotifications(null);
        setNotifications(notifications);
    }

    const updateJobIdBack = (id) => {
        setJobIdBack(id);
    }

    const refreshRecurly = (user, onFinish) => {
        getRecurlyAccount()
        .then((result) => {
            if(currentUser) {
                const acc = result.data.account;
                const subscription = result.data.subscription
                const todayTimestamp = new Date().getTime();
                const dateCreatedTimestamp = new Date(acc.created_at._).getTime();
                const diffTime = (todayTimestamp-dateCreatedTimestamp);
                const daysDiff = Math.round(diffTime / (1000 * 60 * 60  * 24));

                const isTrial = daysDiff <= 30;
                setIsTrial(!(acc.has_active_subscription._ === 'true'));

                const subscriptionActive = acc.has_active_subscription._ === 'true'
                // acc.state === 'active'
                //     && acc.has_active_subscription._ === 'true';                                    
                    
                if(subscriptionActive || user.userProfile.permissions === 'crewmember') {
                    user.subscriptionActive = true;
                    user.hostedLoginToken = acc.hosted_login_token;
                    user.subscription = subscription

                    if(user.userProfile.imageTimeStamp) {
                        const storageRef = storage.ref('/' + user.userProfile.companyID + '/user/' + user.uid + '/' + user.userProfile.imageTimeStamp.seconds + '/');
                        storageRef.child('med').getDownloadURL()
                            .then((url) => {
                                user.userProfile.avatar = url;
                                setCurrentUser(user);
                            })
                            .catch(err => {
                                setCurrentUser(user);
                            });
                    } else{
                        setCurrentUser(user)
                    }                                    
                    
                } else {
                    app.auth().signOut();
                    setMessageState('subscription-error');
                }  
            }
            if (onFinish) onFinish();                                 
        }).catch((error) => {
            console.log(error)
            if(currentUser  && currentUser !== "pending") {
                user.subscriptionActive = user?.userProfile?.permissions === 'crewmember' || false;
                setCurrentUser(user);
            }
        });
    }

    const nextTutorialStep = () => {
        const steps = [
            'welcomeMessage', 
            "addJob",
            'addEquipment', 
            'addingEquipmentRequired',
            "addingEquipmentCategory", 
            "addingEquipmentImage", 
            "addCrewmember", 
            "addingCrewmember",
            "showReport", 
            "addingJob",
            "jobReport",
            "bores"];
        const index = steps.indexOf(tutorialStep);
        
        // if(index === -1 || index === steps.length - 1) {
        //     finishTutorial();
        //     return;
        // }
        
        setTutorialStep(steps[index + 1]);
    }

    const finishTutorial = () => {
        setIsTutorial(false);
    }

    const toggleView = (event) => {
        const {checked} =  event.target;
        setPublicView(checked);
    }

    const handleWindowSizeChange = () => {
        setIsMobile(window.innerWidth <= 1024);
      }

      useEffect(()=>{
        if(currentUser!='pending' && !currentUser?.subscription && currentUser!=null) refreshRecurly(currentUser)
      },[currentUser])

    useEffect(()=>{
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, [])

    useEffect(() => {
        ReactPixel.init('3468782456542451');
        
        app.auth().onAuthStateChanged((user) => {
            if(user) {
                setMessageState(null);
                loadNotifications(user.uid);

                db.collection("users").doc(user.uid).get().then((doc) => {
                    user.userProfile = doc.exists ? doc.data() : null;
                    if(doc.exists) {
                        db.collection("companies").doc(user.userProfile.companyID).get().then((company) => {
                            if(currentUser) {
                            user.company = company.exists ? company.data() : null;
                            refreshRecurly(user)
                        
                        }else{
                            setCurrentUser(user)
                        }
                    }); 
                    } else {
                        setCurrentUser(user);
                    }                    
                });
            } else {
                setCurrentUser(user);
            }
        });        
    }, []);

    return (
        <AuthContext.Provider value={{appState, setAppState, currentUser, updateCurrentUser, setCurrentUser, messageState, updateMessageState, backButtonPoint, updateBackButtonPoint, showWelcomeScreen, setShowWelcomeScreen,
        jobIdBack, updateJobIdBack, isTrial, notifications, updateNotifications, newNotifications,updateNewNotifications, loadNotifications, publicView, setPublicView, toggleView,
        isTutorial, setIsTutorial, tutorialStep, setTutorialStep, nextTutorialStep, finishTutorial, selectedPlace, setSelectedPlace, zoomLevel, setZoomLevel, updateZoomLevel, isMobile, refreshRecurly }}>
            {children}
        </AuthContext.Provider>
    );
}